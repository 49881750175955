export const setSearchTerm = (searchTerm) => ({
    type: "SET_SEARCH_TERM",
    payload: searchTerm,
});

export const setSelectedGameGenres = (selectedGenres) => ({
    type: "SET_SELECTED_GAME_GENRES",
    payload: selectedGenres,
});

export const setSelectedGameThemes = (selectedThemes) => ({
    type: "SET_SELECTED_GAME_THEMES",
    payload: selectedThemes,
});

export const setHighlightMissing = (highlightMissing) => ({
    type: "SET_HIGHLIGHT_MISSING",
    payload: highlightMissing,
});

export const setYearRange = (minYear, maxYear) => ({
    type: 'SET_YEAR_RANGE',
    payload: { minYear, maxYear },
});

export const setGamesFilterMode = (gameFilterModeIndex) => ({
    type: "SET_GAMES_FILTER_MODE",
    payload: gameFilterModeIndex,
});

export const setGamesSortBy = (sortBy) => ({
    type: "SET_GAMES_SORT_BY",
    payload: sortBy,
});

export const setGamesQueryPage = (gamesQueryPage) => ({
    type: "SET_GAMES_QUERY_PAGE",
    payload: gamesQueryPage,
});