import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import jwt_decode from "jwt-decode";
import { setUser } from "../actions/user";
import { setSearchTerm, setGamesQueryPage } from "../actions/filters";
import { setAccount } from "../actions/account";
import { useNavigate, useLocation } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';

function TopBar() {

    const location = useLocation();
    const dispatch = useDispatch();
    const [currentSearchTerm, setCurrentSearchTerm] = useState(""); // cached
    const isPlayingPage = location.pathname === '/playing';
    const user = useSelector((state) => state.user);
    const account = useSelector((state) => state.account);
    const visibleIframe = useSelector(state => state.videoPlayer.visibleIframe)
    const videoId = useSelector((state) => state.videoPlayer.videoId);

    const handleSignout = () => {
        localStorage.removeItem("credential");
        window.location.href = window.location.href;
    }

    const registerAccount = async (user) => {

        fetch(`${process.env.REACT_APP_API_URL}/register-account`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ email: user.email, username: user.username, sub: user.sub })
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                dispatch(setAccount(data.user));
                toast.success('Successfully registered!', {
                    position: "bottom-center",
                    autoClose: 3500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                // navigate('/settings');
                window.location.href = window.location.href; // need hard reload for isLogged update.
            })
            .catch(error => {
                console.error('Error:', error);
                toast.error('Error registering account.', {
                    position: "bottom-center",
                    autoClose: 3500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            });
    }

    const fetchAccountInfo = async (userEmail, sub) => {
        // Fetching account info...
        return new Promise((resolve, reject) => {

            fetch(`${process.env.REACT_APP_API_URL}/user-get-account-data`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ userEmail, sub }) // filters includes search_term, sites and models
            })
                .then(response => response.json())
                .then(accountData => {
                    // console.log('Account data: ', accountData);
                    if (accountData.success) resolve(accountData)
                    else if (accountData.success === false){
                        resolve(false)
                    }
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        })
    }

    const handleCallbackResponse = (response) => {
        // this will only run when user logged in via click
        // console.log("Encoded JWT ID token: " + response.credential);

        var userObject = jwt_decode(response.credential);

        // console.log(userObject);
        userObject.logged = true
        dispatch(setUser(userObject));
        fetchAccountInfo(userObject.email, userObject.sub).then(accountData => {
            // console.log('Account data: ', accountData);
            if (accountData !== false) {
                dispatch(setAccount(accountData.account));
            } else if (accountData === false) {
                // if user doesnt exist in db, add it
                registerAccount({
                    email: userObject.email,
                    sub: userObject.sub
                })
            }
        })
        // cache for future (string)
        localStorage.setItem("credential", response.credential)
    }

    useEffect(() => {

        window.addEventListener("load", () => {
            let storedToken = localStorage.getItem("credential")
            if (storedToken) {
                let userObject
                try {
                    userObject = jwt_decode(storedToken);
                } catch (e) {
                    localStorage.removeItem("credential")
                    window.location.href = window.location.href
                }
                userObject.logged = true
                dispatch(setUser(userObject));
                fetchAccountInfo(userObject.email, userObject.sub).then(accountData => {
                    if (accountData !== false) dispatch(setAccount(accountData.account));
                    else if (accountData === false) {
                    }
                })
            }
            else {
                // console.log('Cached account data not found...');
                const userObject = { logged: false }
                dispatch(setUser(userObject));

                /* global google */
                google.accounts.id.initialize({
                    client_id: "969024492244-bf3semdmo38k8jiabdldn04h7hd4aea1.apps.googleusercontent.com",
                    callback: handleCallbackResponse,
                });

                /* global google */
                google.accounts.id.renderButton(
                    document.getElementById("top-bar-google-container"),
                    { theme: "outline", size: "large" }
                )
            }
        })

    }, [])

    /*
    useEffect(() => {
        queryGames("")
    }, [dispatch])
    */

    const handleInputChange = (e) => {
        let searchTerm = e.target.value
        setCurrentSearchTerm(searchTerm)
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            dispatch(setGamesQueryPage(1))
            dispatch(setSearchTerm(currentSearchTerm))
        }
    }

    return (
        <div className={`top-bar-container ${isPlayingPage ? ' hidden ' : ''}` + (videoId !== -1 && visibleIframe ? " top-bar-container-player-enabled" : "")} >
            <div className='searchbar-container'>
                <svg viewBox="0 0 24 24" focusable="false" className="chakra-icon topbar-search-icon css-1yk3h4a" data-testid="SearchIcon"><path d="M20 11a8 8 0 1 0-16 0 8 8 0 0 0 16 0zm-2.707 7.28a9 9 0 1 1 .772-.63l3.289 3.29a.5.5 0 0 1-.708.706l-3.292-3.292a.507.507 0 0 1-.061-.074z"></path></svg>
                <input onInput={(e) => handleInputChange(e)} className='text-input' placeholder="Search" id="search" autoFocus onKeyDown={(e) => handleKeyPress(e)} spellCheck="false" autoComplete="off" />
            </div>

            <div className='topbar-right-content'>

                {/* <a className='coffee-link' href="https://www.buymeacoffee.com/vgmhub" target="_blank">
                    <img src="https://cdn.buymeacoffee.com/buttons/v2/default-red.png" alt="Buy Me A Coffee" style={{ height: "32px" }} />
                </a> */}

                {user &&
                    <div className="logged-user-infos">
                        {/* {Object.keys(user.user).length > 0 && */}
                        {user.user.logged &&
                            <div className='logged-infos-container'>

                                {/* <img alt="" src={user.user.picture} referrerPolicy="no-referrer"></img> */}

                                <NavLink to="/favorites" activeclassname="active">
                                    <img alt="" src={account.account.profile_picture} referrerPolicy="no-referrer"></img>
                                </NavLink>

                                <div className='logout-name-container'>
                                    <div className='email'>
                                        {/* {!account.account && "Account is not saved in DB yet..."} */}
                                        {account.account && account.account.username}
                                    </div>
                                    {Object.keys(user).length > 0 &&
                                        <button className='logout-btn' onClick={(e) => handleSignout()}>Sign out</button>
                                    }
                                </div>
                            </div>
                        }
                    </div>
                }
                {/* {user && Object.keys(user.user).length === 0 && */}
                {user && user.user.logged === false &&
                    <div className="login-infos-container" id="top-bar-google-container">
                        {/* <button id="google-login" onClick={() => handleGoogleLoginClick()}>LOGIN WITH GOOGLE</button> */}
                    </div>
                }
            </div>
        </div>
    );
}

export default TopBar;