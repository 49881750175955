import { useSelector } from 'react-redux';
import useFunctions from './Functions';
import { React } from 'react';
import { NavLink } from 'react-router-dom';

function AlbumBanner(props) {

    const { album } = props;
    const account = useSelector((state) => state.account);
    const { renderTextWithLineBreaks, likeAlbum, playAlbum } = useFunctions();

    return (
        album &&
        <div className="album-banner-container">
            <div className='album-banner-container-left'>
                <img alt="album cover" className='album-cover' src={process.env.REACT_APP_ALBUM_COVERS_FOLDER + "/" + album._id + "-" + album.versionId + "_400x.jpg"} ></img>
            </div>
            <div className='album-banner-container-right'>
                <div className='banner-tags-container'>
                    <div className='tag'>Album</div>
                </div>
                <div className='album-title'>{album.album_name}</div>
                <div className='album-additional-infos'>{album.album_songs.length} songs | Liked by {album.like_count} people</div>
                <div className='album-added-by'>Added by:

                    <NavLink to={"/profiles/" + (album.contributors.added_by_public_id)} >
                        <span> {album.contributors.added_by}</span>
                    </NavLink>
                </div>

                {album.date_added &&
                    <div className='album-last-updated'>Album added:
                        <span>
                            {" " + new Date(album.date_added).toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' })}
                        </span>
                    </div>
                }
                {album.album_description &&
                    <div className='album-description'>
                        {renderTextWithLineBreaks(album.album_description)}
                    </div>
                }


                <div className="album-banner-buttons component-banner-buttons">
                    <button onClick={() => {
                        playAlbum(album)
                    }}>Play album</button>
                    {account && account.account && account.account.username &&
                        <div className='album-btn-container love-btn-container' onClick={() => likeAlbum(album._id)}>
                            {account && account.account && account.account.liked_album_ids && !account.account.liked_album_ids.find(e => e === album._id) &&// not liked
                                <svg viewBox="0 0 16 16" focusable="false" className="chakra-icon css-1yk3h4a" data-testid="HeartIcon">
                                    <path d="m8 4.79-.755-.869c-1.17-1.348-2.252-1.832-3.093-1.9-.836-.067-1.59.263-2.164.858C.802 4.108.528 6.283 2.04 7.812a245.96 245.96 0 0 0 4.775 4.7c.482.46.882.837 1.186 1.122.304-.285.704-.663 1.186-1.123a238.026 238.026 0 0 0 4.771-4.695 3.545 3.545 0 0 0 .057-4.963c-.572-.589-1.324-.915-2.161-.843-.843.072-1.926.562-3.098 1.911L8 4.791zm6.672 3.725C10.78 12.452 8 15 8 15s-2.78-2.548-6.672-6.485c-3.717-3.76 1.043-10.549 5.976-5.972.232.215.464.455.696.723.232-.267.464-.508.696-.723C13.63-2.04 18.39 4.68 14.672 8.515z">
                                    </path>
                                    <path d="m8 4.79-.755-.869c-1.17-1.348-2.252-1.832-3.093-1.9-.836-.067-1.59.263-2.164.858C.802 4.108.528 6.283 2.04 7.812a245.96 245.96 0 0 0 4.775 4.7c.482.46.882.837 1.186 1.122.304-.285.704-.663 1.186-1.123a238.026 238.026 0 0 0 4.771-4.695 3.545 3.545 0 0 0 .057-4.963c-.572-.589-1.324-.915-2.161-.843-.843.072-1.926.562-3.098 1.911L8 4.791zm6.672 3.725C10.78 12.452 8 15 8 15s-2.78-2.548-6.672-6.485c-3.717-3.76 1.043-10.549 5.976-5.972.232.215.464.455.696.723.232-.267.464-.508.696-.723C13.63-2.04 18.39 4.68 14.672 8.515z">
                                    </path>
                                </svg>
                            }
                            {account && account.account && account.account.liked_album_ids && account.account.liked_album_ids.find(e => e === album._id) && // liked
                                <svg id="svg-liked" viewBox="0 0 16 16" focusable="false" className="chakra-icon css-1qsky21" data-testid="HeartFillIcon">
                                    <path d="M8 3.266C2.837-2.68-2.564 4.578 1.328 8.516 5.22 12.451 8 15 8 15s2.78-2.548 6.672-6.485C18.564 4.501 13.162-2.679 8 3.265z">
                                    </path>
                                </svg>
                            }
                        </div>
                    }
                </div>


            </div>

        </div>
    );
}

// For functional components, export without connect:
export default AlbumBanner;
