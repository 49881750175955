const initialState = {
    searchTerm: "",
    selectedGameGenres: [],
    selectedGameThemes: [],
    yearRange: { minYear: 1970, maxYear: 2024 },
    sortBy: "total_rating_count",
    highlightMissing: false,
    gameFilterModeIndex: undefined,
    gamesQueryPage: 1,
};

const filtersReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_SEARCH_TERM':
            return { ...state, searchTerm: action.payload };
        case 'SET_SELECTED_GAME_GENRES':
            return { ...state, selectedGameGenres: action.payload };
        case 'SET_SELECTED_GAME_THEMES':
            return { ...state, selectedGameThemes: action.payload };
        case 'SET_YEAR_RANGE':
            return { ...state, yearRange: action.payload };
        case 'SET_GAMES_FILTER_MODE':
            return { ...state, gameFilterModeIndex: action.payload };
        case 'SET_HIGHLIGHT_MISSING':
            return { ...state, highlightMissing: action.payload };
        case 'SET_GAMES_SORT_BY':
            return { ...state, sortBy: action.payload };
        case 'SET_GAMES_QUERY_PAGE':
            return { ...state, gamesQueryPage: action.payload };
        default:
            return state;
    }
};

export default filtersReducer;
