import React, { useState } from 'react';
import useFunctions from './Functions';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useNavigate, useLocation } from 'react-router-dom';
import { NavLink } from 'react-router-dom';

function AlbumWrapper(props) {

    const user = useSelector((state) => state.user);
    const { getShortDate, likeAlbum, truncateString, playAlbum, removeFromCollection, addToCollection } = useFunctions();
    const { fromCollection, accountCollections, isCollectionPopup, setIsCollectionPopup, refreshAlbums, album, account, activeAlbumPopup, setActiveAlbumPopup, fromFavorites, setEditingAlbumId, setEditAlbumPopupVisible, index, popupRef } = props;
    const navigate = useNavigate();
    const location = useLocation();
    const isGamePage = location.pathname.includes('/games/');
    const isCollectionPage = location.pathname.includes('/collection/');
    const [inlineCollectionQuery, setInlineCollectionQuery] = useState("");


    const deleteAlbum = async (album) => {
        let answer = window.confirm("Delete album?")
        if (!answer) return false
        return new Promise((resolve, reject) => {
            const albumId = album._id;
            fetch(`${process.env.REACT_APP_API_URL}/delete-album`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ albumId, sub: user.user.sub })
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then(data => {
                    // console.log('Response data:', data);
                    toast.success('Album successfully deleted.', {
                        position: "bottom-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    // hereee
                    refreshAlbums()
                    resolve(true)
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        })
    }

    // needed for add to collection query in popup
    const handleInlineInputChange = (event) => {
        const newValue = event.target.value;
        setInlineCollectionQuery(newValue);
    }

    function modifyAlbumName(inputString) {
        // Replace non-alphanumeric characters with hyphen
        return inputString.replace(/[^a-zA-Z0-9]/g, '-').toLowerCase();
    }

    return (
        <div className='album-container'>
            <NavLink to={"/albums/" + modifyAlbumName(album.album_name) + "/" + album._id}>
                <div className='album-cover-container' style={{ "backgroundImage": "url(" + process.env.REACT_APP_ALBUM_COVERS_FOLDER + "/" + album._id + "-" + album.versionId + "_400x.jpg)" }}>
                    <div className="album-btns-container component-btns-container">
                        <div className='album-btn-container component-btn-container play-btn-container' onClick={(e) => {
                            e.preventDefault()
                            playAlbum(album)
                        }}>
                            <svg className="svg-icon svg-icon-play" focusable="false" height="1em" role="img" width="1em" viewBox="0 0 12 12" aria-hidden="true"><path fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="M2.5.5v11l9-5.5z"></path></svg>
                        </div>

                        {account && account.account && account.account.liked_album_ids &&
                            <div className='album-btn-container component-btn-container love-btn-container' onClick={(e) => {
                                e.preventDefault()
                                likeAlbum(album._id)
                            }}>
                                {account && account.account && account.account.liked_album_ids && !account.account.liked_album_ids.includes(album._id) &&// not liked
                                    <svg viewBox="0 0 16 16" focusable="false" className="chakra-icon css-1yk3h4a" data-testid="HeartIcon">
                                        <path d="m8 4.79-.755-.869c-1.17-1.348-2.252-1.832-3.093-1.9-.836-.067-1.59.263-2.164.858C.802 4.108.528 6.283 2.04 7.812a245.96 245.96 0 0 0 4.775 4.7c.482.46.882.837 1.186 1.122.304-.285.704-.663 1.186-1.123a238.026 238.026 0 0 0 4.771-4.695 3.545 3.545 0 0 0 .057-4.963c-.572-.589-1.324-.915-2.161-.843-.843.072-1.926.562-3.098 1.911L8 4.791zm6.672 3.725C10.78 12.452 8 15 8 15s-2.78-2.548-6.672-6.485c-3.717-3.76 1.043-10.549 5.976-5.972.232.215.464.455.696.723.232-.267.464-.508.696-.723C13.63-2.04 18.39 4.68 14.672 8.515z">
                                        </path>
                                        <path d="m8 4.79-.755-.869c-1.17-1.348-2.252-1.832-3.093-1.9-.836-.067-1.59.263-2.164.858C.802 4.108.528 6.283 2.04 7.812a245.96 245.96 0 0 0 4.775 4.7c.482.46.882.837 1.186 1.122.304-.285.704-.663 1.186-1.123a238.026 238.026 0 0 0 4.771-4.695 3.545 3.545 0 0 0 .057-4.963c-.572-.589-1.324-.915-2.161-.843-.843.072-1.926.562-3.098 1.911L8 4.791zm6.672 3.725C10.78 12.452 8 15 8 15s-2.78-2.548-6.672-6.485c-3.717-3.76 1.043-10.549 5.976-5.972.232.215.464.455.696.723.232-.267.464-.508.696-.723C13.63-2.04 18.39 4.68 14.672 8.515z">
                                        </path>
                                    </svg>
                                }
                                {account && account.account && account.account.liked_album_ids && account.account.liked_album_ids.includes(album._id) && // liked
                                    <svg id="liked-album-svg" viewBox="0 0 16 16" focusable="false" className="chakra-icon css-1qsky21" data-testid="HeartFillIcon">
                                        <path d="M8 3.266C2.837-2.68-2.564 4.578 1.328 8.516 5.22 12.451 8 15 8 15s2.78-2.548 6.672-6.485C18.564 4.501 13.162-2.679 8 3.265z">
                                        </path>
                                    </svg>
                                }
                            </div>
                        }

                        <div className='album-btn-container component-btn-container options-btn-container' onClick={(e) => {
                            e.preventDefault()
                            setIsCollectionPopup(false)
                            setActiveAlbumPopup(index)
                        }}>
                            <svg viewBox="0 0 16 16" focusable="false" className="chakra-icon css-1yk3h4a" data-testid="EllipsisIcon" aria-hidden="true"><path d="M5 8.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm5 0a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm3.5 1.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"></path></svg>
                        </div>

                    </div>
                </div>
            </NavLink>
            <div className='album-infos-container'>
                <div className='album-title' title={album.album_name}>{truncateString(album.album_name, 72)}</div>
                <div className='album-highlighted-info album-added-by-container'>
                    {getShortDate(album.date_added)}, by <NavLink to={"/profiles/" + album.contributors.added_by_public_id} ><span>{album.contributors.added_by}</span></NavLink>
                </div>
                {album.like_count > 0 &&
                    <div className='album-highlighted-info album-likes-container album-year'>
                        {album.like_count + " likes"}
                    </div>
                }
            </div>

            {activeAlbumPopup === index && (
                <div ref={popupRef} className="album-popup-container component-popup-container">
                    {!isCollectionPopup &&
                        <ul>
                            {fromFavorites !== true && account && account.account && album.canEdit &&
                                <li className='mobile-hidden2' onClick={(e) => {
                                    e.preventDefault()
                                    setEditAlbumPopupVisible(true)
                                    setEditingAlbumId(album._id)
                                }}>
                                    Edit album
                                </li>
                            }

                            {fromFavorites !== true && account && account.account && album.canEdit &&
                                <li onClick={(e) => {
                                    e.preventDefault()
                                    setActiveAlbumPopup(-1)
                                    deleteAlbum(album)
                                }}>
                                    Delete album
                                </li>
                            }

                            {!isGamePage &&
                                <li onClick={(e) => {
                                    e.preventDefault()
                                    navigate(`/games/${album.game_slug}/${album.game_id}`)
                                }}>
                                    Game page
                                </li>
                            }

                            {account?.account?.sub &&
                                <li onClick={(e) => {
                                    e.preventDefault()
                                    setIsCollectionPopup(true)
                                }}>
                                    Add to collection
                                </li>
                            }

                            {account && account.account && isCollectionPage && album.canEditCollection &&
                                <li onClick={(e) => {
                                    e.preventDefault()
                                    setActiveAlbumPopup(false)
                                    removeFromCollection(album._id, fromCollection._id, "album")
                                }}>
                                    Remove from collection
                                </li>
                            }

                            <li onClick={(e) => {
                                e.preventDefault()
                                setActiveAlbumPopup(-1)
                            }}>
                                Cancel
                            </li>

                        </ul>
                    }
                    {isCollectionPopup &&
                        <ul className='add-to-collection-popup'>
                            <li id="find-collection">
                                <input type="text" placeholder='Search collection...'
                                    value={inlineCollectionQuery}
                                    onChange={handleInlineInputChange}
                                />
                            </li>
                            {accountCollections.filter(collection => collection.collection_type === "album" && collection.collection_name.toLowerCase().includes(inlineCollectionQuery.toLowerCase())).slice(0, 3).map(collection => {
                                return (
                                    <li onClick={(e) => {
                                        e.preventDefault()
                                        setIsCollectionPopup(false)
                                        setActiveAlbumPopup(-1)
                                        addToCollection(album._id, collection._id, "album")
                                    }}>{collection.collection_name}</li>
                                )
                            })}
                            <li onClick={(e) => {
                                e.preventDefault()
                                setActiveAlbumPopup(-1)
                            }}>
                                Cancel
                            </li>
                        </ul>
                    }
                </div>
            )}

        </div>
    );
}


export default AlbumWrapper;
