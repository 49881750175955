import React, { useRef, useState, useEffect } from 'react';
import useFunctions from './Functions';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import GameWrapper from './GameWrapper';
import GameFilters from './GameFilters';
import { setGamesQueryPage } from '../actions/filters';
import GameWrapperSimplified from './GameWrapperSimplified';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { toast } from 'react-toastify';


function GamesWrapper(props) {

    const dispatch = useDispatch();
    const popupRef = useRef(null);
    const { fromHomepage, totalResultsCount, games, fromCollection, setCollectionGames } = props;
    const user = useSelector((state) => state.user);
    const { mixGameResults, getAccountCollections } = useFunctions();
    const [activeGamePopup, setActiveGamePopup] = useState(-1);

    const location = useLocation();
    const isCollectionPage = location.pathname.includes('/collection/');
    const [accountCollections, setAccountCollections] = useState([]);
    const [isOrderMode, setIsOrderMode] = useState(false);

    const gamesQueryPage = useSelector((state) => state.filters.gamesQueryPage);


    useEffect(() => {
        if (user.user.sub && user.user.email) {
            getAccountCollections().then(collections => {
                setAccountCollections(collections)
            })
        }
    }, [user.user.sub, user.user.email])


    const moveGame = (dragIndex, hoverIndex) => {
        setCollectionGames((prevGames) => {
            const newGames = [...prevGames];
            const draggedGame = newGames[dragIndex];

            // Swap positions
            newGames[dragIndex] = newGames[hoverIndex];
            newGames[hoverIndex] = draggedGame;

            return newGames;
        });
    };

    const submitNewOrder = () => {
        const editFormData = new FormData();
        editFormData.append("updatedCollectionId", fromCollection._id);
        editFormData.append("sub", user.user.sub);
        let newIds = games.map(e => e.id)
        editFormData.append("updated_collection_ids", JSON.stringify(newIds));
        let responseStatus;
        fetch(`${process.env.REACT_APP_API_URL}/update-collection-infos`, {
            method: 'POST',
            // body: JSON.stringify({ updatedAlbumId, updatedData, sub: user.user.sub }),
            body: editFormData,
        })
            .then(response => {
                responseStatus = response.status
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                // console.log('Response data:', data);
                toast.success('Order successfully updated.', {
                    position: "bottom-center",
                    autoClose: 3500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });

                if (responseStatus === 200) {
                    setIsOrderMode(false)
                }

            })
            .catch(error => {
                console.error('Error:', error);
                toast.error('Error editing collection.', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            });
    }

    const onPageChange = (newPageNum) => {
        dispatch(setGamesQueryPage(newPageNum))
    }

    return (

        <div>
            {!isOrderMode &&
                <div className='games-wrapper'>

                    <h1>Games</h1>

                    {fromHomepage && <GameFilters />}

                    <div className="common-form-btns">
                        {/* <button disabled onClick={() => mixGameResults(games.map(e => e.id))}>Mix Results</button> */}
                        <button disabled={games.length === 0} onClick={() => mixGameResults(games.map(e => e.id))}>
                            Mix Results
                        </button>
                        {fromCollection && fromCollection.canEditCollection === true && games.length > 0 && <button className='mobile-hidden' onClick={() => setIsOrderMode(!isOrderMode)}>Edit Order</button>}
                    </div>

                    {games.length === 0 &&
                        <p className='no-games' style={{ "textAlign": "left" }}>No games found.</p>
                    }

                    <div className='game-results' >
                        {games.map((game, index) => (
                            <GameWrapper
                                game={game}
                                index={index}
                                setActiveGamePopup={setActiveGamePopup}
                                activeGamePopup={activeGamePopup}
                                popupRef={popupRef}
                                accountCollections={accountCollections}
                                isCollectionPage={isCollectionPage}
                                fromCollection={fromCollection}
                            />
                        ))}
                    </div>


                    {games.length > 0 && totalResultsCount > 1 &&
                        <div className='pagination-container'>
                            <button className='pagination-btn' onClick={() => onPageChange(gamesQueryPage - 1)} disabled={gamesQueryPage === 1}>
                                Previous
                            </button>
                            <button className='pagination-btn' onClick={() => onPageChange(gamesQueryPage + 1)} disabled={gamesQueryPage >= totalResultsCount}>
                                Next
                            </button>
                        </div>
                    }

                </div>
            }
            {isOrderMode && isCollectionPage &&
                <DndProvider backend={HTML5Backend}>

                    <div className="common-form-btns" style={{ position: "relative", marginTop: "40px" }}>
                        {<button onClick={() => setIsOrderMode(!isOrderMode)}>Cancel</button>}
                        {<button onClick={() => submitNewOrder()}>Submit New Order</button>}
                    </div>

                    <div className='games-wrapper-simplified' >

                        {games.map((game, index) => (
                            <GameWrapperSimplified
                                game={game}
                                index={index}
                                popupRef={popupRef}
                                accountCollections={accountCollections}
                                isCollectionPage={isCollectionPage}
                                fromCollection={fromCollection}
                                moveGame={moveGame}
                                key={game.id}
                            />
                        ))}

                    </div>
                </DndProvider>
            }
        </div>

    );
}


export default GamesWrapper;
