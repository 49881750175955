export const ADD_TO_PLAYLIST = 'ADD_TO_PLAYLIST';
export const PLAY_NEXT_SONG = 'PLAY_NEXT_SONG';
export const PLAY_PREVIOUS_SONG = 'PLAY_PREVIOUS_SONG';
export const SET_PLAYLIST = 'SET_PLAYLIST'; // New action


export function addToPlaylist(song) {
  return { type: ADD_TO_PLAYLIST, song };
}

export function playNextSong() {
  return { type: PLAY_NEXT_SONG };
}

export function playPreviousSong() {
  return { type: PLAY_PREVIOUS_SONG };
}

export function setPlaylist(newPlaylist) {
  return { type: SET_PLAYLIST, newPlaylist };
}