import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import React, { useEffect } from 'react';

function LeftBar() {

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            const message = "Are you sure you want to leave? Playback will be lost.";
            event.returnValue = message;
            return message;
        };

        const shouldAddEventListener = window.innerWidth < 769;
        if (shouldAddEventListener) {
            window.addEventListener('beforeunload', handleBeforeUnload);
        }

        return () => {
            // Remove the event listener if it was added
            if (shouldAddEventListener) {
                window.removeEventListener('beforeunload', handleBeforeUnload);
            }
        };
    }, []);

    return (
        <>
            <div className='left-bar'>
                <NavLink to="/">
                    {/* <img alt="" className='site-logo' src="https://i.ibb.co/ZLxhLT5/222.png"></img> */}
                </NavLink>

                <div className='leftbar-titles'>
                    <NavLink to="/" activeclassname="active">
                        <div className='leftbar-title-container'>
                            <div className='leftbar-title-icon'>
                                <svg className="svg-icon" focusable="false" height="24" role="img" width="24" viewBox="0 0 24 24" aria-hidden="true"><path fillRule="evenodd" d="M9 7.834V4.858l11-1.692v2.976L9 7.834zm11-.68L9 8.846V19c0 1.657-1.567 3-3.5 3S2 20.657 2 19s1.567-3 3.5-3c.98 0 1.865.345 2.5.9V4l12-1.846L21 2v15c0 1.657-1.567 3-3.5 3S14 18.657 14 17s1.567-3 3.5-3c.98 0 1.865.345 2.5.9V7.154zM17.5 15c1.53 0 2.5 1.035 2.5 2 0 .965-.97 2-2.5 2S15 17.965 15 17c0-.965.97-2 2.5-2zM8 19c0-.965-.97-2-2.5-2S3 18.035 3 19c0 .965.97 2 2.5 2S8 19.965 8 19z"></path></svg>
                            </div>
                            <div className='leftbar-title-text'>Games</div>
                        </div>
                    </NavLink>
                    <NavLink to="/albums" activeclassname="active">
                        <div className='leftbar-title-container'>
                            <div className='leftbar-title-icon'>

                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                    <g>
                                        <path fill="currentColor" d="M256 0C114.609 0 0 114.625 0 256s114.609 256 256 256s256-114.625 256-256S397.391 0 256 0zM96.469 149.891L95.031 152.063L74.562 138.438L76.015 136.25C88.594 87.375 160.344 53.469 218 43.219L220.578 42.766L225.891 66.969L223.313 67.422C194.422 110.766 148.562 140.828 96.469 149.891zM145.781 186.688L125.062 172.735L126.875 170.032C150.313 135.204 187.359 110.719 228.5 102.875L231.703 102.234L236.375 126.781L233.172 127.422C198.531 134.078 167.344 154.688 147.578 184.016L145.781 186.688zM159.578 256c0-53.25 43.172-96.406 96.422-96.422c53.25 0.016 96.406 43.172 96.406 96.422S309.25 352.406 256 352.406S159.578 309.25 159.578 256zM280.938 415.25L276.906 390.594L280.141 390.063C316.75 384.094 349.5 362.859 369.953 331.875L371.75 329.156L392.625 342.875L390.828 345.594C366.562 382.375 327.688 407.563 284.172 414.688L280.938 415.25zM436 375.734C403.406 424.625 351.656 458.547 294 468.797L291.422 469.234L287.141 445.031L289.719 444.594C340.781 435.547 386.672 405.484 415.563 362.109L416.969 359.922L437.25 373.578L436 375.734z" />
                                        <path fill="currentColor" d="M308.359 203.641C294.938 190.219 276.469 181.969 256 181.953C235.547 181.969 217.078 190.234 203.656 203.641C190.265 217.063 181.984 235.531 182 256C181.984 276.469 190.265 294.938 203.656 308.359C217.078 321.781 235.547 330.031 256 330.047C276.469 330.031 294.938 321.781 308.359 308.359C321.75 294.938 330.031 276.469 330.031 256C330.031 235.531 321.75 217.063 308.359 203.641zM256 295.391C234.25 295.391 216.625 277.75 216.625 256S234.25 216.625 256 216.625S295.375 234.25 295.375 256S277.75 295.391 256 295.391z" />
                                    </g>
                                </svg>


                            </div>
                            <div className='leftbar-title-text'>Albums</div>
                        </div>
                    </NavLink>
                    <NavLink to="/collections" activeclassname="active">
                        <div className='leftbar-title-container'>
                            <div className='leftbar-title-icon'>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="#000000" width="100px" height="100px" viewBox="0 0 24 24">
                                    <path d="M20,19 C20,20.1045695 19.1045695,21 18,21 L6,21 C4.8954305,21 4,20.1045695 4,19 L3,19 C1.8954305,19 1,18.1045695 1,17 L1,7 C1,5.8954305 1.8954305,5 3,5 L4,5 C4,3.8954305 4.8954305,3 6,3 L18,3 C19.1045695,3 20,3.8954305 20,5 L21,5 C22.1045695,5 23,5.8954305 23,7 L23,17 C23,18.1045695 22.1045695,19 21,19 L20,19 Z M18,19 L18,5 L6,5 L6,19 L18,19 Z M12,12.1404647 L12,7.13148291 L16.5547002,10.1679497 L15.4452998,11.8320503 L14,10.8685171 L14,14.5 C14,15.9534271 12.6045695,17 11,17 C9.3954305,17 8,15.9534271 8,14.5 C8,13.0465729 9.3954305,12 11,12 C11.3471248,12 11.6844618,12.0489806 12,12.1404647 Z M4,7 L3,7 L3,17 L4,17 L4,7 Z M20,7 L20,17 L21,17 L21,7 L20,7 Z M11,15 C11.6045695,15 12,14.7034271 12,14.5 C12,14.2965729 11.6045695,14 11,14 C10.3954305,14 10,14.2965729 10,14.5 C10,14.7034271 10.3954305,15 11,15 Z" />
                                </svg>
                            </div>
                            <div className='leftbar-title-text'>Collections</div>
                        </div>
                    </NavLink>

                    {/* <NavLink to="/artists" activeclassname="active">
                        <div className='leftbar-title-container'>
                            <div className='leftbar-title-icon'>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="#000000" width="100px" height="100px" viewBox="0 0 1024 1024"><path d="M746 835.28L544.529 723.678c74.88-58.912 95.216-174.688 95.216-239.601v-135.12c0-89.472-118.88-189.12-238.288-189.12-119.376 0-241.408 99.664-241.408 189.12v135.12c0 59.024 24.975 178.433 100.624 239.089L54 835.278S0 859.342 0 889.342v81.088c0 29.84 24.223 54.064 54 54.064h692c29.807 0 54.031-24.224 54.031-54.064v-81.087c0-31.808-54.032-54.064-54.032-54.064zm-9.967 125.215H64.002V903.28c4.592-3.343 11.008-7.216 16.064-9.536 1.503-.688 3.007-1.408 4.431-2.224l206.688-112.096c18.848-10.224 31.344-29.184 33.248-50.528s-7.008-42.256-23.712-55.664c-53.664-43.024-76.656-138.32-76.656-189.152V348.96c0-45.968 86.656-125.12 177.408-125.12 92.432 0 174.288 78.065 174.288 125.12v135.12c0 50.128-15.568 145.84-70.784 189.28a64.098 64.098 0 0 0-24.224 55.664 64.104 64.104 0 0 0 33.12 50.849l201.472 111.6c1.777.975 4.033 2.031 5.905 2.848 4.72 2 10.527 5.343 14.783 8.288v57.887zM969.97 675.936L765.505 564.335c74.88-58.912 98.224-174.688 98.224-239.601v-135.12c0-89.472-121.872-190.128-241.28-190.128-77.6 0-156.943 42.192-203.12 96.225 26.337 1.631 55.377 1.664 80.465 9.664 33.711-26.256 76.368-41.872 122.656-41.872 92.431 0 177.278 79.055 177.278 126.128v135.12c0 50.127-18.56 145.84-73.775 189.28a64.098 64.098 0 0 0-24.224 55.664 64.104 64.104 0 0 0 33.12 50.848l204.465 111.6c1.776.976 4.032 2.032 5.904 2.848 4.72 2 10.527 5.344 14.783 8.288v56.912H830.817c19.504 14.72 25.408 35.776 32.977 64h106.192c29.807 0 54.03-24.224 54.03-54.064V730.03c-.015-31.84-54.047-54.096-54.047-54.096z" /></svg>
                            </div>
                            <div className='leftbar-title-text'>Artists</div>
                        </div>
                    </NavLink> */}

                    <NavLink to="/favorites" activeclassname="active">
                        <div className='leftbar-title-container'>
                            <div className='leftbar-title-icon'>
                                <svg className="svg-icon" focusable="false" height="24" role="img" width="24" viewBox="0 0 24 24" aria-hidden="true"><path fillRule="evenodd" d="m12 7.463-.764-.905c-1.497-1.773-2.903-2.437-4.028-2.53-1.117-.092-2.127.363-2.893 1.18-1.573 1.676-1.948 4.666.066 6.761a301.42 301.42 0 0 0 5.974 6.047c.692.68 1.249 1.22 1.645 1.601.396-.381.953-.921 1.645-1.6a301.444 301.444 0 0 0 5.97-6.044 4.963 4.963 0 0 0 .072-6.8c-.763-.809-1.771-1.258-2.89-1.16-1.127.1-2.535.77-4.033 2.545L12 7.463zM12 21s-3.475-3.276-8.34-8.338C-1.034 7.778 5.087-1.08 11.32 5.17c.226.227.453.474.68.743a12.9 12.9 0 0 1 .68-.744c6.233-6.256 12.354 2.513 7.66 7.493C15.475 17.724 12 21 12 21z"></path></svg>
                            </div>
                            <div className='leftbar-title-text'>Profile</div>
                        </div>
                    </NavLink>

                    {/* <NavLink to="/stats">
                        <div className='leftbar-title-container'>
                            <div className='leftbar-title-icon'>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="#000000" width="60px" height="60px" viewBox="0 0 512 512"><title>ionicons-v5-q</title><path d="M104,496H72a24,24,0,0,1-24-24V328a24,24,0,0,1,24-24h32a24,24,0,0,1,24,24V472A24,24,0,0,1,104,496Z" /><path d="M328,496H296a24,24,0,0,1-24-24V232a24,24,0,0,1,24-24h32a24,24,0,0,1,24,24V472A24,24,0,0,1,328,496Z" /><path d="M440,496H408a24,24,0,0,1-24-24V120a24,24,0,0,1,24-24h32a24,24,0,0,1,24,24V472A24,24,0,0,1,440,496Z" /><path d="M216,496H184a24,24,0,0,1-24-24V40a24,24,0,0,1,24-24h32a24,24,0,0,1,24,24V472A24,24,0,0,1,216,496Z" /></svg>
                            </div>
                            <div className='leftbar-title-text'>Stats</div>
                        </div>
                    </NavLink> */}

                    <NavLink to="/faq">
                        <div className='leftbar-title-container'>
                            <div className='leftbar-title-icon'>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="#000000" width="800px" height="800px" viewBox="0 0 24 24"><path d="M12,22A10,10,0,1,0,2,12,10,10,0,0,0,12,22Zm0-2a1.5,1.5,0,1,1,1.5-1.5A1.5,1.5,0,0,1,12,20ZM8,8.994a3.907,3.907,0,0,1,2.319-3.645,4.061,4.061,0,0,1,3.889.316,4,4,0,0,1,.294,6.456,3.972,3.972,0,0,0-1.411,2.114,1,1,0,0,1-1.944-.47,5.908,5.908,0,0,1,2.1-3.2,2,2,0,0,0-.146-3.23,2.06,2.06,0,0,0-2.006-.14,1.937,1.937,0,0,0-1.1,1.8A1,1,0,0,1,8,9Z" /></svg>
                            </div>
                            <div className='leftbar-title-text'>FAQ</div>
                        </div>
                    </NavLink>

                    {/* <NavLink to="/settings">
                        <div className='leftbar-title-container'>
                            <div className='leftbar-title-icon'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="100px" height="100px" viewBox="0 0 48 48" id="Layer_2" data-name="Layer 2"><defs><style>.cls-1</style></defs><path className="cls-1" d="M39.23,26a16.52,16.52,0,0,0,.14-2,16.52,16.52,0,0,0-.14-2l4.33-3.39a1,1,0,0,0,.25-1.31l-4.1-7.11a1,1,0,0,0-1.25-.44l-5.11,2.06a15.68,15.68,0,0,0-3.46-2l-.77-5.43a1,1,0,0,0-1-.86H19.9a1,1,0,0,0-1,.86l-.77,5.43a15.36,15.36,0,0,0-3.46,2L9.54,9.75a1,1,0,0,0-1.25.44L4.19,17.3a1,1,0,0,0,.25,1.31L8.76,22a16.66,16.66,0,0,0-.14,2,16.52,16.52,0,0,0,.14,2L4.44,29.39a1,1,0,0,0-.25,1.31l4.1,7.11a1,1,0,0,0,1.25.44l5.11-2.06a15.68,15.68,0,0,0,3.46,2l.77,5.43a1,1,0,0,0,1,.86h8.2a1,1,0,0,0,1-.86l.77-5.43a15.36,15.36,0,0,0,3.46-2l5.11,2.06a1,1,0,0,0,1.25-.44l4.1-7.11a1,1,0,0,0-.25-1.31ZM24,31.18A7.18,7.18,0,1,1,31.17,24,7.17,7.17,0,0,1,24,31.18Z" /></svg>
                            </div>
                            <div className='leftbar-title-text'>Settings</div>
                        </div>
                    </NavLink> */}

                </div>
            </div >
        </>
    );
}

const mapDispatchToProps = {

};

export default connect(null, mapDispatchToProps)(LeftBar);