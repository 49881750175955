import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import useFunctions from './Functions';
import { NavLink } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router-dom';

function PlaybarSimple() {

    const location = useLocation();
    const [shouldRender, setShouldRender] = useState(true);

    useEffect(() => {
        // Check if the current pathname is not "/playing"
        setShouldRender(location.pathname !== '/playing');
    }, [location.pathname]);

    const playingSongObject = useSelector(state => state.playback.playingSongObject)
    const playback = useSelector((state) => state.playback);
    const { likeSong, truncateString, handleNextClick, togglePlayback, useSongEffect } = useFunctions();
    const account = useSelector((state) => state.account);
    const navigate = useNavigate();


    return shouldRender && playingSongObject ? (

        <div className='playbar-container--simple' onClick={() => navigate('/playing')} >
            <div className='play-controls'>
                <div className='play-control play' onClick={(e) => {
                    e.stopPropagation();
                    togglePlayback()
                }}>

                    {playback.isPaused &&
                        <svg viewBox="0 0 16 16" focusable="false" className="chakra-icon css-c1x3e4" data-testid="PlayIcon"><path d="m3 1 12 7-12 7V1z"></path></svg>
                    }
                    {!playback.isPaused &&
                        <svg viewBox="0 0 16 16" focusable="false" className="chakra-icon css-c1x3e4" data-testid="PauseIcon"><path d="M6 0H2v16h4V0zm8 0h-4v16h4V0z"></path></svg>
                    }

                </div>
            </div>
            <div className='playback-info' >
                <div className='playing-text'>
                    <div className='playing-game' title={playingSongObject.game_name} >
                        <NavLink to={"/games/all/" + playingSongObject.game_id} onClick={(e) => e.stopPropagation()} >
                            {truncateString(playingSongObject.game_name, 35)}
                        </NavLink>
                    </div>
                    <div className='playing-title' title={playingSongObject.song_title} >
                        {truncateString(playingSongObject.song_title, 40)}
                    </div>
                </div>
            </div>
            <div className='song-option-buttons'>
                {account && account.account && account.account.liked_songs && playingSongObject &&
                    <div className="song-option-btn" onClick={(e) => {
                        e.stopPropagation();
                        likeSong()
                    }}>
                        {account.account.liked_songs.find(e => e.youtube_id === playingSongObject.youtube_id) &&
                            <svg id="svg-liked" viewBox="0 0 16 16" focusable="false" className="chakra-icon css-1qsky21" data-testid="HeartFillIcon" aria-hidden="true"><path d="M8 3.266C2.837-2.68-2.564 4.578 1.328 8.516 5.22 12.451 8 15 8 15s2.78-2.548 6.672-6.485C18.564 4.501 13.162-2.679 8 3.265z"></path></svg>
                        }
                        {!account.account.liked_songs.find(e => e.youtube_id === playingSongObject.youtube_id) &&
                            <svg viewBox="0 0 16 16" focusable="false" className="chakra-icon css-1yk3h4a" data-testid="HeartIcon" aria-hidden="true"><path d="m8 4.79-.755-.869c-1.17-1.348-2.252-1.832-3.093-1.9-.836-.067-1.59.263-2.164.858C.802 4.108.528 6.283 2.04 7.812a245.96 245.96 0 0 0 4.775 4.7c.482.46.882.837 1.186 1.122.304-.285.704-.663 1.186-1.123a238.026 238.026 0 0 0 4.771-4.695 3.545 3.545 0 0 0 .057-4.963c-.572-.589-1.324-.915-2.161-.843-.843.072-1.926.562-3.098 1.911L8 4.791zm6.672 3.725C10.78 12.452 8 15 8 15s-2.78-2.548-6.672-6.485c-3.717-3.76 1.043-10.549 5.976-5.972.232.215.464.455.696.723.232-.267.464-.508.696-.723C13.63-2.04 18.39 4.68 14.672 8.515z"></path><path d="m8 4.79-.755-.869c-1.17-1.348-2.252-1.832-3.093-1.9-.836-.067-1.59.263-2.164.858C.802 4.108.528 6.283 2.04 7.812a245.96 245.96 0 0 0 4.775 4.7c.482.46.882.837 1.186 1.122.304-.285.704-.663 1.186-1.123a238.026 238.026 0 0 0 4.771-4.695 3.545 3.545 0 0 0 .057-4.963c-.572-.589-1.324-.915-2.161-.843-.843.072-1.926.562-3.098 1.911L8 4.791zm6.672 3.725C10.78 12.452 8 15 8 15s-2.78-2.548-6.672-6.485c-3.717-3.76 1.043-10.549 5.976-5.972.232.215.464.455.696.723.232-.267.464-.508.696-.723C13.63-2.04 18.39 4.68 14.672 8.515z"></path></svg>
                        }
                    </div>
                }
                <div className='play-control next' onClick={(e) => {
                    e.stopPropagation();
                    handleNextClick()
                }}>
                    <svg viewBox="0 0 16 16" focusable="false" className="chakra-icon css-1yk3h4a" data-testid="StepForwardIcon"><path d="M1 1v14l11-6.217V15h1V1h-1v6.217L1 1z"></path></svg>
                </div>
            </div>
        </div>

    ) : null;
}



export default PlaybarSimple;
