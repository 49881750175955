import React, { useState, useEffect, useRef } from 'react';
import CollectionWrapper from '../components/CollectionWrapper';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

function CollectionsWrapper(props) {

    const popupRef = useRef(null);

    const account = useSelector((state) => state.account);
    const user = useSelector((state) => state.user);
    const { setBrowseOwned, setCollectionsQueryPage, collectionResults, fetchCollections, fromFavorites, collectionsQueryPage, totalResultsCount } = props;

    /* start */
    const [addCollectionPopupVisible, setAddCollectionPopupVisible] = useState(false);
    const [addCollectionStep, setAddCollectionStep] = useState(1);
    const [addCollectionName, setAddCollectionName] = useState("")
    const [addCollectionDescription, setAddCollectionDescription] = useState("")
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedValue, setSelectedValue] = useState('album');
    const [dominantCollectionImageColors, setDominantCollectionImageColors] = useState([]);
    const imageRef = useRef(null);
    const fileInputRef = useRef(null);

    const [activeCollectionPopup, setActiveCollectionPopup] = useState(-1);
    const [editCollectionPopupVisible, setEditCollectionPopupVisible] = useState(false);
    const [editingCollectionId, setEditingCollectionId] = useState(null);
    const [editCollectionName, setEditCollectionName] = useState("")
    const [editCollectionDescription, setEditCollectionDescription] = useState("")
    const [editedSelectedImage, setEditedSelectedImage] = useState(null);
    const [editDominantCollectionImageColors, setEditDominantCollectionImageColors] = useState([]);
    const [editMode, setEditMode] = useState(null); // for collections!
    const editedImageRef = useRef(null);
    const editedFileInputRef = useRef(null);

    const [activeButton, setActiveButton] = useState('likedCollections'); // Default to 'myCollections'

    const handleSelectChange = (event) => {
        const newValue = event.target.value;
        setSelectedValue(newValue);
    };

    const refreshCollections = (chosenCollectionId) => {
        // chosenCollectionId is optional. it will be only passed if collection is edited (?)
        // it won't be needed if it's just called when adding a new collection
        fetchCollections()
    }

    const saveCollection = async (formData) => {
        return new Promise(async (resolve, reject) => {
            try {

                const collectionFormData = new FormData();
                let requiredAccData = { username: account.account.username, public_user_id: account.account.public_user_id, email: user.user.email, sub: user.user.sub }

                collectionFormData.append("collection_name", formData.get("collectionName"));
                collectionFormData.append("collection_type", formData.get("collection_type"));
                collectionFormData.append("collection_description", formData.get("collection_description"));
                // also add dominant colors
                collectionFormData.append("collection_image_colors", JSON.stringify(dominantCollectionImageColors))

                collectionFormData.append("added_by_account", JSON.stringify(requiredAccData));

                // Append the image file , LATER ADD IF EXIST
                collectionFormData.append("collection_image", selectedImage);

                fetch(`${process.env.REACT_APP_API_URL}/add-collection`, {
                    method: 'POST',
                    body: collectionFormData,
                })
                    .then(response => {
                        if (!response.ok) {
                            throw new Error('Network response was not ok');
                        }
                        return response.json();
                    })
                    .then(data => {
                        /*
                        const newAddedCollectionCount = data.updatedCount
                        let tmpAcc = account.account
                        tmpAcc.contributions.contributedCollectionCount = newAddedCollectionCount
                        dispatch(setAccount(tmpAcc))
                        */

                        refreshCollections() // when a new album is added or song is edited, users wont have to reload.

                        toast.success('Collection is added', {
                            position: "bottom-center",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });

                        resetForm()
                        resolve(true)
                    })
                    .catch(error => {
                        toast.error('Error adding album.', {
                            position: "bottom-center",
                            autoClose: 3500,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                        console.error('Error:', error);
                    });

            } catch (e) {

            }
        });
    };

    const addCollection = async (formData) => {
        saveCollection(formData)
        /* no need to wait for fetch response for these */
        setAddCollectionPopupVisible(false)
        setAddCollectionStep(1)
    }

    const handleCollectionDescriptionChange = (event) => {
        setAddCollectionDescription(event.target.value);
    };

    const handleCollectionSubmit = async (e) => {
        e.preventDefault();

        const collectionName = addCollectionName

        if (selectedImage) {
            const formData = new FormData();
            formData.append("collectionName", collectionName);
            formData.append("collection_type", selectedValue);
            formData.append("collection_description", addCollectionDescription);
            addCollection(formData)

        } else {
            // Handle case where no image is selected
            console.warn("No image selected");
        }
    };

    const handleCollectionNameChange = (event) => {
        setAddCollectionName(event.target.value);
    };

    const searchImage = () => {
        if (addCollectionName.trim().length === 0) return false
        let url = "https://www.google.com/search?q=" + addCollectionName + "&tbm=isch"
        window.open(url, "_blank");
    }

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    /* for adding */
    const handleImageFile = (file) => {
        setSelectedImage(file); // just for testing, maybe thats enough
        const reader = new FileReader();
        reader.onload = () => {
            // Display the dropped or pasted image
            imageRef.current.src = reader.result;
        };
        reader.readAsDataURL(file);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];

        if (file && file.type.startsWith('image/')) {
            handleImageFile(file);
        }
    };

    const handlePaste = (e) => {
        const items = e.clipboardData.items;

        for (let i = 0; i < items.length; i++) {
            if (items[i].type.indexOf('image') !== -1) {
                const blob = items[i].getAsFile();
                handleImageFile(blob);
                break;
            }
        }
    };

    const handleFileSelect = (e) => {
        const file = e.target.files[0];

        if (file && file.type.startsWith('image/')) {
            handleImageFile(file);
        }
    };

    /* color stuff start */

    const buildPalette = (dominantCollectionImageColors) => {
        const paletteContainer = document.getElementById("palette");
        const complementaryContainer = document.getElementById("complementary");
        paletteContainer.innerHTML = "";
        complementaryContainer.innerHTML = "";
        const orderedByColor = orderByLuminance(dominantCollectionImageColors);
        setDominantCollectionImageColors(orderedByColor)
    };

    const orderByLuminance = (rgbValues) => {
        const calculateLuminance = (p) => {
            return 0.2126 * p.r + 0.7152 * p.g + 0.0722 * p.b;
        };

        return rgbValues.sort((p1, p2) => {
            return calculateLuminance(p2) - calculateLuminance(p1);
        });
    };

    const buildRgb = (imageData) => {
        const rgbValues = [];
        for (let i = 0; i < imageData.length; i += 4) {
            const rgb = {
                r: imageData[i],
                g: imageData[i + 1],
                b: imageData[i + 2],
            };

            rgbValues.push(rgb);
        }

        return rgbValues;
    };

    const findBiggestColorRange = (rgbValues) => {

        let rMin = Number.MAX_VALUE;
        let gMin = Number.MAX_VALUE;
        let bMin = Number.MAX_VALUE;

        let rMax = Number.MIN_VALUE;
        let gMax = Number.MIN_VALUE;
        let bMax = Number.MIN_VALUE;

        rgbValues.forEach((pixel) => {
            rMin = Math.min(rMin, pixel.r);
            gMin = Math.min(gMin, pixel.g);
            bMin = Math.min(bMin, pixel.b);

            rMax = Math.max(rMax, pixel.r);
            gMax = Math.max(gMax, pixel.g);
            bMax = Math.max(bMax, pixel.b);
        });

        const rRange = rMax - rMin;
        const gRange = gMax - gMin;
        const bRange = bMax - bMin;

        const biggestRange = Math.max(rRange, gRange, bRange);
        if (biggestRange === rRange) {
            return "r";
        } else if (biggestRange === gRange) {
            return "g";
        } else {
            return "b";
        }
    };

    const quantization = (rgbValues, depth) => {
        const MAX_DEPTH = 4;

        if (depth === MAX_DEPTH || rgbValues.length === 0) {
            const color = rgbValues.reduce(
                (prev, curr) => {
                    prev.r += curr.r;
                    prev.g += curr.g;
                    prev.b += curr.b;

                    return prev;
                },
                {
                    r: 0,
                    g: 0,
                    b: 0,
                }
            );

            color.r = Math.round(color.r / rgbValues.length);
            color.g = Math.round(color.g / rgbValues.length);
            color.b = Math.round(color.b / rgbValues.length);

            return [color];
        }

        const componentToSortBy = findBiggestColorRange(rgbValues);
        rgbValues.sort((p1, p2) => {
            return p1[componentToSortBy] - p2[componentToSortBy];
        });

        const mid = rgbValues.length / 2;
        return [
            ...quantization(rgbValues.slice(0, mid), depth + 1),
            ...quantization(rgbValues.slice(mid + 1), depth + 1),
        ];
    };

    const setDominantColors = () => {
        const image = new Image();
        let file = selectedImage
        const fileReader = new FileReader();
        fileReader.onload = () => {
            image.onload = () => {
                const canvas = document.getElementById("canvas");
                canvas.width = image.width;
                canvas.height = image.height;
                const ctx = canvas.getContext("2d");
                ctx.drawImage(image, 0, 0);
                const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
                const rgbArray = buildRgb(imageData.data);
                const quantColors = quantization(rgbArray, 0);
                buildPalette(quantColors);
            };
            image.src = fileReader.result;
        };
        fileReader.readAsDataURL(file);

    }

    useEffect(() => {
        if (selectedImage) {
            setDominantColors()
        }
    }, [selectedImage])


    /* color stuff end */


    /* for editing */

    const editHandleImageFile = (file) => {
        setEditedSelectedImage(file) // just for testing, maybe thats enough
        const reader = new FileReader();
        reader.onload = () => {
            // Display the dropped or pasted image
            editedImageRef.current.src = reader.result;
        };
        reader.readAsDataURL(file);
    };

    const editHandleDrop = (e) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];

        if (file && file.type.startsWith('image/')) {
            editHandleImageFile(file);
        }
    };

    const editHandlePaste = (e) => {
        const items = e.clipboardData.items;

        for (let i = 0; i < items.length; i++) {
            if (items[i].type.indexOf('image') !== -1) {
                const blob = items[i].getAsFile();
                editHandleImageFile(blob);
                break;
            }
        }
    };

    const handleEditedFileSelect = (e) => {
        const file = e.target.files[0];

        if (file && file.type.startsWith('image/')) {
            editHandleImageFile(file);
        }
    };


    /* for editing end */

    const handleEditCollectionNameChange = (event) => {
        setEditCollectionName(event.target.value);
    };

    const handleEditCollectionDescriptionChange = (event) => {
        setEditCollectionDescription(event.target.value);
    };

    const resetForm = () => {
        // reset all the form (state) and hide popup
        setAddCollectionPopupVisible(false)
        setAddCollectionStep(1)
        setAddCollectionName("")
        setSelectedImage(null)
    }

    const checkNextStepAvailabity = (currentStep) => {
        if (currentStep === 1) {
            if (addCollectionName.trim() !== "") {
                return true
            } else {
                return false
            }
        }

        else if (currentStep >= 2) {
            if (selectedImage && dominantCollectionImageColors.length > 0) return true
            else return false
        }
    }

    const resetEditForm = () => {
        setEditCollectionPopupVisible(false)
        setEditingCollectionId(null)
        setEditMode(null)
        setEditCollectionName("")
        setEditedSelectedImage(null)
    }

    /* edit - img colors */

    const editBuildPalette = (dominantCollectionImageColors) => {
        const paletteContainer = document.getElementById("palette");
        const complementaryContainer = document.getElementById("complementary");
        paletteContainer.innerHTML = "";
        complementaryContainer.innerHTML = "";
        const orderedByColor = orderByLuminance(dominantCollectionImageColors);
        setEditDominantCollectionImageColors(orderedByColor)
    };

    const setEditedDominantColors = () => {
        const image = new Image();
        let file = editedSelectedImage;
        const fileReader = new FileReader();
        fileReader.onload = () => {
            image.onload = () => {
                const canvas = document.getElementById("canvas");
                canvas.width = image.width;
                canvas.height = image.height;
                const ctx = canvas.getContext("2d");
                ctx.drawImage(image, 0, 0);
                const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
                const rgbArray = buildRgb(imageData.data);
                const quantColors = quantization(rgbArray, 0);
                editBuildPalette(quantColors);
            };
            image.src = fileReader.result;
        };
        fileReader.readAsDataURL(file);

    }

    useEffect(() => {
        if (editedSelectedImage) {
            setEditedDominantColors()
        }
    }, [editedSelectedImage])


    /* end */

    const handleCollectionEdit = async (e) => {
        e.preventDefault()
        setActiveCollectionPopup(-1)
        const editFormData = new FormData();
        let updatedCollectionId = editingCollectionId
        setEditingCollectionId(null) // reset

        editFormData.append("updatedCollectionId", updatedCollectionId);
        editFormData.append("sub", user.user.sub);

        let correctCollectionName = editCollectionName
        if (correctCollectionName.trim() !== "") {
            editFormData.append("collection_name", correctCollectionName);
        }

        let correctCollectionDescription = editCollectionDescription
        if (correctCollectionDescription.trim() !== "") {
            editFormData.append("collection_description", correctCollectionDescription);
        }

        if (editedSelectedImage) {
            editFormData.append("collection_image", editedSelectedImage);
            editFormData.append("collection_image_colors", JSON.stringify(editDominantCollectionImageColors)); // submit btn will be enabled once process ends
        }

        let responseStatus;
        fetch(`${process.env.REACT_APP_API_URL}/update-collection-infos`, {
            method: 'POST',
            // body: JSON.stringify({ updatedAlbumId, updatedData, sub: user.user.sub }),
            body: editFormData,
        })
            .then(response => {
                responseStatus = response.status
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                // console.log('Response data:', data);
                toast.success('Collection successfully edited.', {
                    position: "bottom-center",
                    autoClose: 3500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });

                if (responseStatus === 200) {
                    // fetching again - (will act different based on page)
                    refreshCollections();
                }

            })
            .catch(error => {
                console.error('Error:', error);
                toast.error('Error editing collection.', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            });

        resetEditForm()
    }

    const onPageChange = (newPageNum) => {
        setCollectionsQueryPage(newPageNum)
    }

    const handleButtonClick = (buttonType) => {
        setActiveButton(buttonType);
    };


    return (

        <div className="collections-wrapper">

            {/* will be hidden  */}
            <canvas id="canvas"></canvas>
            <div id="palette"></div>
            <div id="complementary"></div>

            {addCollectionPopupVisible &&
                <form className='add-collection-form ' onSubmit={handleCollectionSubmit}>

                    <div className={`form-group ${addCollectionStep !== 1 ? 'hidden' : ''}`}>
                        <label htmlFor="collectionName">Collection Name:</label>
                        <input type="text" value={addCollectionName} onChange={handleCollectionNameChange} autoComplete="off" className="form-control" id="collectionName" placeholder="Enter the collection name" required />
                    </div>

                    <div className={`form-group ${addCollectionStep !== 1 ? 'hidden' : ''}`}>
                        <label htmlFor="collectionName">Collection Type:</label>
                        <div>
                            <select value={selectedValue} onChange={handleSelectChange}>
                                <option value="album">Album collection</option>
                                <option value="game">Game collection</option>
                            </select>
                        </div>
                    </div>

                    <div className={`form-group ${addCollectionStep !== 2 ? 'hidden' : ''}`}>
                        <label htmlFor="collectionImage" style={{ display: "block" }}>
                            <span className='label-category'>Collection Image: </span>
                            <span className='span-shortcut' style={{ cursor: "pointer" }} onClick={searchImage}>
                                [Search image

                                <svg id="new-tab" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52" enable-background="new 0 0 52 52" >
                                    <g>
                                        <path d="M48.7,2H29.6C28.8,2,28,2.5,28,3.3v3C28,7.1,28.7,8,29.6,8h7.9c0.9,0,1.4,1,0.7,1.6l-17,17   c-0.6,0.6-0.6,1.5,0,2.1l2.1,2.1c0.6,0.6,1.5,0.6,2.1,0l17-17c0.6-0.6,1.6-0.2,1.6,0.7v7.9c0,0.8,0.8,1.7,1.6,1.7h2.9   c0.8,0,1.5-0.9,1.5-1.7v-19C50,2.5,49.5,2,48.7,2z" />
                                        <path d="M36.3,25.5L32.9,29c-0.6,0.6-0.9,1.3-0.9,2.1v11.4c0,0.8-0.7,1.5-1.5,1.5h-21C8.7,44,8,43.3,8,42.5v-21   C8,20.7,8.7,20,9.5,20H21c0.8,0,1.6-0.3,2.1-0.9l3.4-3.4c0.6-0.6,0.2-1.7-0.7-1.7H6c-2.2,0-4,1.8-4,4v28c0,2.2,1.8,4,4,4h28   c2.2,0,4-1.8,4-4V26.2C38,25.3,36.9,24.9,36.3,25.5z" />
                                    </g>
                                </svg>

                                ]</span>
                        </label>

                        {!selectedImage &&
                            <div className='drag-image-wrapper'>
                                <div
                                    className='drag-info-container'
                                    onDrop={handleDrop}
                                    onPaste={handlePaste}
                                    onDragOver={handleDragOver}
                                    style={{ border: '2px dashed #ccc' }}
                                >
                                    <div>Drag and drop an image OR paste from clipboard OR manually select from your computer</div>
                                </div>
                                <div className='manual-file-select-container'>
                                    <input
                                        type="file"
                                        accept="image/*"
                                        onChange={handleFileSelect}
                                        ref={fileInputRef}
                                    />
                                </div>
                            </div>
                        }

                        {selectedImage &&
                            <img
                                ref={imageRef}
                                alt="Dropped or Pasted"
                                style={{ maxWidth: '100%', maxHeight: '190px', margin: '1px auto' }}
                            />
                        }

                    </div>

                    <div className={`form-group ${addCollectionStep !== 3 ? 'hidden' : ''}`}>
                        <label>Collection Description (optional):</label>
                        <textarea
                            value={addCollectionDescription}
                            onChange={handleCollectionDescriptionChange}
                            rows={4} // Set the number of visible rows
                            cols={50} // Set the number of visible columns
                            placeholder="Enter the collection description..."
                            className='form-control'
                            id="collectionDescription"
                        />
                    </div>

                    <div className='common-form-btns'>
                        {addCollectionStep > 1 &&
                            <button type="button" className='previous-step' onClick={() => {
                                setAddCollectionStep(addCollectionStep - 1)
                            }}>Previous</button>
                        }

                        {addCollectionStep === 2 && selectedImage &&
                            <button type="button" onClick={() => {
                                setSelectedImage(null)
                                setDominantCollectionImageColors([])
                            }}>Remove Image</button>
                        }

                        {addCollectionStep < 3 &&
                            <button type="button" className={`form-group ${checkNextStepAvailabity(addCollectionStep) ? '' : 'option-blocked'}`} onClick={() => {
                                setAddCollectionStep(addCollectionStep + 1)
                            }}>Next</button>
                        }
                        {addCollectionStep === 3 &&
                            <button type="submit" className={`${checkNextStepAvailabity(addCollectionStep) ? '' : 'option-blocked'}`}>Submit</button>
                        }

                        <button onClick={() => {
                            resetForm()
                        }}>Cancel</button>

                    </div>

                </form>
            }

            {collectionResults.length > 0 &&
                <h1 className='collections-header-title'>Collections</h1>
            }

            {collectionResults.length > 0 &&
                <div className='result-btns common-form-btns collection-results-btns'>
                    {fromFavorites && <button
                        className={activeButton === 'likedCollections' ? '' : 'not-selected-btn'}
                        onClick={() => {
                            handleButtonClick('likedCollections')
                            setBrowseOwned(false)
                        }}>Liked Collections</button>}
                    {fromFavorites && <button
                        className={activeButton === 'myCollections' ? '' : 'not-selected-btn'}
                        onClick={() => {
                            handleButtonClick('myCollections')
                            setBrowseOwned(true)
                        }}>My Collections</button>}
                </div>
            }

            <div className='collections-container'>

                {fromFavorites !== true && user.user.logged &&
                    <CollectionWrapper
                        collection={null}
                        setAddCollectionPopupVisible={setAddCollectionPopupVisible}
                        addCollectionPopupVisible={addCollectionPopupVisible}
                    />
                }

                {collectionResults.map((collection, index) => {
                    return (
                        <CollectionWrapper
                            collection={collection}
                            index={index}
                            popupRef={popupRef}
                            setEditCollectionPopupVisible={setEditCollectionPopupVisible}
                            setEditingCollectionId={setEditingCollectionId}
                            activeCollectionPopup={activeCollectionPopup}
                            setActiveCollectionPopup={setActiveCollectionPopup}
                        />
                    )
                })}
            </div>


            {collectionResults.length > 0 && totalResultsCount > 1 &&
                <div className='pagination-container'>
                    <button className='pagination-btn' onClick={() => onPageChange(collectionsQueryPage - 1)} disabled={collectionsQueryPage === 1}>
                        Previous
                    </button>
                    <button className='pagination-btn' onClick={() => onPageChange(collectionsQueryPage + 1)} disabled={collectionsQueryPage >= totalResultsCount}>
                        Next
                    </button>
                </div>
            }

            {editCollectionPopupVisible &&
                <form className='edit-game-form edit-component-form popup-form' onSubmit={handleCollectionEdit}>

                    {!editMode &&
                        <div className='edit-choose-btns'>
                            <button onClick={() => setEditMode("collectionName")}>Edit collection name</button>
                            <button onClick={() => setEditMode("collectionImage")}>Edit collection image</button>
                            <button onClick={() => setEditMode("collectionDescription")}>Edit collection description</button>
                        </div>
                    }

                    <div className={`form-group ${editMode !== "collectionName" ? 'hidden' : ''}`}>
                        <label htmlFor="correctCollectionName">Collection name: </label>
                        <input onChange={handleEditCollectionNameChange} value={editCollectionName} type="text" autoComplete="off" className="form-control" id="correctCollectionName" placeholder="New collection name" />
                    </div>

                    <div className={`form-group ${editMode !== "collectionImage" ? 'hidden' : ''}`}>
                        <label htmlFor="correctCollectionImage" style={{ display: "block" }}>Collection Image:
                        </label>

                        {!editedSelectedImage &&
                            <div className='drag-image-wrapper'>
                                <div
                                    className='drag-info-container'
                                    onDrop={editHandleDrop}
                                    onPaste={editHandlePaste}
                                    onDragOver={handleDragOver}
                                    style={{ border: '2px dashed #ccc' }}
                                >
                                    <div>Drag and drop an image OR paste from clipboard OR manually select from your computer</div>
                                </div>
                                <div className='manual-file-select-container'>
                                    <input
                                        type="file"
                                        accept="image/*"
                                        onChange={handleEditedFileSelect}
                                        ref={editedFileInputRef}
                                    />
                                </div>
                            </div>
                        }

                        {editedSelectedImage &&
                            <img
                                ref={editedImageRef}
                                alt="Dropped or Pasted"
                                style={{ maxWidth: '100%', maxHeight: '190px', margin: '1px auto' }}
                            />
                        }

                    </div>

                    <div className={`form-group ${editMode !== "collectionDescription" ? 'hidden' : ''}`}>
                        <label htmlFor="correctAlbumDescription">Collection description: </label>
                        <textarea
                            value={editCollectionDescription}
                            onChange={handleEditCollectionDescriptionChange}
                            rows={4} // Set the number of visible rows
                            cols={50} // Set the number of visible columns
                            placeholder="Enter the collection description..."
                            className='form-control'
                            id="correctCollectionDescription"
                        />

                    </div>


                    <div className='common-form-btns'>

                        {editMode &&
                            <button type="button" className='previous-step' onClick={() => {
                                setEditMode(null)
                            }}>Previous</button>
                        }

                        {editMode &&
                            <button type="submit" className={`${(editCollectionName || editCollectionDescription) || (editedSelectedImage && editDominantCollectionImageColors.length > 0) ? '' : 'option-blocked'}`}>Submit</button>
                        }

                        {editMode === "collectionImage" && editedSelectedImage &&
                            <button type="button" onClick={() => {
                                setEditedSelectedImage(null)
                            }}>Remove Image</button>
                        }

                        <button onClick={() => {
                            resetEditForm()
                        }} >Cancel</button>
                    </div>
                </form>
            }

        </div>
    );
}


export default CollectionsWrapper;
