import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import useFunctions from './Functions';
import { NavLink } from 'react-router-dom';


function GameWrapper(props) {

    const account = useSelector((state) => state.account);
    const { index, game, setActiveGamePopup,
        activeGamePopup, popupRef, accountCollections, isCollectionPage, fromCollection } = props;
    const { likeGame, playGameMusic, removeFromCollection,
        addToCollection } = useFunctions();
    const [inlineCollectionQuery, setInlineCollectionQuery] = useState("");
    const [isCollectionPopup, setIsCollectionPopup] = useState(false);
    const highlightMissing = useSelector((state) => state.filters.highlightMissing);

    const handleInlineInputChange = (event) => {
        const newValue = event.target.value;
        setInlineCollectionQuery(newValue);
    }

    
    return (

        <div className="game-container" key={index} >
            <NavLink to={'/games/' + game.slug + "/" + game.id} className='game-title' title={game.name}>
                <div className={`game-cover ${game.game_album_ids && highlightMissing && game.game_album_ids.length === 0 ? 'game--empty' : ''}`} style={{ backgroundImage: 'url(' + game.coverId + ')' }}>

                    <div className="game-btns-container">
                        <div className='game-btn-container play-btn-container' onClick={(e) => {
                            e.preventDefault();
                            playGameMusic(game.id);
                        }}>
                            <svg className="svg-icon svg-icon-play" focusable="false" height="1em" role="img" width="1em" viewBox="0 0 12 12" aria-hidden="true"><path fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="M2.5.5v11l9-5.5z"></path></svg>
                        </div>

                        <div className='game-btn-container options-btn-container' onClick={(e) => {
                            e.preventDefault();
                            setIsCollectionPopup(false)
                            setActiveGamePopup(index)

                        }}>
                            <svg viewBox="0 0 16 16" focusable="false" className="chakra-icon css-1yk3h4a" data-testid="EllipsisIcon" aria-hidden="true"><path d="M5 8.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm5 0a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm3.5 1.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"></path></svg>
                        </div>
                    </div>

                </div>
            </NavLink>

            <div className="game-title-text">
                {game.name.length < 50 ? game.name : game.name.substring(0, 50) + "..."}
            </div>

            {account && account.account && account.account.liked_game_ids &&
                <div className='svg-container svg-container--like-game' onClick={() => likeGame(game.id)}>

                    {account.account.liked_game_ids.find(e => e === game.id) &&
                        <svg id="liked-game-svg" viewBox="0 0 16 16" focusable="false" className="chakra-icon css-1qsky21" data-testid="HeartFillIcon" aria-hidden="true"><path d="M8 3.266C2.837-2.68-2.564 4.578 1.328 8.516 5.22 12.451 8 15 8 15s2.78-2.548 6.672-6.485C18.564 4.501 13.162-2.679 8 3.265z"></path></svg>
                    }
                    {!account.account.liked_game_ids.find(e => e === game.id) &&
                        <svg viewBox="0 0 16 16" focusable="false" className="chakra-icon css-1yk3h4a" data-testid="HeartIcon" aria-hidden="true"><path d="m8 4.79-.755-.869c-1.17-1.348-2.252-1.832-3.093-1.9-.836-.067-1.59.263-2.164.858C.802 4.108.528 6.283 2.04 7.812a245.96 245.96 0 0 0 4.775 4.7c.482.46.882.837 1.186 1.122.304-.285.704-.663 1.186-1.123a238.026 238.026 0 0 0 4.771-4.695 3.545 3.545 0 0 0 .057-4.963c-.572-.589-1.324-.915-2.161-.843-.843.072-1.926.562-3.098 1.911L8 4.791zm6.672 3.725C10.78 12.452 8 15 8 15s-2.78-2.548-6.672-6.485c-3.717-3.76 1.043-10.549 5.976-5.972.232.215.464.455.696.723.232-.267.464-.508.696-.723C13.63-2.04 18.39 4.68 14.672 8.515z"></path><path d="m8 4.79-.755-.869c-1.17-1.348-2.252-1.832-3.093-1.9-.836-.067-1.59.263-2.164.858C.802 4.108.528 6.283 2.04 7.812a245.96 245.96 0 0 0 4.775 4.7c.482.46.882.837 1.186 1.122.304-.285.704-.663 1.186-1.123a238.026 238.026 0 0 0 4.771-4.695 3.545 3.545 0 0 0 .057-4.963c-.572-.589-1.324-.915-2.161-.843-.843.072-1.926.562-3.098 1.911L8 4.791zm6.672 3.725C10.78 12.452 8 15 8 15s-2.78-2.548-6.672-6.485c-3.717-3.76 1.043-10.549 5.976-5.972.232.215.464.455.696.723.232-.267.464-.508.696-.723C13.63-2.04 18.39 4.68 14.672 8.515z"></path></svg>
                    }

                </div>
            }

            {/* new start */}

            {activeGamePopup === index && (
                <div ref={popupRef} className="game-popup-container">
                    {!isCollectionPopup &&
                        <ul>

                            {account?.account?.sub &&
                                <li onClick={() => {
                                    setIsCollectionPopup(true)
                                }}>
                                    Add to collection
                                </li>
                            }

                            {account?.account && isCollectionPage && game.canEditCollection &&
                                <li onClick={() => {
                                    setActiveGamePopup(false)
                                    removeFromCollection(game.id, fromCollection._id, "game")
                                }}>
                                    Remove from collection
                                </li>
                            }

                            <li onClick={() => {
                                setActiveGamePopup(-1)
                            }}>
                                Cancel
                            </li>

                        </ul>
                    }
                    {isCollectionPopup &&
                        <ul className='add-to-collection-popup'>
                            <li id="find-collection">
                                <input type="text" placeholder='Search collection...'
                                    value={inlineCollectionQuery}
                                    onChange={handleInlineInputChange}
                                />
                            </li>
                            {accountCollections.filter(collection => collection.collection_type === "game" && collection.collection_name.toLowerCase().includes(inlineCollectionQuery.toLowerCase())).slice(0, 3).map(collection => {
                                return (
                                    <li onClick={(e) => {
                                        e.preventDefault()
                                        setIsCollectionPopup(false)
                                        setActiveGamePopup(-1)
                                        addToCollection(game.id, collection._id, "game")
                                    }}>{collection.collection_name}</li>
                                )
                            })}
                            <li onClick={() => {
                                setActiveGamePopup(-1)
                            }}>
                                Cancel
                            </li>
                        </ul>
                    }
                </div>
            )}

            {/* new end  */}

        </div>

    );
}


export default GameWrapper;