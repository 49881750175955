import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import GamesWrapper from '../components/GamesWrapper';
import useFunctions from '../components/Functions';
import { setGameResults } from '../actions/games';

function HomePage() {

  const dispatch = useDispatch();
  const results = useSelector(state => state.gameResults.results)
  const filters = useSelector((state) => state.filters);
  const user = useSelector((state) => state.user);
  const gamesQueryPage = useSelector((state) => state.filters.gamesQueryPage);
  const [totalResultsCount, setTotalResultsCount] = useState(0);
  const { debounce } = useFunctions();

  const queryGames = () => {
    // console.log("Querying with filters: ", filters);
    fetch(`${process.env.REACT_APP_API_URL}/get-games`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ filters, page: gamesQueryPage })
    })
      .then(response => response.json())
      .then(data => {
        localStorage.setItem('cachedGameResults', JSON.stringify(data.games));
        dispatch(setGameResults(data.games))
        setTotalResultsCount(data.totalResultsCount)
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }


  useEffect(() => {
    if (user.user.logged !== undefined) {

      const delay = 250; // Adjust the delay as needed

      const debouncedQueryGames = debounce(queryGames, delay); // Adjust the delay as needed
      const timeoutId = setTimeout(() => {
        debouncedQueryGames();
      }, delay);

      // Cleanup: Clear the timeout on unmount or when filters/user.user.logged change
      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [
    user.user.logged,
    filters.searchTerm,
    filters.selectedGameGenres,
    filters.selectedGameThemes,
    filters.yearRange,
    filters.sortBy,
    filters.gameFilterModeIndex,
    filters.gamesQueryPage
  ]);

  return (
    <div>
      <GamesWrapper
        games={results}
        totalResultsCount={totalResultsCount}
        fromHomepage={true}
      />
    </div>
  );
}

export default HomePage;
