const initialState = {
    playlistData: {},           // Array of songs
    currentSongIndex: -1,  // Index of the currently playing song (-1 means no song is playing)
};

const playlistReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'ADD_TO_PLAYLIST':
            return {
                ...state,
                playlist: [...state.playlist, action.song],
            };
        case 'PLAY_NEXT_SONG':
            return {
                ...state,
                currentSongIndex: (state.currentSongIndex > -1 && state.playlistData.album_songs.length > (state.currentSongIndex + 1)) ? state.currentSongIndex + 1 : state.currentSongIndex,
                playlistData: {
                    ...state.playlistData,
                    playMode: 'indexChange',
                },
            };

        case 'PLAY_PREVIOUS_SONG':
            return {
                ...state,
                currentSongIndex: state.currentSongIndex > 0 ? state.currentSongIndex - 1 : state.currentSongIndex,
                playlistData: {
                    ...state.playlistData,
                    playMode: 'indexChange',
                },
            };
        case 'SET_PLAYLIST':
            return {
                ...state,
                playlistData: action.playlistSongs, // Replace the existing playlist with the new one
                currentSongIndex: action.currentSongIndex
            };
        default:
            return state;
    }
};

export default playlistReducer;
