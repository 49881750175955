
import React, { useState, useEffect, useRef } from 'react';
import FavoritesBanner from '../../components/FavoritesBanner';
import { useSelector } from 'react-redux';
import useFunctions from '../../components/Functions';
import CollectionsWrapper from '../../components/CollectionsWrapper';

function FavoriteCollectionsPage() {

  const { getCollectionsById } = useFunctions();
  const [collectionResults, setCollectionResults] = useState([]); // liked or owned
  const account = useSelector((state) => state.account);
  const [browseOwned, setBrowseOwned] = useState(false); // false: browsing liked
  const [totalResultsCount, setTotalResultsCount] = useState(0);
  const [collectionsQueryPage, setCollectionsQueryPage] = useState(1);


  const filterMyCollections = () => {
    getCollectionsById(account.account.contributions.addedCollectionIds, collectionsQueryPage).then(data => {
      setCollectionResults(data.collections.reverse())
      setTotalResultsCount(data.totalResultsCount)
    })
  }

  const filterLikedCollections = () => {
    getCollectionsById(account.account.liked_collection_ids, collectionsQueryPage).then(data => {
      if (data.collections.length > 0) {
        setCollectionResults(data.collections.reverse())
        setTotalResultsCount(data.totalResultsCount)
      }
    })
  }

  const queryMyCollections = () => {
    browseOwned ? filterMyCollections() : filterLikedCollections();
  }

  const delay = 130; // Adjust the delay as needed
  const timeoutIdRef = useRef(null);
  const debounce = (func) => {
    clearTimeout(timeoutIdRef.current);
    timeoutIdRef.current = setTimeout(() => {
      func();
    }, delay);
  };

  const queryMyCollectionsDebounced = () => {
    debounce(queryMyCollections);
  };

  useEffect(() => {
    if (!account.account.liked_collection_ids || !account.account.contributions.addedCollectionIds) return;

    queryMyCollectionsDebounced();

    return () => {
      clearTimeout(timeoutIdRef.current);
    };

  }, [account.account, collectionsQueryPage]);

  useEffect(() => {
    if (!account.account.liked_collection_ids || !account.account.contributions.addedCollectionIds) return;
    setCollectionsQueryPage(1)
    queryMyCollectionsDebounced();

    return () => {
      clearTimeout(timeoutIdRef.current);
    };
  }, [browseOwned]);

  return (
    <div className='favorites-content-container'>
      <FavoritesBanner />

      <CollectionsWrapper
        // fetchCollections={fetchCollections}
        collectionResults={collectionResults}
        fromFavorites={true}
        filterLikedCollections={filterLikedCollections}
        filterMyCollections={filterMyCollections}
        totalResultsCount={totalResultsCount}
        collectionsQueryPage={collectionsQueryPage}
        setCollectionsQueryPage={setCollectionsQueryPage}
        setBrowseOwned={setBrowseOwned}
        queryMyAlbums={queryMyCollections}
      />

    </div>
  );
}

export default FavoriteCollectionsPage;
