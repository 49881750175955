import React from 'react';
import ReactDOM from 'react-dom';
import AppRouter from './routes/AppRouter';
import VideoPlayer from './components/VideoPlayer';
import store from './store/configureStore'; // Import the Redux store
import { Provider } from 'react-redux';
import 'normalize.css/normalize.css'
import './styles/styles.scss';
import { VideoProvider } from './context/videoContext';


ReactDOM.render(
  <VideoProvider>
    <Provider store={store}>
      <VideoPlayer />
      <AppRouter />
    </Provider>
  </VideoProvider>,
  document.getElementById('root')
);