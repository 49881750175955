export const SET_VIDEO_ID = 'SET_VIDEO_ID';
export const TOGGLE_PLAYBACK = 'TOGGLE_PLAYBACK';

export function setVideoId(videoId) {
    return { type: SET_VIDEO_ID, videoId };
}

export function togglePlayback() {
    return { type: TOGGLE_PLAYBACK };
}
