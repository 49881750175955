import React, { useEffect, useState } from 'react';
import useFunctions from './Functions';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';


function SongsWrapper(props) {
    const { toggleQueue, setMultiEditBtnsEnabled, multiEditBtnsEnabled, fromGamePage, isQueue, songList,
        songs, gameName, album, toggleSongForm, toggleSongSelect, selectedSongIds,
        setSelectedSongIds, submitEditForm } = props;
    const { playSong, likeSong, isURL } = useFunctions();
    const [hiddenSongIndexes, setHiddenSongIndexes] = useState([]);
    const playback = useSelector((state) => state.playback);
    const [addSongPopupVisible, setAddSongPopupVisible] = useState(false);
    /* needed for add song to album */
    const [addSongName, setAddSongName] = useState("");
    const [addSongLink, setAddSongLink] = useState("");
    const [isValidSongForm, setIsValidSongForm] = useState(false);
    /* needed for add song to album end */
    const account = useSelector((state) => state.account);
    const user = useSelector((state) => state.user);
    const playingSongObject = useSelector(state => state.playback.playingSongObject)
    const [searchSongTitle, setSearchSongTitle] = useState('');
    const visibleIframe = useSelector(state => state.videoPlayer.visibleIframe)

    const [inputValue, setInputValue] = useState(''); // multi edit - remove from song title
    const [singleCorrectSongTitle, setSingleCorrectSongTitle] = useState(false); // single edit - correct song title
    const [singleCorrectYoutube, setSingleCorrectYoutube] = useState(false); // single edit - correct youtube video

    useEffect(() => {
        let isValid = false
        if (addSongName.trim() !== "") {
            if (isURL(addSongLink) && addSongLink.includes("youtube.com") && addSongLink.includes("v=")) {
                isValid = true
            }
        }
        setIsValidSongForm(isValid)
    }, [addSongName, addSongLink])

    const searchYoutubeVideo = () => {
        let url
        if (searchSongTitle !== "") {
            url = "https://www.youtube.com/results?search_query=" + gameName + " " + searchSongTitle + " soundtrack"
        } else {
            url = "https://www.youtube.com/results?search_query=" + gameName + " soundtrack"
        }
        window.open(url, "_blank");
    }

    const handleInputChange = (event) => {
        setSearchSongTitle(event.target.value);
        setAddSongName(event.target.value)
    }

    const handleYoutubeInputChange = (event) => {
        setAddSongLink(event.target.value)
    }

    const searchSongHandler = (e) => {
        let searchTerm = e.target.value.toLowerCase()
        let arr = [] // index of songs that DOES NOT match the search term
        songs.forEach((song, index) => {
            if (!song.song_title.toLowerCase().includes(searchTerm)) {
                arr.push(index)
            }
        })
        setHiddenSongIndexes(arr)
    }

    const addSongToAlbum = async (formData) => {
        if (formData.songName && formData.songYoutubeURL) {
            if (isURL(formData.songYoutubeURL) && formData.songYoutubeURL.includes("youtube") && formData.songYoutubeURL.includes("v=")) {
                const url = new URL(formData.songYoutubeURL);
                const urlParams = new URLSearchParams(url.search);
                const youtube_id = urlParams.get('v');
                const songInfo = { song_title: formData.songName, youtube_id }

                return new Promise((resolve, reject) => {

                    fetch(`${process.env.REACT_APP_API_URL}/add-song-to-album`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({ albumId: album._id, songInfo, sub: user.user.sub }) // formData = songName and youtube_id
                    })
                        .then(response => {
                            if (!response.ok) {
                                throw new Error('Network response was not ok');
                            }
                            return response.json();
                        })
                        .then(data => {
                            // console.log('Response data:', data);
                            toast.success('Song is added to album.', {
                                position: "bottom-center",
                                autoClose: 3500,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                            });

                            resolve(true)
                        })
                        .catch(error => {
                            console.error('Error:', error);
                        });
                })

            } else {
                toast.warn('Youtube URL is not valid.', {
                    position: "bottom-center",
                    autoClose: 3500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }
        } else {
            console.log("No URL provided.");
        }
    }

    const handleSongSubmit = (e) => {
        e.preventDefault()
        let songName = document.getElementById("songName").value
        let songYoutubeURL = document.getElementById("songYoutubeVideoURL").value
        let formData = { songName, songYoutubeURL };
        addSongToAlbum(formData)
        setAddSongPopupVisible(false)
    }

    const removeFromAlbum = async (albumId, songIds) => {

        let msg = "Delete the selected song from the album?"
        if (songIds.length > 1) {
            msg = "Delete the selected songs from the album?"
        }
        let answer = window.confirm(msg)
        if (!answer) return false

        return new Promise((resolve, reject) => {

            fetch(`${process.env.REACT_APP_API_URL}/remove-from-album`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ albumId, songIds, sub: user.user.sub })
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then(data => {
                    // console.log('Response data:', data);
                    toast.success('Song is removed from the album.', {
                        position: "bottom-center",
                        autoClose: 3500,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                    resolve(true)
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        })
    }

    useEffect(() => {
        if (selectedSongIds && selectedSongIds.length === 0) {
            setMultiEditBtnsEnabled(true)
            document.querySelectorAll(".song-edit-form-container--active").forEach(d => {
                d.classList.remove("song-edit-form-container--active")
            })

            // multi
            document.querySelectorAll(".song-edit-form-container--multi--active").forEach(d => {
                d.classList.remove("song-edit-form-container--multi--active")
            })

            document.querySelectorAll(".option--checkbox").forEach(d => {
                if (d.className.includes("option-blocked")) {
                    d.classList.remove("option-blocked")
                }
            })

        }
    }, [selectedSongIds])

    const cancelEditForm = () => {
        // setMultiEditBtnsEnabled(true)
        setSelectedSongIds([])
        document.querySelectorAll(".song-edit-form-container--active").forEach(d => {
            d.classList.remove("song-edit-form-container--active")
        })

        // multi
        document.querySelectorAll(".song-edit-form-container--multi--active").forEach(d => {
            d.classList.remove("song-edit-form-container--multi--active")
        })

        return true
    }

    /* live update song titles - multi */
    const handleRemoveFromSongsChangeMulti = (event) => {
        setInputValue(event.target.value);
    }

    /* live update song titles - single */
    const handleCorrectYoutubeChangeSingle = (event) => {
        setSingleCorrectYoutube(event.target.value);
    }

    const handleCorrectSongTitleChangeSingle = (event) => {
        setSingleCorrectSongTitle(event.target.value);
    }

    return (

        <div className={`songs-wrapper 
            ${isQueue && visibleIframe ? 'songs-wrapper--queue-visible' : ''}
            ${isQueue && !visibleIframe ? 'songs-wrapper--queue' : ''}`}
        >
            {songs.length === 0 &&
                <p style={{ "textAlign": "left" }}>No songs found.</p>
            }

            {addSongPopupVisible &&
                <form className='add-song-form popup-form' onSubmit={handleSongSubmit}>
                    <div className="form-group">
                        <label htmlFor="songName">Song Name: </label>
                        <input value={addSongName} onChange={handleInputChange} type="text" autoComplete="off" className="form-control" id="songName" placeholder="Enter the song name" required />
                    </div>
                    <div className="form-group">
                        <label htmlFor="email">Youtube video URL: <span className='span-shortcut' style={{ cursor: "pointer" }} onClick={searchYoutubeVideo}>[Search youtube

                            <svg id="new-tab" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52" enable-background="new 0 0 52 52" >
                                <g>
                                    <path d="M48.7,2H29.6C28.8,2,28,2.5,28,3.3v3C28,7.1,28.7,8,29.6,8h7.9c0.9,0,1.4,1,0.7,1.6l-17,17   c-0.6,0.6-0.6,1.5,0,2.1l2.1,2.1c0.6,0.6,1.5,0.6,2.1,0l17-17c0.6-0.6,1.6-0.2,1.6,0.7v7.9c0,0.8,0.8,1.7,1.6,1.7h2.9   c0.8,0,1.5-0.9,1.5-1.7v-19C50,2.5,49.5,2,48.7,2z" />
                                    <path d="M36.3,25.5L32.9,29c-0.6,0.6-0.9,1.3-0.9,2.1v11.4c0,0.8-0.7,1.5-1.5,1.5h-21C8.7,44,8,43.3,8,42.5v-21   C8,20.7,8.7,20,9.5,20H21c0.8,0,1.6-0.3,2.1-0.9l3.4-3.4c0.6-0.6,0.2-1.7-0.7-1.7H6c-2.2,0-4,1.8-4,4v28c0,2.2,1.8,4,4,4h28   c2.2,0,4-1.8,4-4V26.2C38,25.3,36.9,24.9,36.3,25.5z" />
                                </g>
                            </svg>

                            ]</span> </label>
                        <input value={addSongLink} onChange={handleYoutubeInputChange} type="text" className="form-control" autoComplete="off" id="songYoutubeVideoURL" placeholder="Enter youtube video URL" required />
                    </div>
                    <div className='common-form-btns'>
                        <button type="submit" className={`${isValidSongForm ? '' : 'option-blocked'}`} >Submit</button>
                        <button onClick={() => setAddSongPopupVisible(false)} >Cancel</button>
                    </div>
                </form>
            }

            {/* only in game page  */}
            {songs.length > 0 && account && account.account && account.account.username &&
                <div className="container-settings">

                    <input type="text" className="form-control" autoComplete="off" id="searchSong" placeholder="Search song..." onInput={(e) => searchSongHandler(e)} />

                    {selectedSongIds && album && album.canEdit &&
                        <div
                            className={`option option--edit ${!multiEditBtnsEnabled ? 'option-blocked' : ''}`}
                            onClick={() => {
                                toggleSongForm(selectedSongIds)
                            }}>
                            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M21.2799 6.40005L11.7399 15.94C10.7899 16.89 7.96987 17.33 7.33987 16.7C6.70987 16.07 7.13987 13.25 8.08987 12.3L17.6399 2.75002C17.8754 2.49308 18.1605 2.28654 18.4781 2.14284C18.7956 1.99914 19.139 1.92124 19.4875 1.9139C19.8359 1.90657 20.1823 1.96991 20.5056 2.10012C20.8289 2.23033 21.1225 2.42473 21.3686 2.67153C21.6147 2.91833 21.8083 3.21243 21.9376 3.53609C22.0669 3.85976 22.1294 4.20626 22.1211 4.55471C22.1128 4.90316 22.0339 5.24635 21.8894 5.5635C21.7448 5.88065 21.5375 6.16524 21.2799 6.40005V6.40005Z" stroke="#000000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path> <path d="M11 4H6C4.93913 4 3.92178 4.42142 3.17163 5.17157C2.42149 5.92172 2 6.93913 2 8V18C2 19.0609 2.42149 20.0783 3.17163 20.8284C3.92178 21.5786 4.93913 22 6 22H17C19.21 22 20 20.2 20 18V13" stroke="#000000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path> </g></svg>
                        </div>
                    }
                    {/* {selectedSongIds &&
                        <div
                            className={`option ${!multiEditBtnsEnabled ? 'option-blocked' : ''}`}
                        >
                            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path> <path d="M19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12C18 12.5523 18.4477 13 19 13Z" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path> <path d="M5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13Z" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path> </g></svg>
                        </div>
                    } */}

                    {/* removed selectedSongIds */}
                    {album && album.canEdit &&
                        <div
                            className={`option option--checkbox ${!multiEditBtnsEnabled ? 'option-blocked' : ''}`}
                            toggle-mode="enable" onClick={() => {
                                const resultArray = songs.map((e, i) => e.uuid);
                                toggleSongSelect(resultArray) // passing array of all indexes instead of one integer index this time.
                            }}>
                            <svg className="svg--not-checked" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="#ddd"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <rect width="20" height="20" x="2" y="2" fill="none" stroke="#aaa" strokeWidth="1"></rect> </g></svg>
                            <svg fill="#ccc" id="svg--checked" className="svg--checked" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M400,48H112a64.07,64.07,0,0,0-64,64V400a64.07,64.07,0,0,0,64,64H400a64.07,64.07,0,0,0,64-64V112A64.07,64.07,0,0,0,400,48ZM364.25,186.29l-134.4,160a16,16,0,0,1-12,5.71h-.27a16,16,0,0,1-11.89-5.3l-57.6-64a16,16,0,1,1,23.78-21.4l45.29,50.32L339.75,165.71a16,16,0,0,1,24.5,20.58Z" /></svg>
                        </div>
                    }

                </div>
            }

            {/* multi form  */}
            {album && selectedSongIds && submitEditForm &&
                <div className={"song-edit-form-container song-edit-form-container--multi"} >
                    <div className="applied-songs-info">
                        <div className="info-header">Your changes will be applied to following songs:</div>
                        <ul>
                            {songs.filter(e => selectedSongIds.includes(e.uuid)).map((song, index) => {
                                return (
                                    <div>
                                        <li className='song-old-name' key={index}>{song.song_title}
                                            {inputValue.length > 0 && <span> {"->"} </span>}
                                            {inputValue.length > 0 && <div className='song-new-name' >{song.song_title.replace(inputValue, '')}</div>}
                                        </li>
                                    </div>
                                )
                            })}
                        </ul>
                    </div>

                    {selectedSongIds.length === 1 &&
                        <input type="text" autoComplete="off" className="song-edit-input song-edit-input--name" placeholder="Correct song title: " />
                    }
                    <input onChange={handleRemoveFromSongsChangeMulti} type="text" className="song-edit-input song-edit-input--remove-string" placeholder="Remove from song title: " />
                    {selectedSongIds.length === 1 &&
                        <input type="text" autoComplete="off" className="song-edit-input song-edit-input--youtube" placeholder="Correct youtube video: " />
                    }
                    <div className="edit-btns">
                        <button className="remove-from-album" onClick={() => removeFromAlbum(album._id, selectedSongIds)}>
                            <svg fill="#fff" xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 -0.5 21 21" version="1.1">
                                <g id="Page-1" stroke="none" stroke-width="1" fill="#fff" fill-rule="evenodd">
                                    <g id="Dribbble-Light-Preview" transform="translate(-179.000000, -360.000000)" fill="#222">
                                        <g id="icons" transform="translate(56.000000, 160.000000)">
                                            <path d="M130.35,216 L132.45,216 L132.45,208 L130.35,208 L130.35,216 Z M134.55,216 L136.65,216 L136.65,208 L134.55,208 L134.55,216 Z M128.25,218 L138.75,218 L138.75,206 L128.25,206 L128.25,218 Z M130.35,204 L136.65,204 L136.65,202 L130.35,202 L130.35,204 Z M138.75,204 L138.75,200 L128.25,200 L128.25,204 L123,204 L123,206 L126.15,206 L126.15,220 L140.85,220 L140.85,206 L144,206 L144,204 L138.75,204 Z" >
                                            </path>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                            <span>Remove from album</span>
                        </button>
                        <button onClick={() => cancelEditForm()}>Cancel</button>
                        <button onClick={() => submitEditForm(-1)} disabled={inputValue.length === 0}>Submit changes</button>
                    </div>
                </div>
            }
            {/* multi form end  */}

            {isQueue && <div className='queue-toggle-btn' onClick={toggleQueue}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
                    <path d="M7 10L12 15L17 10" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>

            </div>}

            <div className='songs-container'>
                {songs.map((vidObject, index) => {
                    return (
                        <div key={index} className={(hiddenSongIndexes.includes(index) ? "hidden" : "")}>
                            <div className='song-container' key={index}>
                                <div className='song-img-container'>
                                    <div className="svg-container" onClick={() => {
                                        playSong({
                                            vidObject,
                                            gameName,
                                            albumData: album,
                                            songIndex: index,
                                            songList,
                                            togglePause: playingSongObject && playingSongObject.youtube_id === vidObject.youtube_id,
                                            playMode: "singlePlay"
                                        })
                                    }}>

                                        {playingSongObject && playingSongObject.youtube_id === vidObject.youtube_id && !playback.isPaused &&
                                            <div className="playing-gif-container">
                                                <img className="playing-gif" src="https://e-cdn-files.dzcdn.net/cache/slash/images/common/animated/equalizer/12x12/dark.ee54bef1c488b8caebde.gif"></img>
                                            </div>
                                        }

                                        {playingSongObject && playingSongObject.youtube_id !== vidObject.youtube_id &&
                                            <div className="svg-content">
                                                <svg className="svg-icon svg-icon-play" viewBox="0 0 12 12"><path fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="M2.5.5v11l9-5.5z"></path></svg>
                                            </div>
                                        }
                                        {playingSongObject && playingSongObject.youtube_id === vidObject.youtube_id && !playback.isPaused &&
                                            <div className="svg-content">
                                                <svg viewBox="0 0 16 16" focusable="false" className="chakra-icon css-c1x3e4" data-testid="PauseIcon"><path d="M6 0H2v16h4V0zm8 0h-4v16h4V0z"></path></svg>
                                            </div>
                                        }
                                        {playingSongObject && playingSongObject.youtube_id === vidObject.youtube_id && playback.isPaused &&
                                            <div className="svg-content no-hide">
                                                <svg className="svg-icon svg-icon-play" viewBox="0 0 12 12"><path fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="M2.5.5v11l9-5.5z"></path></svg>
                                            </div>
                                        }
                                        {!playingSongObject &&
                                            <div className="svg-content">
                                                <svg className="svg-icon svg-icon-play" viewBox="0 0 12 12"><path fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="M2.5.5v11l9-5.5z"></path></svg>
                                            </div>
                                        }

                                    </div>

                                    <img alt="album cover" src={process.env.REACT_APP_ALBUM_COVERS_FOLDER + "/" + vidObject.fromAlbum + "-" + vidObject.fromVersionId + "_64x.jpg"} />

                                </div>

                                <div className='song-title-container'>
                                    <a className={"song-title " + (playingSongObject && vidObject.youtube_id === playingSongObject.youtube_id ? "song-title--active" : "")} href={"https://www.youtube.com/watch?v=" + vidObject.youtube_id} rel="noreferrer" target="_blank" >
                                        {index + 1}. {vidObject.song_title}
                                    </a>
                                </div>
                                {account && account.account && account.account.username &&
                                    <div className='song-options'>
                                        {account && account.account && account.account.liked_songs &&
                                            <div className='option option--like' onClick={() => {
                                                !isQueue && likeSong(vidObject, album)
                                                vidObject.album_cover_url && likeSong(vidObject) // tum data zaten vidObjede bulunmali
                                                isQueue && !vidObject.album_cover_url && likeSong(vidObject, album)
                                            }}>
                                                {account.account.liked_songs.find(e => e.youtube_id === vidObject.youtube_id) &&
                                                    <svg id="liked-song-svg" viewBox="0 0 16 16" focusable="false" className="chakra-icon css-1qsky21" data-testid="HeartFillIcon" aria-hidden="true"><path d="M8 3.266C2.837-2.68-2.564 4.578 1.328 8.516 5.22 12.451 8 15 8 15s2.78-2.548 6.672-6.485C18.564 4.501 13.162-2.679 8 3.265z"></path></svg>
                                                }
                                                {!account.account.liked_songs.find(e => e.youtube_id === vidObject.youtube_id) &&
                                                    <svg viewBox="0 0 16 16" focusable="false" className="chakra-icon css-1yk3h4a" data-testid="HeartIcon" aria-hidden="true"><path d="m8 4.79-.755-.869c-1.17-1.348-2.252-1.832-3.093-1.9-.836-.067-1.59.263-2.164.858C.802 4.108.528 6.283 2.04 7.812a245.96 245.96 0 0 0 4.775 4.7c.482.46.882.837 1.186 1.122.304-.285.704-.663 1.186-1.123a238.026 238.026 0 0 0 4.771-4.695 3.545 3.545 0 0 0 .057-4.963c-.572-.589-1.324-.915-2.161-.843-.843.072-1.926.562-3.098 1.911L8 4.791zm6.672 3.725C10.78 12.452 8 15 8 15s-2.78-2.548-6.672-6.485c-3.717-3.76 1.043-10.549 5.976-5.972.232.215.464.455.696.723.232-.267.464-.508.696-.723C13.63-2.04 18.39 4.68 14.672 8.515z"></path><path d="m8 4.79-.755-.869c-1.17-1.348-2.252-1.832-3.093-1.9-.836-.067-1.59.263-2.164.858C.802 4.108.528 6.283 2.04 7.812a245.96 245.96 0 0 0 4.775 4.7c.482.46.882.837 1.186 1.122.304-.285.704-.663 1.186-1.123a238.026 238.026 0 0 0 4.771-4.695 3.545 3.545 0 0 0 .057-4.963c-.572-.589-1.324-.915-2.161-.843-.843.072-1.926.562-3.098 1.911L8 4.791zm6.672 3.725C10.78 12.452 8 15 8 15s-2.78-2.548-6.672-6.485c-3.717-3.76 1.043-10.549 5.976-5.972.232.215.464.455.696.723.232-.267.464-.508.696-.723C13.63-2.04 18.39 4.68 14.672 8.515z"></path></svg>
                                                }
                                            </div>
                                        }

                                        {/* selectedSongIds doesnt exist any other page than gamePage. for example liked tracks page */}
                                        {selectedSongIds && album && album.canEdit &&
                                            <div className={'option option--edit ' + (selectedSongIds.length > 0 ? "option-blocked" : "")} onClick={() => {
                                                toggleSongSelect(vidObject.uuid, true)
                                                toggleSongForm(vidObject.uuid)
                                            }}>

                                                <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M21.2799 6.40005L11.7399 15.94C10.7899 16.89 7.96987 17.33 7.33987 16.7C6.70987 16.07 7.13987 13.25 8.08987 12.3L17.6399 2.75002C17.8754 2.49308 18.1605 2.28654 18.4781 2.14284C18.7956 1.99914 19.139 1.92124 19.4875 1.9139C19.8359 1.90657 20.1823 1.96991 20.5056 2.10012C20.8289 2.23033 21.1225 2.42473 21.3686 2.67153C21.6147 2.91833 21.8083 3.21243 21.9376 3.53609C22.0669 3.85976 22.1294 4.20626 22.1211 4.55471C22.1128 4.90316 22.0339 5.24635 21.8894 5.5635C21.7448 5.88065 21.5375 6.16524 21.2799 6.40005V6.40005Z" stroke="#000000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path> <path d="M11 4H6C4.93913 4 3.92178 4.42142 3.17163 5.17157C2.42149 5.92172 2 6.93913 2 8V18C2 19.0609 2.42149 20.0783 3.17163 20.8284C3.92178 21.5786 4.93913 22 6 22H17C19.21 22 20 20.2 20 18V13" stroke="#000000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path> </g></svg>
                                            </div>
                                        }

                                        {/* <div className='option option--more-settings'>
                                            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12C11 12.5523 11.4477 13 12 13Z" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path> <path d="M19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12C18 12.5523 18.4477 13 19 13Z" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path> <path d="M5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12C4 12.5523 4.44772 13 5 13Z" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path> </g></svg>
                                        </div> */}

                                        {selectedSongIds && album && album.canEdit &&
                                            <div className='option option--checkbox' onClick={() => {
                                                toggleSongSelect(vidObject.uuid)
                                            }}>
                                                {!selectedSongIds.includes(vidObject.uuid) && <svg className="svg--not-checked" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="#ddd"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <rect width="20" height="20" x="2" y="2" fill="none" stroke="#aaa" strokeWidth="1"></rect> </g></svg>}
                                                {selectedSongIds.includes(vidObject.uuid) && <svg id="svg--checked" className="svg--checked" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M400,48H112a64.07,64.07,0,0,0-64,64V400a64.07,64.07,0,0,0,64,64H400a64.07,64.07,0,0,0,64-64V112A64.07,64.07,0,0,0,400,48ZM364.25,186.29l-134.4,160a16,16,0,0,1-12,5.71h-.27a16,16,0,0,1-11.89-5.3l-57.6-64a16,16,0,1,1,23.78-21.4l45.29,50.32L339.75,165.71a16,16,0,0,1,24.5,20.58Z" /></svg>}
                                            </div>
                                        }

                                    </div>
                                }
                            </div>

                            {album && selectedSongIds && submitEditForm &&
                                <div className={"song-edit-form-container song-edit-form-container--single song-edit-form-container--" + vidObject.uuid} >
                                    <div className="applied-songs-info">
                                        <div className="info-header">Your changes will be applied to the following song. You can update the song title and/or its YouTube link. If you don't wish to change a specific field, leave it blank.</div>
                                        <ul>
                                            {songs.filter(e => selectedSongIds.includes(e.uuid)).map((song, index) => {
                                                return (
                                                    <li key={index}>{song.song_title}</li>
                                                )
                                            })}
                                        </ul>
                                    </div>

                                    {selectedSongIds.length === 1 &&
                                        <input onChange={handleCorrectSongTitleChangeSingle} type="text" autoComplete="off" className="song-edit-input song-edit-input--name" placeholder="Correct song title: " />
                                    }

                                    {/* removed  */}
                                    <input type="text" className="hidden song-edit-input song-edit-input--remove-string" placeholder="Remove from song title: " />

                                    {selectedSongIds.length === 1 &&
                                        <input onChange={handleCorrectYoutubeChangeSingle} type="text" autoComplete="off" className="song-edit-input song-edit-input--youtube" placeholder="Correct youtube video: " />
                                    }
                                    <div className="edit-btns">
                                        <button className="remove-from-album" onClick={() => removeFromAlbum(album._id, [vidObject.uuid])}>
                                            <svg fill="#fff" xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 -0.5 21 21" version="1.1">

                                                <g id="Page-1" stroke="none" stroke-width="1" fill="#fff" fill-rule="evenodd">
                                                    <g id="Dribbble-Light-Preview" transform="translate(-179.000000, -360.000000)" fill="#222">
                                                        <g id="icons" transform="translate(56.000000, 160.000000)">
                                                            <path d="M130.35,216 L132.45,216 L132.45,208 L130.35,208 L130.35,216 Z M134.55,216 L136.65,216 L136.65,208 L134.55,208 L134.55,216 Z M128.25,218 L138.75,218 L138.75,206 L128.25,206 L128.25,218 Z M130.35,204 L136.65,204 L136.65,202 L130.35,202 L130.35,204 Z M138.75,204 L138.75,200 L128.25,200 L128.25,204 L123,204 L123,206 L126.15,206 L126.15,220 L140.85,220 L140.85,206 L144,206 L144,204 L138.75,204 Z" >

                                                            </path>
                                                        </g>
                                                    </g>
                                                </g>
                                            </svg>
                                            <span>Remove from album</span>
                                        </button>
                                        <button onClick={() => cancelEditForm()}>Cancel</button>
                                        <button
                                            disabled={!singleCorrectSongTitle && !singleCorrectYoutube}
                                            onClick={() => submitEditForm(vidObject.uuid)} >Submit changes</button>
                                    </div>
                                </div>
                            }
                        </div>
                    )
                })}

                {/* add song - only available if played from gamePage */}
                {album && !isQueue && fromGamePage &&
                    account && account.account && album && album.canEdit &&

                    <div className="add-song-empty-container" onClick={() => setAddSongPopupVisible(true)} >
                        <div className='song-container song-container--empty'>
                            <div className='song-img-container'>
                                <div className="svg-container"></div>
                                {album && <img alt="" src='' />}
                            </div>

                            <div className='song-title-container'>
                                <div className="song-title" >
                                    + Add another song to this album
                                </div>
                            </div>
                            <div className='song-options'>  </div>
                        </div>
                    </div>
                }

            </div>


        </div>
    );
}

export default SongsWrapper;