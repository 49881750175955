import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    setSelectedGameGenres, setSelectedGameThemes, setGamesFilterMode,
    setYearRange, setSearchTerm, setHighlightMissing, setGamesSortBy, setGamesQueryPage
} from '../actions/filters';
import MultiRangeSlider from "multi-range-slider-react";
import Switch from 'react-switch';
import Select from 'react-select';

function GameFilters(props) {

    const dispatch = useDispatch();
    const [viewFilters, setViewFilters] = useState(true)
    const searchTerm = useSelector((state) => state.filters.searchTerm);
    const gameFilterModeIndex = useSelector((state) => state.filters.gameFilterModeIndex);
    const selectedGameGenres = useSelector((state) => state.filters.selectedGameGenres);
    const selectedGameThemes = useSelector((state) => state.filters.selectedGameThemes);
    const yearRange = useSelector((state) => state.filters.yearRange);
    const highlightMissing = useSelector((state) => state.filters.highlightMissing);
    const gamesQueryPage = useSelector((state) => state.filters.gamesQueryPage);

    const sortGamesBy = useSelector((state) => state.filters.sortBy);
    const sortOptions = [
        { value: "total_rating_count", label: "Popularity" },
        { value: "first_release_date", label: "Year" },
        { value: "name", label: "Name" },
    ]
    const [minValue, set_minValue] = useState(1970);
    const [maxValue, set_maxValue] = useState(2024);
    const [isHideEmptyChecked, setIsHideEmptyChecked] = useState(false);
    const [isShowEmptyOnlyChecked, setIsShowEmptyOnlyChecked] = useState(false);
    const [isHighlightMissingChecked, setIsHighlightMissingChecked] = useState(false);


    const handleHideEmptyToggle = (checked) => {
        setIsHideEmptyChecked(checked);
        if (checked && isShowEmptyOnlyChecked) setIsShowEmptyOnlyChecked(false)
    };

    const handleShowEmptyOnlyToggle = (checked) => {
        setIsShowEmptyOnlyChecked(checked);
        if (checked && isHideEmptyChecked) setIsHideEmptyChecked(false)
    };

    const handleHighlightMissingToggle = (checked) => {
        setIsHighlightMissingChecked(checked);
    }

    useEffect(() => {
        if (minValue !== yearRange.minYear || maxValue !== yearRange.maxYear) {
            dispatch(setYearRange(
                minValue,
                maxValue
            ))
            dispatch(setGamesQueryPage(1))
        }
    }, [minValue, maxValue])

    useEffect(() => {
        if (isHideEmptyChecked === false && isShowEmptyOnlyChecked === false) {
            if (gameFilterModeIndex !== undefined) {
                dispatch(setGamesFilterMode(undefined))
                dispatch(setGamesQueryPage(1))
            }
        } else if (isHideEmptyChecked && !isShowEmptyOnlyChecked) {
            if (gameFilterModeIndex !== 0) {
                dispatch(setGamesFilterMode(0))
                dispatch(setGamesQueryPage(1))
            }
        } else if (!isHideEmptyChecked && isShowEmptyOnlyChecked) {
            if (gameFilterModeIndex !== 1) {
                dispatch(setGamesFilterMode(1))
                dispatch(setGamesQueryPage(1))
            }
        }
    }, [isHideEmptyChecked, isShowEmptyOnlyChecked])

    useEffect(() => {
        if (highlightMissing !== isHighlightMissingChecked) dispatch(setHighlightMissing(isHighlightMissingChecked))
    }, [isHighlightMissingChecked])

    // year range
    const handleInput = (e) => {
        set_minValue(e.minValue);
        set_maxValue(e.maxValue);
    };

    const [genres, setAllGenres] = useState([
        { id: 4, name: 'Fighting', type: 'genre' },
        { id: 5, name: 'Shooter', type: 'genre' },
        { id: 7, name: 'Music', type: 'genre' },
        { id: 8, name: 'Platform', type: 'genre' },
        { id: 9, name: 'Puzzle', type: 'genre' },
        { id: 10, name: 'Racing', type: 'genre' },
        { id: 11, name: 'Real Time Strategy', type: 'genre' },
        { id: 12, name: 'Role-playing (RPG)', type: 'genre' },
        { id: 13, name: 'Simulator', type: 'genre' },
        { id: 14, name: 'Sport', type: 'genre' },
    ])

    const [themes, setAllThemes] = useState([
        { id: 20, name: 'Thriller', type: 'theme' },
        { id: 18, name: 'Science fiction', type: 'theme' },
        { id: 1, name: 'Action', type: 'theme' },
        { id: 19, name: 'Horror', type: 'theme' },
        { id: 21, name: 'Survival', type: 'theme' },
        { id: 17, name: 'Fantasy', type: 'theme' },
        { id: 22, name: 'Historical', type: 'theme' },
        { id: 23, name: 'Stealth', type: 'theme' },
        { id: 27, name: 'Comedy', type: 'theme' },
        { id: 28, name: 'Business', type: 'theme' }
    ])

    const [combinedTags, setCombinedTags] = useState(genres.concat(themes))

    const handleGenreCheckboxChange = (selectedGenres) => {
        // Do something with the selected genres (e.g., update state in your component)
        // console.log('Selected Genres:', selectedGenres);
        dispatch(setSelectedGameGenres(selectedGenres));
        dispatch(setGamesQueryPage(1))
    };

    const handleThemeCheckboxChange = (selectedThemes) => {
        // Do something with the selected genres (e.g., update state in your component)
        console.log('Selected Themes:', selectedThemes);
        dispatch(setSelectedGameThemes(selectedThemes));
        dispatch(setGamesQueryPage(1))
    };

    const handleSortChange = (event) => {
        const selectedValue = event.value;
        dispatch(setGamesSortBy(selectedValue))
        dispatch(setGamesQueryPage(1))
    };

    const handleGenreCheckboxClick = (id) => {
        const updatedGenres = selectedGameGenres.includes(id)
            ? selectedGameGenres.filter((genreId) => genreId !== id)
            : [...selectedGameGenres, id];

        // setSelectedGenres(updatedGenres);
        handleGenreCheckboxChange(updatedGenres);
    };

    const handleThemeCheckboxClick = (id) => {
        const updatedThemes = selectedGameThemes.includes(id)
            ? selectedGameThemes.filter((themeId) => themeId !== id)
            : [...selectedGameThemes, id];

        // setSelectedGenres(updatedGenres);
        handleThemeCheckboxChange(updatedThemes);
    };


    return (

        <div className='game-filters-wrapper'>
            <div className='common-form-btns'>
                <button onClick={() => {
                    setViewFilters(!viewFilters)
                }}>Toggle Filters</button>

                <button onClick={() => {
                    minValue !== 1970 && set_minValue(1970)
                    maxValue !== 2024 && set_maxValue(2024)
                    isHideEmptyChecked && setIsHideEmptyChecked(false) //
                    isShowEmptyOnlyChecked && setIsShowEmptyOnlyChecked(false) //
                    isHighlightMissingChecked && setIsHighlightMissingChecked(false)
                    selectedGameGenres.length > 0 && dispatch(setSelectedGameGenres([]))
                    selectedGameThemes.length > 0 && dispatch(setSelectedGameThemes([]))
                    searchTerm !== "" && dispatch(setSearchTerm(""))
                    sortGamesBy !== "total_rating_count" && dispatch(setGamesSortBy("total_rating_count"))
                    gamesQueryPage !== 1 && dispatch(setGamesQueryPage(1))
                }}>Reset filters</button>
            </div>

            {viewFilters &&
                <div className='filters'>

                    {/* all combined  */}
                    <div className='game-filters-group game-filters-group--genresThemes'>
                        <h2>Genres & Themes</h2>
                        <div className='game-filters-container game-filters-container--genresThemes'>

                            {combinedTags
                                .sort((a, b) => a.name > b.name ? 1 : -1)
                                .map((tag) => (
                                    <div key={tag.id}>
                                        {tag.type === 'genre' && (
                                            <div key={tag.id}>
                                                <input
                                                    type="checkbox"
                                                    id={`genre-${tag.id}`}
                                                    value={tag.id}
                                                    checked={selectedGameGenres.includes(tag.id)}
                                                    onChange={() => handleGenreCheckboxClick(tag.id)}
                                                />
                                                <div className='label-container'><label htmlFor={`genre-${tag.id}`}>{tag.name}</label></div>
                                            </div>
                                        )}
                                        {tag.type === 'theme' && (
                                            <div key={tag.id}>
                                                <input
                                                    type="checkbox"
                                                    id={`theme-${tag.id}`}
                                                    value={tag.id}
                                                    checked={selectedGameThemes.includes(tag.id)}
                                                    onChange={() => handleThemeCheckboxClick(tag.id)}
                                                />
                                                <div className='label-container'><label htmlFor={`theme-${tag.id}`}>{tag.name}</label></div>
                                            </div>
                                        )}
                                    </div>
                                ))}
                        </div>
                    </div>


                    <div className='game-filters-group game-filters-group--years'>
                        <h2>Release date</h2>
                        <MultiRangeSlider
                            min={1970}
                            max={2024}
                            step={1}
                            minValue={minValue}
                            maxValue={maxValue}
                            ruler={false}
                            canMinMaxValueSame={true}
                            onChange={(e) => {
                                handleInput(e);
                            }}
                        />
                    </div>

                    <div className='switches-container'>
                        <div className='toggle-container'>
                            <Switch
                                checked={isHideEmptyChecked}
                                onChange={handleHideEmptyToggle}
                                // onColor="#86d3ff"
                                onColor="#FF8E9B"
                                // onHandleColor="#2693e6"
                                onHandleColor="#EF5466"
                                handleDiameter={25} /* 25 */
                                uncheckedIcon={false}
                                checkedIcon={false}
                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                activeBoxShadow="0px 0px 1px 4px rgba(0, 0, 0, 0.2)"
                                height={17}
                                width={42}
                                className="react-switch"
                            />
                            <span>Hide empty</span>
                        </div>
                        <div className='toggle-container'>
                            <Switch
                                checked={isShowEmptyOnlyChecked}
                                onChange={handleShowEmptyOnlyToggle}
                                // onColor="#86d3ff"
                                onColor="#FF8E9B"
                                // onHandleColor="#2693e6"
                                onHandleColor="#EF5466"
                                handleDiameter={25}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                activeBoxShadow="0px 0px 1px 4px rgba(0, 0, 0, 0.2)"
                                height={17}
                                width={42}
                                className="react-switch"
                            />
                            <span>Show empty only</span>
                        </div>
                        <div className='toggle-container'>
                            <Switch
                                checked={isHighlightMissingChecked}
                                onChange={handleHighlightMissingToggle}
                                // onColor="#86d3ff"
                                onColor="#FF8E9B"
                                // onHandleColor="#2693e6"
                                onHandleColor="#EF5466"
                                handleDiameter={25}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                activeBoxShadow="0px 0px 1px 4px rgba(0, 0, 0, 0.2)"
                                height={17}
                                width={42}
                                className="react-switch"
                            />
                            <span>Highlight missing</span>
                        </div>
                    </div>

                    <div className='game-filters-group game-filters-group--sortBy'>
                        <h2>Sort results</h2>
                        <div className='game-filters-container game-filters-container--sortBy'>

                            {/* <select id="sortBy" value={sortGamesBy} onChange={handleSortChange}>
                        <option value="total_rating_count">Popularity</option>
                        <option value="first_release_date">Year</option>
                        <option value="name">Name</option>
                    </select> */}

                            <Select
                                placeholder="Popularity"
                                isSearchable={false}
                                value={
                                    sortOptions.find(e => e.value === sortGamesBy)
                                }
                                onChange={(e) => handleSortChange(e)}
                                options={sortOptions} />

                        </div>
                    </div>
                </div>
            }
        </div>

    );
}


export default GameFilters;