import React, { useState, useEffect, useRef } from 'react';
import GameBanner from '../components/GameBanner';
import AlbumsWrapper from '../components/AlbumsWrapper';
import useFunctions from '../components/Functions';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import { toast } from 'react-toastify';

function GamePage() {

    const [game, setGame] = useState();
    const [gameAlbums, setGameAlbums] = useState([]);
    const [addAlbumPopupVisible, setAddAlbumPopupVisible] = useState(false)
    const { game_id } = useParams();

    const [imageToAdd, setImageToAdd] = useState(null);
    const account = useSelector((state) => state.account);
    const user = useSelector((state) => state.user);
    const imgRef = useRef(null);
    const [addAlbumStep, setAddAlbumStep] = useState(1);
    const [addAlbumName, setAddAlbumName] = useState("")
    const [addAlbumPlaylistURL, setAddAlbumPlaylistURL] = useState("")
    const [addAlbumDescription, setAddAlbumDescription] = useState("")
    const [albumQueryPage, setAlbumQueryPage] = useState(1);
    const [totalResultsCount, setTotalResultsCount] = useState(0);
    const { isURL } = useFunctions();
    const fileInputRef = useRef(null);


    async function fetchPlaylistVideos(playlistId) {

        try {
            const url = new URL(`${process.env.REACT_APP_API_URL}/getPlaylistVideos`);
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    sub: user.user.sub,
                    playlistId
                }),
            });

            if (!response.ok) {
                throw new Error(`Failed to fetch playlist videos. Status: ${response.status}`);
            }

            const result = await response.json();

            if (result.success) {
                return result.playlistVideos;
            } else {
                throw new Error(result.error || 'Failed to fetch playlist videos');
            }
        } catch (error) {
            console.error('Error:', error);
            throw error;
        }

    }

    const saveAlbum = async (formData) => {
        return new Promise(async (resolve, reject) => {
            try {
                // const albumFormData = new FormData();
                let requiredAccData = { username: account.account.username, public_user_id: account.account.public_user_id, email: user.user.email, sub: user.user.sub }
                formData.append("game_name", game.name);
                formData.append("release_date", game.year);
                formData.append("game_id", game_id);
                formData.append("game_slug", game.slug);
                formData.append("date_added", Date.now());
                formData.append("added_by_account", JSON.stringify(requiredAccData));
                formData.append("isUniqueGame", gameAlbums.length === 0)

                //console.log('albumFormData.album_cover_image is coming: ', formData.get('album_cover_image'));

                if (gameAlbums.find(a => a.album_playlist_id === formData.get("album_playlist_id"))) {
                    let answer = window.confirm("It seems this YouTube playlist is already linked to another album. Please only proceed if you are committed to resolving any existing issues in that album.")
                    if (!answer) return false
                }

                fetch(`${process.env.REACT_APP_API_URL}/add-album`, {
                    method: 'POST',
                    body: formData
                })
                    .then(response => {
                        if (!response.ok) {
                            throw new Error('Network response was not ok');
                        }
                        return response.json();
                    })
                    .then(data => {

                        // let tmpAcc = account.account
                        // dispatch(setAccount(tmpAcc)) NOT SURE.

                        refreshGameAlbums() // when a new album is added or song is edited, users wont have to reload.

                        toast.success('Album is added', {
                            position: "bottom-center",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });

                        resetForm()
                        resolve(true)
                    })
                    .catch(error => {
                        toast.error('Error adding album.', {
                            position: "bottom-center",
                            autoClose: 3500,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                        });
                        console.log('error ::', error);
                        console.error('Error:', error);
                    });

            } catch (e) {
                console.log(e);
            }
        });
    };

    const searchYoutube = () => {
        let url = "https://www.youtube.com/results?search_query=" + game.name + " soundtrack&sp=EgIQAw%253D%253D"
        window.open(url, "_blank");
    }

    const searchAlbumName = () => {
        let url = "https://www.google.com/search?q=" + game.name + " soundtrack"
        window.open(url, "_blank");
    }

    const autoFillAlbumName = () => {
        setAddAlbumName(game.name + " Soundtrack")
    }

    const searchCover = () => {
        if (addAlbumName.trim().length === 0) return false
        let url = "https://www.google.com/search?q=" + addAlbumName + " album cover&tbm=isch"
        window.open(url, "_blank");
    }

    const handleAlbumSubmit = async (e) => {
        e.preventDefault();

        const album_name = addAlbumName
        const albumYoutubePlaylistURL = document.getElementById("albumYoutubePlaylistURL").value;

        if (imageToAdd) {
            const formData = new FormData();
            formData.append("album_name", album_name);

            const url = new URL(albumYoutubePlaylistURL);
            const urlParams = new URLSearchParams(url.search);
            const playlistID = urlParams.get('list');
            let playlistVideos = await fetchPlaylistVideos(playlistID);

            formData.append("album_songs", JSON.stringify(playlistVideos))
            formData.append("album_playlist_id", playlistID);
            formData.append("album_description", addAlbumDescription)

            // Append the image file
            if (imageToAdd) {
                formData.append("album_cover_image", imageToAdd);
            } // fff

            saveAlbum(formData)

            /* no need to wait for fetch response for these */
            setAddAlbumPopupVisible(false)
            setAddAlbumStep(1)

        } else {
            // Handle case where no image is selected
            console.warn("No image selected");
        }
    };

    // after editing a song, users won't have to reload the page.
    const refreshGameAlbums = (chosenGameAlbumId) => {
        // chosenGameAlbumId is optional. it will be only passed if song is edited.
        // it won't be needed if it's just called when adding a new album

        // local query --> get ALL albums for this game.
        fetch(`${process.env.REACT_APP_API_URL}/get-game-albums`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ sub: user.user.sub, game_id, page: albumQueryPage })
        })
            .then(response => response.json())
            .then(data => {
                setGameAlbums(data.albums)
                setTotalResultsCount(data.totalResultsCount)
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }

    const fetchGame = async () => {

        // igdb query start - needed for filling game related infos. cover, description etc
        /* new start */
        fetch(`${process.env.REACT_APP_API_URL}/get-igdb-game`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ id: game_id }),
        })
            .then((response) => {
                // Check if the response status is OK (status code 200)
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                // Parse the JSON in the response and return it
                return response.json();
            })
            .then((game) => {
                // Now you can access the parsed JSON data
                // console.log('Game fetched from IGDB:', game);
                setGame(game);
            })
            .catch((error) => {
                console.error('Error fetching game data:', error);
                // Handle the error appropriately, e.g., set an error state
            });

        /* new end */
        // igdb query end
    }

    const fetchGameAlbums = async () => {

        // if (!(user.user.sub || user.notLogged)) return false
        // local query --> get ALL albums for this game.
        fetch(`${process.env.REACT_APP_API_URL}/get-game-albums`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ sub: user.user.sub, game_id, page: albumQueryPage })
        })
            .then(response => response.json())
            .then(gameAlbums => {
                // console.log(gameAlbums);
                setGameAlbums(gameAlbums.albums)
            })
            .catch(error => {
                console.error('Error:', error);
            });

    }

    const handleKeyPress = (event) => {
        if (event.key === 'Escape') {
            resetForm()
        }
    };

    /* local end */
    useEffect(() => {

        fetchGame()
        fetchGameAlbums()
        /* also add esc key listener for closing forms */
        document.addEventListener('keydown', handleKeyPress);

    }, [game_id])


    const handleImgFile = (file) => {

        setImageToAdd(file); // just for testing, maybe thats enough

        const reader = new FileReader();
        reader.onload = () => {
            // Display the dropped or pasted image
            imgRef.current.src = reader.result;
        };
        reader.readAsDataURL(file);

    };

    const handleImgDragOver = (e) => {
        e.preventDefault();
    };

    const handleImgDrop = (e) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];

        if (file && file.type.startsWith('image/')) {
            handleImgFile(file);
        }
    };

    const handleImgPaste = (e) => {

        const items = e.clipboardData.items;

        for (let i = 0; i < items.length; i++) {
            if (items[i].type.indexOf('image') !== -1) {
                const blob = items[i].getAsFile();
                // console.log('blob DATA: ', blob);
                handleImgFile(blob);
                break;
            }
        }
    };

    const handleFileSelect = (e) => {
        const file = e.target.files[0];

        if (file && file.type.startsWith('image/')) {
            handleImgFile(file);
        }
    };

    const checkNextStepAvailabity = (currentStep) => {
        if (currentStep === 1) {
            if (addAlbumName.trim() !== "") {
                return true
            } else {
                return false
            }
        }

        else if (currentStep === 2) {
            if (imageToAdd) return true
            else return false
        }

        else if (currentStep >= 3) { // after step 3, inputs are optional.
            if (isURL(addAlbumPlaylistURL) && addAlbumPlaylistURL.includes("youtube.com") && addAlbumPlaylistURL.includes("list=")) return true
            else return false
        }
    }

    const handleAlbumNameChange = (event) => {
        setAddAlbumName(event.target.value);
    };

    const handlePlaylistURLChange = (event) => {
        setAddAlbumPlaylistURL(event.target.value);
    };

    const handleDescriptionChange = (event) => {
        setAddAlbumDescription(event.target.value);
    };

    const resetForm = () => {
        // reset all the form (state) and hide popup
        setAddAlbumPopupVisible(false)
        setAddAlbumStep(1)
        setAddAlbumName("")
        setImageToAdd(null)
    }

    useEffect(() => {
        if (user.user.logged !== undefined) fetchGameAlbums() // wait for true or false
    }, [user.user.logged])

    return (
        <div>

            {addAlbumPopupVisible && game &&
                <form className='add-game-form ' onSubmit={handleAlbumSubmit}>

                    <div className={`form-group ${addAlbumStep !== 1 ? 'hidden' : ''}`}>
                        <label htmlFor="albumName">
                            <span className='label-category'>Album Name:</span>
                            <span className='span-shortcut' style={{ cursor: "pointer" }} onClick={searchAlbumName}>[Search
                                <svg id="new-tab" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52" enable-background="new 0 0 52 52" >
                                    <g>
                                        <path d="M48.7,2H29.6C28.8,2,28,2.5,28,3.3v3C28,7.1,28.7,8,29.6,8h7.9c0.9,0,1.4,1,0.7,1.6l-17,17   c-0.6,0.6-0.6,1.5,0,2.1l2.1,2.1c0.6,0.6,1.5,0.6,2.1,0l17-17c0.6-0.6,1.6-0.2,1.6,0.7v7.9c0,0.8,0.8,1.7,1.6,1.7h2.9   c0.8,0,1.5-0.9,1.5-1.7v-19C50,2.5,49.5,2,48.7,2z" />
                                        <path d="M36.3,25.5L32.9,29c-0.6,0.6-0.9,1.3-0.9,2.1v11.4c0,0.8-0.7,1.5-1.5,1.5h-21C8.7,44,8,43.3,8,42.5v-21   C8,20.7,8.7,20,9.5,20H21c0.8,0,1.6-0.3,2.1-0.9l3.4-3.4c0.6-0.6,0.2-1.7-0.7-1.7H6c-2.2,0-4,1.8-4,4v28c0,2.2,1.8,4,4,4h28   c2.2,0,4-1.8,4-4V26.2C38,25.3,36.9,24.9,36.3,25.5z" />
                                    </g>
                                </svg>
                                ]
                            </span>
                            <span className='span-shortcut' style={{ cursor: "pointer" }} onClick={autoFillAlbumName}>[Auto suggest]</span>
                        </label>
                        <input type="text" value={addAlbumName} onChange={handleAlbumNameChange} autoComplete="off" className="form-control" id="albumName" placeholder="Enter the album name" required />

                        <div className='add-album-info' style={{ marginTop: "20px" }}>
                            <p>
                                Please check for the official album name online and use it. If not available, you can simply use the auto-suggest function, which will automatically generate a text using a basic template. However, we recommend using the proper album name for better accuracy. Thank you!
                            </p>
                        </div>

                    </div>

                    <div className={`form-group ${addAlbumStep !== 2 ? 'hidden' : ''}`}>
                        <label htmlFor="albumCoverImage" style={{ display: "block" }}>
                            <span className='label-category'>Album Cover Image:</span>
                            <span className='span-shortcut' style={{ cursor: "pointer" }} onClick={searchCover}>
                                [Search cover
                                <svg id="new-tab" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52" enable-background="new 0 0 52 52" >
                                    <g>
                                        <path d="M48.7,2H29.6C28.8,2,28,2.5,28,3.3v3C28,7.1,28.7,8,29.6,8h7.9c0.9,0,1.4,1,0.7,1.6l-17,17   c-0.6,0.6-0.6,1.5,0,2.1l2.1,2.1c0.6,0.6,1.5,0.6,2.1,0l17-17c0.6-0.6,1.6-0.2,1.6,0.7v7.9c0,0.8,0.8,1.7,1.6,1.7h2.9   c0.8,0,1.5-0.9,1.5-1.7v-19C50,2.5,49.5,2,48.7,2z" />
                                        <path d="M36.3,25.5L32.9,29c-0.6,0.6-0.9,1.3-0.9,2.1v11.4c0,0.8-0.7,1.5-1.5,1.5h-21C8.7,44,8,43.3,8,42.5v-21   C8,20.7,8.7,20,9.5,20H21c0.8,0,1.6-0.3,2.1-0.9l3.4-3.4c0.6-0.6,0.2-1.7-0.7-1.7H6c-2.2,0-4,1.8-4,4v28c0,2.2,1.8,4,4,4h28   c2.2,0,4-1.8,4-4V26.2C38,25.3,36.9,24.9,36.3,25.5z" />
                                    </g>
                                </svg>
                                ]
                            </span>
                        </label>

                        {!imageToAdd &&
                            <div className='drag-image-wrapper'>
                                <div
                                    className='drag-info-container'
                                    onDrop={handleImgDrop}
                                    onPaste={handleImgPaste}
                                    onDragOver={handleImgDragOver}
                                >
                                    <div>Drag and drop an image OR paste from clipboard OR manually select from your computer</div>
                                </div>

                                <div className='manual-file-select-container'>
                                    <input
                                        type="file"
                                        accept="image/*"
                                        onChange={handleFileSelect}
                                        ref={fileInputRef}
                                    />
                                </div>

                            </div>
                        }

                        {imageToAdd &&
                            <img
                                ref={imgRef}
                                alt="Dropped or Pasted"
                                style={{ maxWidth: '100%', maxHeight: '190px', margin: '1px auto' }}
                            />
                        }

                    </div>

                    <div className={`${addAlbumStep !== 3 ? 'hidden' : ''}`}>
                        <div className="form-group">
                            <label htmlFor="email">Youtube Playlist URL: <span className='span-shortcut' style={{ cursor: "pointer" }} onClick={searchYoutube}>[Search youtube

                                <svg id="new-tab" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52" enable-background="new 0 0 52 52" >
                                    <g>
                                        <path d="M48.7,2H29.6C28.8,2,28,2.5,28,3.3v3C28,7.1,28.7,8,29.6,8h7.9c0.9,0,1.4,1,0.7,1.6l-17,17   c-0.6,0.6-0.6,1.5,0,2.1l2.1,2.1c0.6,0.6,1.5,0.6,2.1,0l17-17c0.6-0.6,1.6-0.2,1.6,0.7v7.9c0,0.8,0.8,1.7,1.6,1.7h2.9   c0.8,0,1.5-0.9,1.5-1.7v-19C50,2.5,49.5,2,48.7,2z" />
                                        <path d="M36.3,25.5L32.9,29c-0.6,0.6-0.9,1.3-0.9,2.1v11.4c0,0.8-0.7,1.5-1.5,1.5h-21C8.7,44,8,43.3,8,42.5v-21   C8,20.7,8.7,20,9.5,20H21c0.8,0,1.6-0.3,2.1-0.9l3.4-3.4c0.6-0.6,0.2-1.7-0.7-1.7H6c-2.2,0-4,1.8-4,4v28c0,2.2,1.8,4,4,4h28   c2.2,0,4-1.8,4-4V26.2C38,25.3,36.9,24.9,36.3,25.5z" />
                                    </g>
                                </svg>

                                ]</span></label>
                            <input onChange={handlePlaylistURLChange} value={addAlbumPlaylistURL} type="text" autoComplete="off" className="form-control" id="albumYoutubePlaylistURL" placeholder="Enter youtube playlist URL" required />

                            <div className='add-album-info' style={{ marginTop: "20px" }}>
                                <p>
                                    Copy the playlist URL from YouTube and paste it here. Make sure the playlist is public and not private.
                                </p>
                            </div>

                        </div>
                    </div>

                    <div className={`${addAlbumStep !== 4 ? 'hidden' : ''}`}>
                        <div className="form-group">
                            <label htmlFor="email">Description (optional):</label>
                            <textarea
                                value={addAlbumDescription}
                                onChange={handleDescriptionChange}
                                rows={4} // Set the number of visible rows
                                cols={50} // Set the number of visible columns
                                placeholder="Enter the album description..."
                                className='form-control'
                                id="albumDescription"
                            />
                        </div>
                    </div>

                    <div className={`${addAlbumStep !== 5 ? 'hidden' : ''}`}>
                        <div className="form-group">
                            {/* <label>Note:</label> */}
                            <div className='add-album-info'>
                                <p>
                                    Once you've added the playlist, please take a moment to review and edit the song titles/links if necessary. Remove any unnecessary text from the prefix/suffix and keep only the original song titles. You may also delete any songs that are not working or have been removed from Youtube.
                                </p>
                                <hr></hr>

                                <div className='example-group'>
                                    <div className='example example--bad'>
                                        <span>Bad:</span>TES V Skyrim Soundtrack - Ancient Stones
                                    </div>
                                    <div className='example example--good'>
                                        <span>Good:</span>Ancient Stones
                                    </div>
                                </div>

                                <div className='example-group'>
                                    <div className='example example--bad'>
                                        <span>Bad:</span>Gerudo Valley - The Legend of Zelda: Ocarina Of Time OST 1080P HQ
                                    </div>
                                    <div className='example example--good'>
                                        <span>Good:</span>Gerudo Valley
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className='common-form-btns'>
                        {addAlbumStep > 1 &&
                            <button type="button" className='previous-step' onClick={() => {
                                setAddAlbumStep(addAlbumStep - 1)
                            }}>Previous</button>
                        }

                        {addAlbumStep === 2 && imageToAdd &&
                            <button type="button" onClick={() => {
                                setImageToAdd(null)
                            }}>Remove Image</button>
                        }

                        {addAlbumStep < 5 &&
                            <button type="button" className={`form-group ${checkNextStepAvailabity(addAlbumStep) ? '' : 'option-blocked'}`} onClick={() => {
                                setAddAlbumStep(addAlbumStep + 1)
                            }}>Next</button>
                        }
                        {addAlbumStep !== 5 &&
                            <button type="button" onClick={() => {
                                resetForm()
                            }}>Cancel</button>
                        }

                        {addAlbumStep === 5 &&
                            <>
                                <button type="submit" className={`${checkNextStepAvailabity(addAlbumStep) ? '' : 'option-blocked'}`}>Submit</button>
                                <button onClick={() => {
                                    resetForm()
                                }}>Cancel</button>
                            </>
                        }

                    </div>

                </form>
            }

            <div className='holder'>
                {game && <GameBanner game={game} />}
            </div>

            <div className='game-content-container'>
                <div className='mid-column'>

                    <AlbumsWrapper
                        albums={gameAlbums}
                        setAddAlbumPopupVisible={setAddAlbumPopupVisible}
                        refreshAlbums={fetchGameAlbums} // needed for instant data-refresh after album edit.
                        totalResultsCount={totalResultsCount}
                        albumQueryPage={albumQueryPage}
                    />

                </div>
            </div>

        </div >
    );
}

export default GamePage;
