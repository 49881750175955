import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import AlbumsWrapper from '../components/AlbumsWrapper';

function AlbumsPage(props) {

    const user = useSelector((state) => state.user);
    const [albumResults, setAlbumResults] = useState([]);
    const [totalResultsCount, setTotalResultsCount] = useState(0);
    const [albumQueryPage, setAlbumQueryPage] = useState(1);
    const prevSearchTermRef = useRef();
    const filters = useSelector((state) => state.filters);

    const fetchAlbums = async () => {

        fetch(`${process.env.REACT_APP_API_URL}/query-albums`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ search_term: filters.searchTerm, sub: user.user.sub, page: albumQueryPage })
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                // console.log(data.albumResults);
                // setAlbumResults(shuffle(data.albumResults).slice(0, 20))
                setTotalResultsCount(data.totalResultsCount)
                setAlbumResults(data.albumResults);
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }

    useEffect(() => {
        if (user.user.logged !== undefined) {
            if (prevSearchTermRef.current !== filters.searchTerm) {
                // If changed, reset gameQueryPage to 1 and query games
                setAlbumQueryPage(1);
                fetchAlbums();
            } else {
                fetchAlbums()
            }
            prevSearchTermRef.current = filters.searchTerm;
        }
    }, [filters.searchTerm, user.user.logged, albumQueryPage])


    return (
        <div className="albums-page">
            {/* <h1>Albums</h1> */}
            <AlbumsWrapper
                albums={albumResults}
                setAlbumResults={setAlbumResults}
                // setChosenGameAlbum={setChosenGameAlbum}
                refreshAlbums={fetchAlbums} // needed for instant data-refresh after album edit.
                albumQueryPage={albumQueryPage}
                setAlbumQueryPage={setAlbumQueryPage}
                totalResultsCount={totalResultsCount}
            />

        </div>
    );
}


export default AlbumsPage;
