const initialState = {
    isPaused: true,
    repeatSong: false,
    shuffleEnabled: true,
    volume: 100,
};

const playbackReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_IS_PAUSED':
            return {
                ...state,
                isPaused: action.isPaused, // Update game_title when needed
            };
        case 'SET_PLAYING_SONG_OBJECT':
            return {
                ...state,
                playingSongObject: action.payload,
            };

        case 'SET_VIDEO_DURATION':
            return {
                ...state,
                duration: action.duration,
            };

        case 'SET_CURRENT_TIMESTAMP':
            return {
                ...state,
                currentTimestamp: action.currentTimestamp,
            };

        case 'SET_VOLUME':
            return {
                ...state,
                volume: action.volume,
            };

        case 'TOGGLE_REPEAT':
            return {
                ...state,
                repeatSong: !state.repeatSong
            };

        case 'TOGGLE_SHUFFLE':
            return {
                ...state,
                shuffleEnabled: !state.shuffleEnabled
            };

        default:
            return state;
    }
};

export default playbackReducer;
