
import React from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import useFunctions from './Functions';

function FavoritesBanner(props) {
    const account = useSelector((state) => state.account);
    const { mode, profile } = props;
    const { shuffle } = useFunctions();
    const shuffleEnabled = useSelector(state => state.playback.shuffleEnabled)
    const dispatch = useDispatch();

    const getFormattedDate = (timestamp) => {
        const date = new Date(timestamp);
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const formattedDate = date.toLocaleDateString('en-US', options);
        return formattedDate
    }

    const shuffleFavorites = () => {
        /* option A 
        const vidObject = account.account.liked_songs[Math.floor(Math.random() * account.account.liked_songs.length)];
        playSong({
            // vidObject,
            vidObject,
            gameName: vidObject.game_name,
            // albumData: album,
            // songIndex: 0,
            songList: account.account.liked_songs,
            togglePause: playingSongObject && playingSongObject.youtube_id === vidObject.youtube_id,
            playMode: "singlePlay"
        })
        */
        let albumData = {
            // album_name: "Game Mix", 
            album_songs: shuffleEnabled ? shuffle(account.account.liked_songs) : account.account.liked_songs.reverse() // here, I AT LEAST need this. songList = user mix, liked songs etc-copied
        }
        dispatch({ type: 'SET_PLAYLIST', playlistSongs: albumData, currentSongIndex: 0 });
        return true
    }

    return (
        <div>
            <div className='favorites-banner-container'>
                <div className='pp-container'>
                    {mode === "visitor" &&
                        <img alt='profile picture' src={profile && profile.profile_picture} />
                    }
                    {!mode && account && account.account && account.account.profile_picture &&
                        <img alt='profile picture' src={account.account.profile_picture} />
                    }
                    {((!mode && account && account.account && !account.account.profile_picture) || (!mode && !account)) &&
                        <img alt="profile picture" src="avatar.jpg" />
                    }
                    {((mode === "visitor" && profile && !profile.profile_picture) || (mode === "visitor" && !profile)) &&
                        <img alt="profile picture" src="avatar.jpg" />
                    }
                </div>
                <div className='profile-infos-container'>
                    <div className='profile-name'>
                        {!mode && account && account.account && account.account.username &&
                            account.account.username
                        }
                        {mode === "visitor" && profile.username &&
                            profile.username
                        }
                    </div>
                    {!mode && account && account.account && account.account.created_at &&
                        <div className="member-infos">
                            Member since: {getFormattedDate(account.account.created_at)}
                        </div>
                    }
                    {mode === "visitor" && profile.created_at &&
                        <div className="member-infos">
                            Member since: {getFormattedDate(profile.created_at)}
                        </div>
                    }

                    {!mode && account && account.account && account.account.liked_songs &&
                        <div className='shuffle-favorites-container'>
                            <button onClick={() => shuffleFavorites()}>SHUFFLE FAVORITES</button>
                        </div>
                    }

                </div>
            </div>
            <div className='content-navbar-container'>
                {!mode && <NavLink to="/favorites" end activeclassname="active">Summary</NavLink>}
                {!mode && <NavLink to="/favorites/tracks" activeclassname="active">Tracks</NavLink>}
                {!mode && <NavLink to="/favorites/albums" activeclassname="active">Albums</NavLink>}
                {!mode && <NavLink to="/favorites/games" activeclassname="active">Games</NavLink>}
                {!mode && <NavLink to="/favorites/collections" activeclassname="active">Collections</NavLink>}
                {!mode && <NavLink to="/settings" activeclassname="active">Settings</NavLink>}
                {/* <NavLink to="/history" activeclassname="active">History</NavLink> */}
            </div>
        </div>
    );
}

export default FavoritesBanner;
