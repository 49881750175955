import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'; // For functional components
import YouTube from 'react-youtube';
import { useVideoRef } from '../context/videoContext';
import { setVideoDuration, setCurrentTimestamp, setIsPaused } from '../actions/playback';
import useFunctions from './Functions';

function VideoPlayer() {

    const dispatch = useDispatch();
    const videoRef = useVideoRef(null); // from context, also shared with Playbar.js
    const videoId = useSelector((state) => state.videoPlayer.videoId);
    const myIntervalRef = useVideoRef();
    const visibleIframe = useSelector(state => state.videoPlayer.visibleIframe)
    const repeatSong = useSelector(state => state.playback.repeatSong)
    const { handleNextClick } = useFunctions();

    const formatDuration = (durationInSeconds) => {
        const minutes = Math.floor(durationInSeconds / 60);
        const seconds = Math.floor(durationInSeconds % 60);
        if (isNaN(minutes) || isNaN(seconds)) {
            return "0:00"
        }
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };

    const trackVideo = () => {
        if (myIntervalRef.current) clearInterval(myIntervalRef.current)

        myIntervalRef.current = setInterval(() => {
            if (videoRef.current && videoRef.current.getCurrentTime) {
                const time = videoRef.current.getCurrentTime()
                const duration = videoRef.current.getDuration();
                updatePlaybar(time, duration)
                dispatch(setCurrentTimestamp(formatDuration(time)));
            }
        }, 200)

    }

    useEffect(() => {
        // console.log("New video id: " + videoId)
        trackVideo()
    }, [videoId])

    // const progressBars = document.querySelectorAll("#progress-bar")
    const updatePlaybar = (currentSecond, duration) => {
        const progressPercentage = (currentSecond / duration) * 100;
        try {
            document.querySelectorAll("#progress-bar").forEach(d => d.style.width = `${progressPercentage}%`)
        } catch (e) { }
    }

    const opts = {
        playerVars: {
            // https://developers.google.com/youtube/player_parameters
            autoplay: 1,
            //controls: 0, // Set to 0 to hide controls
            cc_load_policy: 0, // Set to 0 to hide captions
            //fs: 0, // Set to 0 to disable fullscreen button
            iv_load_policy: 3, // Set to 3 to disable video annotations
            modestbranding: 1, // Set to 1 to remove YouTube logo
            rel: 0, // Set to 0 to hide related videos at the end
            showinfo: 0, // Set to 0 to hide video title and uploader info
            frameborder: 0,
        },
    };

    return (

        <div className={'right-bar ' +
            (videoId !== -1 && visibleIframe ? ' right-bar--active' : ' ')
        }>

            {/* {playingSongObject &&
                <div className="game-info-container">
                    <img src={process.env.ALBUM_COVERS_FOLDER + "/" + playingSongObject.fromAlbum + "-" + playingSongObject.fromVersionId + "_400x.jpg"} alt="album cover"></img>
                    <h1>{playingSongObject && playingSongObject.album_name}</h1>
                </div>
            } */}

            {videoId &&
                <div className='youtube-iframe-container' >
                    <YouTube
                        id="game-soundtrack"
                        videoId={videoId}
                        onReady={(e) => {
                            videoRef.current = e.target;
                            let defaultVolume = parseInt(document.querySelector(".popover input").value)
                            e.target.setVolume(defaultVolume);

                            const durationInSeconds = e.target.getDuration();
                            const formattedDuration = formatDuration(durationInSeconds);
                            dispatch(setVideoDuration(formattedDuration));

                        }}
                        opts={opts}
                        onEnd={() => {
                            dispatch(setIsPaused(true)); // actually "ended". needed for changing left bottom icon
                            if (repeatSong) {
                                videoRef.current.seekTo(0);
                                videoRef.current.playVideo();
                                dispatch(setIsPaused(false));
                            } else { // later: if continueWithNext is enabled
                                handleNextClick()
                            }
                        }}
                    // onStateChange={handleStateChange}
                    />
                    {/* <div className='youtube-iframe-filler'></div> */}
                </div>
            }

        </div>

    );
}

export default VideoPlayer;
