
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import FavoritesBanner from '../components/FavoritesBanner';

function FavoritesPage(props) {

  const account = useSelector((state) => state.account);
  const [profile, setProfile] = useState({});
  const user = useSelector((state) => state.user);
  const { mode } = props;
  const { profile_id } = useParams();

  const [contributionsObject, setContributionsObject] = useState(null);
  const [percentageAddedAlbums, setPercentageAddedAlbums] = useState(0);
  const [colorClassAddedAlbums, setColorClassAddedAlbums] = useState("");
  const [percentageAddedCollections, setPercentageAddedCollections] = useState(0);
  const [colorClassAddedCollections, setColorClassAddedCollections] = useState("");
  const [percentageGamesContributed, setPercentageGamesContributed] = useState(0);
  const [colorClassGamesContributed, setColorClassGamesContributed] = useState("");
  const [percentageUniqueGamesContributed, setPercentageUniqueGamesContributed] = useState(0);
  const [colorClassUniqueGamesContributed, setColorClassUniqueGamesContributed] = useState("");
  const [percentageAlbumsLikedBy, setPercentageAlbumsLikedBy] = useState(0);
  const [colorClassAlbumsLikedBy, setColorClassAlbumsLikedBy] = useState("");
  const [percentageCollectionsLikedBy, setPercentageCollectionsLikedBy] = useState(0);
  const [colorClassCollectionsLikedBy, setColorClassCollectionsLikedBy] = useState("");


  const getProfile = async () => {

    return new Promise((resolve, reject) => {

      fetch(`${process.env.REACT_APP_API_URL}/get-profile-data`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ profile_id }) // filters includes search_term, sites and models
      })
        .then(response => response.json())
        .then(profileData => {
          console.log('visitor info: ', profileData);
          resolve(profileData)
        })
        .catch(error => {
          console.error('Error:', error);
        });
    })
  }

  useEffect(() => {
    if (parseInt(profile_id) === account.account.public_user_id) {
      // navigate(`/favorites`) // causes infinite reload
    }
    if (profile_id) {
      getProfile().then(data => {
        setProfile(data.account)
      })
    }
  }, [profile_id])


  const getColorFromPercentage = (percentage) => {
    // return "color-val-7"
    if (percentage < 25) return "color-val-1"
    else if (percentage < 45) return "color-val-2"
    else if (percentage < 65) return "color-val-3"
    else if (percentage < 75) return "color-val-4"
    else if (percentage < 90) return "color-val-5"
    else if (percentage < 95) return "color-val-6"
    else if (percentage <= 100) return "color-val-7"
  }

  // update later, math related
  function calculatePercentage(input, category) {
    //input = 20
    let thresholds, maxThreshold;
    if (category === "albumCount") {
      // thresholds = [5, 10, 25, 50, 100, 150, 250];
      maxThreshold = 150;
    } else if (category === "collectionCount") {
      // thresholds = [1, 5, 10, 25, 50, 75, 100];
      maxThreshold = 40;
    } else if (category === "gamesContributed") {
      // thresholds = [5, 10, 25, 50, 100, 150, 250];
      maxThreshold = 150;
    } else if (category === "uniqueGamesContributed") {
      // thresholds = [5, 10, 25, 50, 100, 150, 250];
      maxThreshold = 150;
    } else if (category === "albumsLikedBy") {
      maxThreshold = 150;
    } else if (category === "collectionsLikedBy") {
      maxThreshold = 150;
    }

    let result = parseInt((input * 100) / maxThreshold)
    if (result > 100) result = 100

    return result;
  }

  useEffect(() => {

    if (user.user.logged === true && account.account.contributions) {

      let contributionsObject = profile && profile.contributions ? profile.contributions : account.account.contributions
      setContributionsObject(contributionsObject)

      const percentageAddedAlbums = calculatePercentage(contributionsObject.addedAlbumIds.length, "albumCount")
      const colorClassAddedAlbums = getColorFromPercentage(percentageAddedAlbums)
      setPercentageAddedAlbums(percentageAddedAlbums)
      setColorClassAddedAlbums(colorClassAddedAlbums)

      const percentageAddedCollections = calculatePercentage(contributionsObject.addedCollectionIds.length, "collectionCount")
      const colorClassAddedCollections = getColorFromPercentage(percentageAddedCollections)
      setPercentageAddedCollections(percentageAddedCollections)
      setColorClassAddedCollections(colorClassAddedCollections)

      const percentageGamesContributed = calculatePercentage(contributionsObject.contributedGameIds.length, "gamesContributed")
      const colorClassGamesContributed = getColorFromPercentage(percentageGamesContributed)
      setPercentageGamesContributed(percentageGamesContributed)
      setColorClassGamesContributed(colorClassGamesContributed)

      const percentageUniqueGamesContributed = calculatePercentage(contributionsObject.contributedUniqueGameIds.length, "uniqueGamesContributed")
      const colorClassUniqueGamesContributed = getColorFromPercentage(percentageUniqueGamesContributed)
      setPercentageUniqueGamesContributed(percentageUniqueGamesContributed)
      setColorClassUniqueGamesContributed(colorClassUniqueGamesContributed)

      const percentageAlbumsLikedBy = calculatePercentage(contributionsObject.albumsLikedBy, "albumsLikedBy")
      const colorClassAlbumsLikedBy = getColorFromPercentage(percentageAlbumsLikedBy)
      setPercentageAlbumsLikedBy(percentageAlbumsLikedBy)
      setColorClassAlbumsLikedBy(colorClassAlbumsLikedBy)

      const percentageCollectionsLikedBy = calculatePercentage(contributionsObject.collectionsLikedBy, "collectionsLikedBy")
      const colorClassCollectionsLikedBy = getColorFromPercentage(percentageCollectionsLikedBy)
      setPercentageCollectionsLikedBy(percentageCollectionsLikedBy)
      setColorClassCollectionsLikedBy(colorClassCollectionsLikedBy)

    }

  }, [user.user.logged, account.account, profile])


  return (

    <div className='favorites-content-container'>
      {account && account.account && account.account.username &&
        <FavoritesBanner
          mode={mode}
          profile={profile}
        />
      }

      {account && account.account && account.account.username && contributionsObject!==null &&
        <div className='profile-stats-container'>

          <div class="flex-wrapper">

            <div class="single-chart">
              <svg viewBox="0 0 36 36" className={"circular-chart " + colorClassAddedAlbums}>
                <path class="circle-bg"
                  d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
                />
                <path class="circle"
                  stroke-dasharray={percentageAddedAlbums + ", 100"}
                  d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
                />
                <text x="18" y="20.35" class="percentage">
                  {percentageAddedAlbums}%
                </text>
              </svg>
              <div className="circle-text-info-container">
                <span className='info-title'>Albums added</span>
                <span className='info-value'>({contributionsObject.addedAlbumIds.length})</span>
              </div>
            </div>

            <div class="single-chart">
              <svg viewBox="0 0 36 36" className={"circular-chart " + colorClassGamesContributed}>
                <path class="circle-bg"
                  d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
                />
                <path class="circle"
                  stroke-dasharray={percentageGamesContributed + ", 100"}
                  d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
                />
                <text x="18" y="20.35" class="percentage">
                  {percentageGamesContributed}%
                </text>
              </svg>
              <div className="circle-text-info-container">
                <span className='info-title'>Games contributed</span>
                <span className='info-value'>({contributionsObject.contributedGameIds.length})</span>
              </div>
            </div>


            <div class="single-chart">
              <svg viewBox="0 0 36 36" className={"circular-chart " + colorClassUniqueGamesContributed}>
                <path class="circle-bg"
                  d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
                />
                <path class="circle"
                  stroke-dasharray={percentageGamesContributed + ", 100"}
                  d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
                />
                <text x="18" y="20.35" class="percentage">
                  {percentageUniqueGamesContributed}%
                </text>
              </svg>
              <div className="circle-text-info-container">
                <span className='info-title'>Games contributed (unique)</span>
                <span className='info-value'>({contributionsObject.contributedUniqueGameIds.length})</span>
              </div>
            </div>

            <div class="single-chart">
              <svg viewBox="0 0 36 36" class={"circular-chart " + colorClassAlbumsLikedBy}>
                <path class="circle-bg"
                  d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
                />
                <path class="circle"
                  stroke-dasharray={percentageAlbumsLikedBy + ", 100"}
                  d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
                />
                <text x="18" y="20.35" class="percentage">
                  {percentageAlbumsLikedBy}%
                </text>
              </svg>
              <div className="circle-text-info-container">
                <span className='info-title'>Albums liked by</span>
                <span className='info-value'>({contributionsObject.albumsLikedBy})</span>
              </div>
            </div>

            <div class="single-chart">
              <svg viewBox="0 0 36 36" className={"circular-chart " + colorClassAddedCollections}>
                <path class="circle-bg"
                  d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
                />
                <path class="circle"
                  stroke-dasharray={percentageAddedCollections + ", 100"}
                  d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
                />
                <text x="18" y="20.35" class="percentage">
                  {percentageAddedCollections}%
                </text>
              </svg>
              <div className="circle-text-info-container">
                <span className='info-title'>Collections added</span>
                <span className='info-value'>({contributionsObject.addedCollectionIds.length})</span>
              </div>
            </div>

            <div class="single-chart">
              <svg viewBox="0 0 36 36" class={"circular-chart " + colorClassCollectionsLikedBy} >
                <path class="circle-bg"
                  d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
                />
                <path class="circle"
                  stroke-dasharray={percentageCollectionsLikedBy + ", 100"}
                  d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
                />
                <text x="18" y="20.35" class="percentage">
                  {percentageCollectionsLikedBy}%
                </text>
              </svg>
              <div className="circle-text-info-container">
                <span className='info-title'>Collections liked by</span>
                <span className='info-value'>({contributionsObject.collectionsLikedBy})</span>
              </div>
            </div>

          </div>

        </div>
      }

      {user.user.logged === false && account && account.account && !account.account.username &&

        <div style={{ "margin": "80px 50px", "textAlign": "left" }}>Please login to see this page </div>

      }
    </div>
  );
}

export default FavoritesPage;