export const SET_IS_PAUSED = 'SET_IS_PAUSED';
export const SET_VIDEO_DURATION = 'SET_VIDEO_DURATION'; 
export const SET_CURRENT_TIMESTAMP = 'SET_CURRENT_TIMESTAMP'; 
export const SET_VOLUME = 'SET_VOLUME'; 
export const TOGGLE_REPEAT = 'TOGGLE_REPEAT'; 
export const TOGGLE_SHUFFLE = 'TOGGLE_SHUFFLE'; 

export function setIsPaused(isPaused) {
  return { type: SET_IS_PAUSED, isPaused };
}

export function setVideoDuration(duration) {
  return { type: SET_VIDEO_DURATION, duration };
}

export function setCurrentTimestamp(currentTimestamp) {
  return { type: SET_CURRENT_TIMESTAMP, currentTimestamp };
}

export function toggleRepeat() {
  return { type: TOGGLE_REPEAT };
}

export function toggleShuffle() {
  return { type: TOGGLE_SHUFFLE };
}

export function setVolume(volume) {
  return { type: SET_VOLUME, volume };
}
