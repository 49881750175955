
import React, { useState, useRef } from 'react';
import { toast } from 'react-toastify';

function StatsPage() {

    const [imageToAdd, setImageToAdd] = useState(null);
    const imgRef = useRef(null);

    const handleImgFile = (file) => {

        setImageToAdd(file); // just for testing, maybe thats enough

        const reader = new FileReader();
        reader.onload = () => {
            // Display the dropped or pasted image
            imgRef.current.src = reader.result;
        };
        reader.readAsDataURL(file);

    };

    const handleImgDragOver = (e) => {
        e.preventDefault();
    };

    const handleImgDrop = (e) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];

        if (file && file.type.startsWith('image/')) {
            handleImgFile(file);
        }
    };

    const handleImgPaste = (e) => {

        const items = e.clipboardData.items;

        for (let i = 0; i < items.length; i++) {
            if (items[i].type.indexOf('image') !== -1) {
                const blob = items[i].getAsFile();
                // console.log('blob DATA: ', blob);
                handleImgFile(blob);
                break;
            }
        }
    };

    const addAlbumTest = () => {
        const formData = new FormData();
        formData.append("playlistID", "playlistID-123");
        if (imageToAdd) {
            formData.append("album_cover_image", imageToAdd);
        }

        console.log('formData.album_cover_image is coming: ', formData.get('album_cover_image'));
        //return false

        fetch(`${process.env.REACT_APP_API_URL}/add-album`, {
            method: 'POST',
            body: formData
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {

                toast.success('Album is added', {
                    position: "bottom-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });

                //resolve(true)

            })
            .catch(error => {
                toast.error('Error adding album.', {
                    position: "bottom-center",
                    autoClose: 3500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                console.log('error ::', error);
                console.error('Error:', error);
            });

    }

    return (
        <div className='stats-page'>
            <h1>Stats Page</h1>

            <div className={`form-group`}>
                <label htmlFor="albumCoverImage" style={{ display: "block" }}>
                    <span className='label-category'>Album Cover Image:</span>
                    <span className='span-shortcut' style={{ cursor: "pointer" }}>
                        [Search cover
                        <svg id="new-tab" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52" enable-background="new 0 0 52 52" >
                            <g>
                                <path d="M48.7,2H29.6C28.8,2,28,2.5,28,3.3v3C28,7.1,28.7,8,29.6,8h7.9c0.9,0,1.4,1,0.7,1.6l-17,17   c-0.6,0.6-0.6,1.5,0,2.1l2.1,2.1c0.6,0.6,1.5,0.6,2.1,0l17-17c0.6-0.6,1.6-0.2,1.6,0.7v7.9c0,0.8,0.8,1.7,1.6,1.7h2.9   c0.8,0,1.5-0.9,1.5-1.7v-19C50,2.5,49.5,2,48.7,2z" />
                                <path d="M36.3,25.5L32.9,29c-0.6,0.6-0.9,1.3-0.9,2.1v11.4c0,0.8-0.7,1.5-1.5,1.5h-21C8.7,44,8,43.3,8,42.5v-21   C8,20.7,8.7,20,9.5,20H21c0.8,0,1.6-0.3,2.1-0.9l3.4-3.4c0.6-0.6,0.2-1.7-0.7-1.7H6c-2.2,0-4,1.8-4,4v28c0,2.2,1.8,4,4,4h28   c2.2,0,4-1.8,4-4V26.2C38,25.3,36.9,24.9,36.3,25.5z" />
                            </g>
                        </svg>
                        ]
                    </span>
                </label>

                {!imageToAdd &&
                    <div
                        className='drag-info-container'
                        onDrop={handleImgDrop}
                        onPaste={handleImgPaste}
                        onDragOver={handleImgDragOver}
                        style={{ border: '2px dashed #ccc' }}
                    >

                        <div >Drag and drop an image OR paste from clipboard OR manually select from your computer</div>

                    </div>
                }

                {imageToAdd &&
                    <img
                        ref={imgRef}
                        alt="Dropped or Pasted"
                        style={{ maxWidth: '100%', maxHeight: '190px', margin: '1px auto' }}
                    />
                }

            </div>

            <button onClick={addAlbumTest}>ADD ALBUM</button>

        </div>
    );
}

export default StatsPage;
