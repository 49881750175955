// Action Types
export const SET_ACCOUNT = "SET_ACCOUNT";
export const TOGGLE_SONG_LIKE_STATUS = "TOGGLE_SONG_LIKE_STATUS"
export const TOGGLE_ALBUM_LIKE_STATUS = "TOGGLE_ALBUM_LIKE_STATUS"
export const TOGGLE_GAME_LIKE_STATUS = "TOGGLE_GAME_LIKE_STATUS"
export const TOGGLE_COLLECTION_LIKE_STATUS = "TOGGLE_COLLECTION_LIKE_STATUS"

// Action Creators
export const setAccount = (accountData) => ({
  type: SET_ACCOUNT,
  payload: accountData,
});

// Action Creators
export const toggleSongLikeStatus = (songInfo) => ({
  type: TOGGLE_SONG_LIKE_STATUS,
  payload: songInfo,
});


export const toggleAlbumLikeStatus = (albumId) => ({
  type: TOGGLE_ALBUM_LIKE_STATUS,
  payload: albumId,
});

export const toggleCollectionLikeStatus = (collectionId) => ({
  type: TOGGLE_COLLECTION_LIKE_STATUS,
  payload: collectionId,
});


export const toggleGameLikeStatus = (gameId) => ({
  type: TOGGLE_GAME_LIKE_STATUS,
  payload: gameId,
});

export const updateListeningHistory = (song) => ({
  type: "UPDATE_LISTENING_HISTORY",
  payload: song,
});

