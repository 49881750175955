
import React, { useState, useEffect } from 'react';
import useFunctions from '../../components/Functions';
import { useSelector } from 'react-redux';
import FavoritesBanner from '../../components/FavoritesBanner';
import GamesWrapper from '../../components/GamesWrapper';

function FavoriteGamesPage() {

  const { getGamesById } = useFunctions();
  const [likedGameIds, setLikedGameIds] = useState([]);
  const account = useSelector((state) => state.account);
  const [gameQueryPage, setGameQueryPage] = useState(1);
  const [games, setGames] = useState([]);
  const [totalResultsCount, setTotalResultsCount] = useState(0);

  const filterLikedGames = () => {
    if (likedGameIds.length > 0) {
      getGamesById(likedGameIds, gameQueryPage).then(data => {
        if (data.games.length > 0) {
          setGames(data.games.reverse())
          setTotalResultsCount(data.totalResultsCount)
        }
      })
    }
  }

  useEffect(() => {
    if (account.account && account.account.liked_game_ids) {
      if (account.account.liked_game_ids.length > 0) {
        let reversed = [...account.account.liked_game_ids].reverse()
        setLikedGameIds(reversed)
      }
    }
  }, [account])

  useEffect(() => {
    filterLikedGames()
  }, [likedGameIds, gameQueryPage])

  return (
    <div className='favorites-content-container'>
      <FavoritesBanner />

      {likedGameIds &&
        <GamesWrapper
          games={games}
          gameQueryPage={gameQueryPage}
          setGameQueryPage={setGameQueryPage}
          totalResultsCount={totalResultsCount}
        />
      }

    </div>
  );
}

export default FavoriteGamesPage;
