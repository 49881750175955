import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import useFunctions from './Functions';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';

function CollectionWrapper(props) {

    const { getShortDate, likeCollection, playCollection } = useFunctions();
    const account = useSelector((state) => state.account);
    const user = useSelector((state) => state.user);
    const [collectionURL, setCollectionURL] = useState("#");
    const [isCollectionPopup, setIsCollectionPopup] = useState(false);
    const { activeCollectionPopup, setActiveCollectionPopup, refreshCollections, setEditCollectionPopupVisible, setEditingCollectionId, collection, index, setAddCollectionPopupVisible, addCollectionPopupVisible, popupRef, fromFavorites } = props;

    const deleteCollection = async (collection) => {
        let answer = window.confirm("Delete collection?")
        if (!answer) return false
        return new Promise((resolve, reject) => {
            const collectionId = collection._id;
            fetch(`${process.env.REACT_APP_API_URL}/delete-collection`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ collectionId, sub: user.user.sub })
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then(data => {
                    // console.log('Response data:', data);
                    toast.success('Collection successfully deleted.', {
                        position: "bottom-center",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });

                    refreshCollections()
                    resolve(true)
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        })
    }

    useEffect(() => {
        if (!collection) return
        if (collection.collection_type === "album") setCollectionURL('/collection/' + collection._id)
        else if (collection.collection_type === "game") setCollectionURL('/collection/' + collection.collection_type + "Collection/" + collection._id);
    }, [collection])

    return (

        <div className='collection-wrapper' data-id={collection && collection.collection_name}>
            {collection &&
                <div className='collection-container'>
                    <NavLink to={collectionURL}>
                        <div className='collection-image-wrapper' style={{ "backgroundImage": "url(" + process.env.REACT_APP_COLLECTION_IMAGES_FOLDER + "/" + collection._id + "-" + collection.versionId + "_400x.jpg)" }}>
                            <div className="collection-btns-container component-btns-container">
                                <div className='collection-btn-container component-btn-container play-btn-container' onClick={(e) => {
                                    e.preventDefault()
                                    playCollection(collection)
                                }}>
                                    <svg className="svg-icon svg-icon-play" focusable="false" height="1em" role="img" width="1em" viewBox="0 0 12 12" aria-hidden="true"><path fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" d="M2.5.5v11l9-5.5z"></path></svg>
                                </div>

                                {account && account.account && account.account.liked_collection_ids &&
                                    <div className='collection-btn-container component-btn-container love-btn-container' onClick={(e) => {
                                        e.preventDefault()
                                        likeCollection(collection._id)
                                    }}>
                                        {account && account.account && account.account.liked_collection_ids && !account.account.liked_collection_ids.includes(collection._id) &&// not liked
                                            <svg viewBox="0 0 16 16" focusable="false" className="chakra-icon css-1yk3h4a" data-testid="HeartIcon">
                                                <path d="m8 4.79-.755-.869c-1.17-1.348-2.252-1.832-3.093-1.9-.836-.067-1.59.263-2.164.858C.802 4.108.528 6.283 2.04 7.812a245.96 245.96 0 0 0 4.775 4.7c.482.46.882.837 1.186 1.122.304-.285.704-.663 1.186-1.123a238.026 238.026 0 0 0 4.771-4.695 3.545 3.545 0 0 0 .057-4.963c-.572-.589-1.324-.915-2.161-.843-.843.072-1.926.562-3.098 1.911L8 4.791zm6.672 3.725C10.78 12.452 8 15 8 15s-2.78-2.548-6.672-6.485c-3.717-3.76 1.043-10.549 5.976-5.972.232.215.464.455.696.723.232-.267.464-.508.696-.723C13.63-2.04 18.39 4.68 14.672 8.515z">
                                                </path>
                                                <path d="m8 4.79-.755-.869c-1.17-1.348-2.252-1.832-3.093-1.9-.836-.067-1.59.263-2.164.858C.802 4.108.528 6.283 2.04 7.812a245.96 245.96 0 0 0 4.775 4.7c.482.46.882.837 1.186 1.122.304-.285.704-.663 1.186-1.123a238.026 238.026 0 0 0 4.771-4.695 3.545 3.545 0 0 0 .057-4.963c-.572-.589-1.324-.915-2.161-.843-.843.072-1.926.562-3.098 1.911L8 4.791zm6.672 3.725C10.78 12.452 8 15 8 15s-2.78-2.548-6.672-6.485c-3.717-3.76 1.043-10.549 5.976-5.972.232.215.464.455.696.723.232-.267.464-.508.696-.723C13.63-2.04 18.39 4.68 14.672 8.515z">
                                                </path>
                                            </svg>
                                        }
                                        {account && account.account && account.account.liked_collection_ids && account.account.liked_collection_ids.includes(collection._id) && // liked
                                            <svg id="liked-collection-svg" viewBox="0 0 16 16" focusable="false" className="chakra-icon css-1qsky21" data-testid="HeartFillIcon">
                                                <path d="M8 3.266C2.837-2.68-2.564 4.578 1.328 8.516 5.22 12.451 8 15 8 15s2.78-2.548 6.672-6.485C18.564 4.501 13.162-2.679 8 3.265z">
                                                </path>
                                            </svg>
                                        }
                                    </div>
                                }

                                {fromFavorites !== true &&
                                    <div className='collection-btn-container component-btn-container options-btn-container' onClick={(e) => {
                                        e.preventDefault();
                                        setIsCollectionPopup(false)
                                        setActiveCollectionPopup(index)
                                    }}>
                                        <svg viewBox="0 0 16 16" focusable="false" className="chakra-icon css-1yk3h4a" data-testid="EllipsisIcon" aria-hidden="true"><path d="M5 8.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm5 0a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm3.5 1.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z"></path></svg>
                                    </div>
                                }

                            </div>

                        </div>
                    </NavLink>
                    <div className='collection-name-container'>
                        {collection.collection_name}
                    </div>

                    <div className='collection-infos'>
                        {getShortDate(collection.date_added)}, by <NavLink to={"/profiles/" + collection.contributors.added_by_public_id} ><span>{collection.contributors.added_by}</span></NavLink>
                    </div>

                    {collection.like_count > 0 &&
                        <div className='collection-infos'>
                            {collection.like_count} likes
                        </div>
                    }

                    {/* popup  */}
                    {activeCollectionPopup === index && (
                        <div ref={popupRef} className="collection-popup-container component-popup-container">
                            {!isCollectionPopup &&
                                <ul>
                                    {fromFavorites !== true && account && account.account && collection.canEdit &&
                                        <li onClick={(event) => {
                                            event.preventDefault()
                                            setEditCollectionPopupVisible(true)
                                            setEditingCollectionId(collection._id)
                                        }}>
                                            Edit collection
                                        </li>
                                    }

                                    {fromFavorites !== true && account && account.account && collection.canEdit &&
                                        <li onClick={(event) => {
                                            event.preventDefault()
                                            setActiveCollectionPopup(-1)
                                            deleteCollection(collection)
                                        }}>
                                            Delete collection
                                        </li>
                                    }

                                    <li onClick={(event) => {
                                        event.preventDefault()
                                        setActiveCollectionPopup(-1)
                                    }}>
                                        Cancel
                                    </li>

                                </ul>
                            }
                        </div>
                    )}

                </div>
            }
            {!collection && fromFavorites !== true && user.user.logged &&
                <div className='empty-collection-container mobile-hidden' onClick={() => setAddCollectionPopupVisible(true)}>
                    <div className='add-container'>+</div>
                </div>
            }
        </div>

    );
}


export default CollectionWrapper;
