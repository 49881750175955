import React, { useState, useEffect, useRef } from 'react';
import CollectionsWrapper from '../components/CollectionsWrapper';
import { useSelector } from 'react-redux';


function CollectionsPage(props) {

    const [collectionResults, setCollectionResults] = useState([]);
    const filters = useSelector((state) => state.filters);
    const user = useSelector((state) => state.user);
    const [collectionsQueryPage, setCollectionsQueryPage] = useState(1);
    const [totalResultsCount, setTotalResultsCount] = useState(0);
    const prevSearchTermRef = useRef();

    const fetchCollections = async () => {
        fetch(`${process.env.REACT_APP_API_URL}/query-collections`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ search_term: filters.searchTerm, page: collectionsQueryPage, sub: user.user.sub })
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setCollectionResults(data.collectionResults.reverse());
                setTotalResultsCount(data.totalResultsCount)
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }

    useEffect(() => {
        if (user.user.logged !== undefined) {
            if (prevSearchTermRef.current !== filters.searchTerm) {
                // If changed, reset collectionsQueryPage to 1 and query collections
                setCollectionsQueryPage(1);
                fetchCollections();
            } else {
                fetchCollections()
            }
            prevSearchTermRef.current = filters.searchTerm;
        }
    }, [filters.searchTerm, user.user.logged, collectionsQueryPage]);


    return (
        <>
            <CollectionsWrapper
                fetchCollections={fetchCollections}
                collectionResults={collectionResults}
                collectionsQueryPage={collectionsQueryPage}
                totalResultsCount={totalResultsCount}
                setCollectionsQueryPage={setCollectionsQueryPage}
            />
        </>
    );
}

export default CollectionsPage;