import { useSelector } from 'react-redux';
import useFunctions from './Functions';
import { React, useEffect, useState } from 'react';

function GameBanner(props) {
    const { game } = props;
    const { likeGame } = useFunctions();
    const account = useSelector((state) => state.account);
    const [colorsList, setColorsList] = useState([]);
    const [dominantColor, setDominantColor] = useState({ r: 32, g: 32, b: 32 });
    const [viewDescription, setViewDescription] = useState(false);

    useEffect(() => {
        if (colorsList.length > 0) {
            let color = colorsList[0]
            //setDominantColor(color)
        }
    }, [colorsList])

    useEffect(() => {
        /* finding color palette start */
        let fanArtUrl = game.fanartUrl

        const buildPalette = (colorsList) => {

            setColorsList(colorsList)

            const paletteContainer = document.getElementById("palette");
            const complementaryContainer = document.getElementById("complementary");
            // reset the HTML in case you load various images
            paletteContainer.innerHTML = "";
            complementaryContainer.innerHTML = "";

            const orderedByColor = orderByLuminance(colorsList);
            const hslColors = convertRGBtoHSL(orderedByColor);

            for (let i = 0; i < orderedByColor.length; i++) {
                const hexColor = rgbToHex(orderedByColor[i]);

                const hexColorComplementary = hslToHex(hslColors[i]);

                if (i > 0) {
                    const difference = calculateColorDifference(
                        orderedByColor[i],
                        orderedByColor[i - 1]
                    );

                    // if the distance is less than 120 we ommit that color
                    if (difference < 120) {
                        continue;
                    }
                }

                // create the div and text elements for both colors & append it to the document
                const colorElement = document.createElement("div");
                colorElement.style.backgroundColor = hexColor;
                colorElement.appendChild(document.createTextNode(hexColor));
                paletteContainer.appendChild(colorElement);
                // true when hsl color is not black/white/grey
                if (hslColors[i].h) {
                    const complementaryElement = document.createElement("div");
                    complementaryElement.style.backgroundColor = `hsl(${hslColors[i].h},${hslColors[i].s}%,${hslColors[i].l}%)`;

                    complementaryElement.appendChild(
                        document.createTextNode(hexColorComplementary)
                    );
                    complementaryContainer.appendChild(complementaryElement);
                }
            }
        };

        const rgbToHex = (pixel) => {
            const componentToHex = (c) => {
                const hex = c.toString(16);
                return hex.length == 1 ? "0" + hex : hex;
            };

            return (
                "#" +
                componentToHex(pixel.r) +
                componentToHex(pixel.g) +
                componentToHex(pixel.b)
            ).toUpperCase();
        };

        const hslToHex = (hslColor) => {
            const hslColorCopy = { ...hslColor };
            hslColorCopy.l /= 100;
            const a =
                (hslColorCopy.s * Math.min(hslColorCopy.l, 1 - hslColorCopy.l)) / 100;
            const f = (n) => {
                const k = (n + hslColorCopy.h / 30) % 12;
                const color = hslColorCopy.l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
                return Math.round(255 * color)
                    .toString(16)
                    .padStart(2, "0");
            };
            return `#${f(0)}${f(8)}${f(4)}`.toUpperCase();
        };

        const convertRGBtoHSL = (rgbValues) => {
            return rgbValues.map((pixel) => {
                let hue,
                    saturation,
                    luminance = 0;

                // first change range from 0-255 to 0 - 1
                let redOpposite = pixel.r / 255;
                let greenOpposite = pixel.g / 255;
                let blueOpposite = pixel.b / 255;

                const Cmax = Math.max(redOpposite, greenOpposite, blueOpposite);
                const Cmin = Math.min(redOpposite, greenOpposite, blueOpposite);

                const difference = Cmax - Cmin;

                luminance = (Cmax + Cmin) / 2.0;

                if (luminance <= 0.5) {
                    saturation = difference / (Cmax + Cmin);
                } else if (luminance >= 0.5) {
                    saturation = difference / (2.0 - Cmax - Cmin);
                }

                /**
                 * If Red is max, then Hue = (G-B)/(max-min)
                 * If Green is max, then Hue = 2.0 + (B-R)/(max-min)
                 * If Blue is max, then Hue = 4.0 + (R-G)/(max-min)
                 */
                const maxColorValue = Math.max(pixel.r, pixel.g, pixel.b);

                if (maxColorValue === pixel.r) {
                    hue = (greenOpposite - blueOpposite) / difference;
                } else if (maxColorValue === pixel.g) {
                    hue = 2.0 + (blueOpposite - redOpposite) / difference;
                } else {
                    hue = 4.0 + (greenOpposite - blueOpposite) / difference;
                }

                hue = hue * 60; // find the sector of 60 degrees to which the color belongs

                // it should be always a positive angle
                if (hue < 0) {
                    hue = hue + 360;
                }

                // When all three of R, G and B are equal, we get a neutral color: white, grey or black.
                if (difference === 0) {
                    return false;
                }

                return {
                    h: Math.round(hue) + 180, // plus 180 degrees because that is the complementary color
                    s: parseFloat(saturation * 100).toFixed(2),
                    l: parseFloat(luminance * 100).toFixed(2),
                };
            });
        };

        const orderByLuminance = (rgbValues) => {
            const calculateLuminance = (p) => {
                return 0.2126 * p.r + 0.7152 * p.g + 0.0722 * p.b;
            };

            return rgbValues.sort((p1, p2) => {
                return calculateLuminance(p2) - calculateLuminance(p1);
            });
        };

        const buildRgb = (imageData) => {
            const rgbValues = [];
            // note that we are loopin every 4!
            // for every Red, Green, Blue and Alpha
            for (let i = 0; i < imageData.length; i += 4) {
                const rgb = {
                    r: imageData[i],
                    g: imageData[i + 1],
                    b: imageData[i + 2],
                };

                rgbValues.push(rgb);
            }

            return rgbValues;
        };

        const calculateColorDifference = (color1, color2) => {
            const rDifference = Math.pow(color2.r - color1.r, 2);
            const gDifference = Math.pow(color2.g - color1.g, 2);
            const bDifference = Math.pow(color2.b - color1.b, 2);

            return rDifference + gDifference + bDifference;
        };

        const findBiggestColorRange = (rgbValues) => {

            let rMin = Number.MAX_VALUE;
            let gMin = Number.MAX_VALUE;
            let bMin = Number.MAX_VALUE;

            let rMax = Number.MIN_VALUE;
            let gMax = Number.MIN_VALUE;
            let bMax = Number.MIN_VALUE;

            rgbValues.forEach((pixel) => {
                rMin = Math.min(rMin, pixel.r);
                gMin = Math.min(gMin, pixel.g);
                bMin = Math.min(bMin, pixel.b);

                rMax = Math.max(rMax, pixel.r);
                gMax = Math.max(gMax, pixel.g);
                bMax = Math.max(bMax, pixel.b);
            });

            const rRange = rMax - rMin;
            const gRange = gMax - gMin;
            const bRange = bMax - bMin;

            const biggestRange = Math.max(rRange, gRange, bRange);
            if (biggestRange === rRange) {
                return "r";
            } else if (biggestRange === gRange) {
                return "g";
            } else {
                return "b";
            }
        };

        const quantization = (rgbValues, depth) => {
            const MAX_DEPTH = 4;

            if (depth === MAX_DEPTH || rgbValues.length === 0) {
                const color = rgbValues.reduce(
                    (prev, curr) => {
                        prev.r += curr.r;
                        prev.g += curr.g;
                        prev.b += curr.b;

                        return prev;
                    },
                    {
                        r: 0,
                        g: 0,
                        b: 0,
                    }
                );

                color.r = Math.round(color.r / rgbValues.length);
                color.g = Math.round(color.g / rgbValues.length);
                color.b = Math.round(color.b / rgbValues.length);

                return [color];
            }

            const componentToSortBy = findBiggestColorRange(rgbValues);
            rgbValues.sort((p1, p2) => {
                return p1[componentToSortBy] - p2[componentToSortBy];
            });

            const mid = rgbValues.length / 2;
            return [
                ...quantization(rgbValues.slice(0, mid), depth + 1),
                ...quantization(rgbValues.slice(mid + 1), depth + 1),
            ];
        };

        const main = () => {
            // const imgFile = document.getElementById("imgfile");

            // const imageUrl = "/test-banner.jpg"; // Replace with your image URL
            const imageUrl = fanArtUrl;
            const image = new Image();
            image.crossOrigin = "Anonymous";
            image.src = imageUrl;

            // Whenever file & image is loaded procced to extract the information from the image

            image.onload = () => {
                const canvas = document.getElementById("canvas");
                if (!canvas) return // change later
                canvas.width = image.width;
                canvas.height = image.height;
                const ctx = canvas.getContext("2d");
                ctx.drawImage(image, 0, 0);

                const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
                const rgbArray = buildRgb(imageData.data);
                const quantColors = quantization(rgbArray, 0);
                buildPalette(quantColors);
            };

        };

        main();

        /* finding color palette end */
    }, [])


    return (
        <div className="game-banner-container component-banner-container">

            <canvas id="canvas"></canvas>
            <div id="palette"></div>
            <div id="complementary"></div>

            <div className='game-banner-left component-banner-left'
                style={
                    dominantColor !== null
                        ? {
                            backgroundColor: `rgba(${dominantColor.r}, ${dominantColor.g}, ${dominantColor.b}, 1)`,
                        }
                        : {}
                }
            >
            </div>

            {dominantColor !== null &&
                <div className='game-banner-right component-banner-right'>
                    <div className='game-banner component-banner' style={game && { backgroundImage: 'url(' + game.fanartUrl + ')' }}> </div>
                    <div className='game-banner-right--gradient-bg component-banner-right--gradient-bg' style={dominantColor !== null ? {
                        background: `linear-gradient(0.25turn, rgba(${dominantColor.r}, ${dominantColor.g}, ${dominantColor.b}, 1), rgba(${dominantColor.r}, ${dominantColor.g}, ${dominantColor.b}, 0))`
                    } : {}}
                    >
                    </div>
                </div>
            }
            <div className='game-banner-infos-container component-banner-infos-container'>
                <div className='game-banner-infos-content component-banner-infos-content'>

                    <div className='banner-tags-container'>
                        <div className='tag'>Game</div>
                    </div>

                    <h1>{game.name}</h1>
                    {<div className='game-description component-description'>
                        {game.summary && game.summary.length < 250 &&
                            game.summary
                        }
                        {game.summary && game.summary.length >= 250 && !viewDescription &&
                            <div> {game.summary.slice(0, 250)}<span className="toggle-description" onClick={() => setViewDescription(!viewDescription)}>... [more]</span> </div>
                        }
                        {game.summary && game.summary.length >= 250 && viewDescription &&
                            <div>{game.summary} <span className="toggle-description" onClick={() => setViewDescription(!viewDescription)}>[hide]</span></div>
                        }
                    </div>}

                    {/* {<div className='game-cover' style={game && { backgroundImage: 'url(' + game.coverId + ')' }}> </div>} */}

                    {account && account.account && account.account.username &&
                        <div className="game-banner-buttons component-banner-buttons">
                            <div className='game-btn-container love-btn-container' onClick={() => likeGame(game.id)}>
                                {account && account.account && account.account.liked_game_ids && !account.account.liked_game_ids.find(e => e === game.id) &&// not liked
                                    <svg viewBox="0 0 16 16" focusable="false" className="chakra-icon css-1yk3h4a" data-testid="HeartIcon">
                                        <path d="m8 4.79-.755-.869c-1.17-1.348-2.252-1.832-3.093-1.9-.836-.067-1.59.263-2.164.858C.802 4.108.528 6.283 2.04 7.812a245.96 245.96 0 0 0 4.775 4.7c.482.46.882.837 1.186 1.122.304-.285.704-.663 1.186-1.123a238.026 238.026 0 0 0 4.771-4.695 3.545 3.545 0 0 0 .057-4.963c-.572-.589-1.324-.915-2.161-.843-.843.072-1.926.562-3.098 1.911L8 4.791zm6.672 3.725C10.78 12.452 8 15 8 15s-2.78-2.548-6.672-6.485c-3.717-3.76 1.043-10.549 5.976-5.972.232.215.464.455.696.723.232-.267.464-.508.696-.723C13.63-2.04 18.39 4.68 14.672 8.515z">
                                        </path>
                                        <path d="m8 4.79-.755-.869c-1.17-1.348-2.252-1.832-3.093-1.9-.836-.067-1.59.263-2.164.858C.802 4.108.528 6.283 2.04 7.812a245.96 245.96 0 0 0 4.775 4.7c.482.46.882.837 1.186 1.122.304-.285.704-.663 1.186-1.123a238.026 238.026 0 0 0 4.771-4.695 3.545 3.545 0 0 0 .057-4.963c-.572-.589-1.324-.915-2.161-.843-.843.072-1.926.562-3.098 1.911L8 4.791zm6.672 3.725C10.78 12.452 8 15 8 15s-2.78-2.548-6.672-6.485c-3.717-3.76 1.043-10.549 5.976-5.972.232.215.464.455.696.723.232-.267.464-.508.696-.723C13.63-2.04 18.39 4.68 14.672 8.515z">
                                        </path>
                                    </svg>
                                }
                                {account && account.account && account.account.liked_game_ids && account.account.liked_game_ids.find(e => e === game.id) && // liked
                                    <svg id="svg-liked" viewBox="0 0 16 16" focusable="false" className="chakra-icon css-1qsky21" data-testid="HeartFillIcon">
                                        <path d="M8 3.266C2.837-2.68-2.564 4.578 1.328 8.516 5.22 12.451 8 15 8 15s2.78-2.548 6.672-6.485C18.564 4.501 13.162-2.679 8 3.265z">
                                        </path>
                                    </svg>
                                }
                            </div>
                        </div>
                    }


                </div>
            </div>

        </div>
    );
}


// For functional components, export without connect:
export default GameBanner;
