
import React, { useState, useEffect } from 'react';
import FavoritesBanner from '../../components/FavoritesBanner';
import { useSelector } from 'react-redux';
import SongsWrapper from '../../components/SongsWrapper';


function FavoriteTracksPage() {

  const [likedSongs, setLikedSongs] = useState([]);
  const account = useSelector((state) => state.account);

  useEffect(() => {
    if (account.account && account.account.liked_songs) {
      let reversed = [...account.account.liked_songs].reverse()
      setLikedSongs(reversed)
    }
  }, [account])

  return (
    <div className='favorites-content-container'>

      <FavoritesBanner />

      {likedSongs.length > -1 &&
        <SongsWrapper
          songs={likedSongs}
          songList={likedSongs}
          albumType="favorites"
        />
      }

    </div>
  );
}

export default FavoriteTracksPage;
