// reducer.js
import { SET_ACCOUNT } from "../actions/account";
import { TOGGLE_SONG_LIKE_STATUS, TOGGLE_ALBUM_LIKE_STATUS, TOGGLE_COLLECTION_LIKE_STATUS, TOGGLE_GAME_LIKE_STATUS } from "../actions/account";

const initialState = {
    account: {},
};

const accountReducer = (state = initialState, action) => {
    switch (action.type) {

        case SET_ACCOUNT:
            return {
                ...state,
                account: action.payload,
            };

        case TOGGLE_SONG_LIKE_STATUS:
            let likedSongObject = action.payload // contains all 7 props
            const { youtube_id, album_name } = action.payload;
            const existingSongIndex = state.account.liked_songs.findIndex(
                (song) => song.youtube_id === youtube_id && song.album_name === album_name
            );

            if (existingSongIndex !== -1) {
                // Song already exists, remove it from liked_songs
                const updatedLikedSongs = [...state.account.liked_songs];
                updatedLikedSongs.splice(existingSongIndex, 1);

                return {
                    ...state,
                    account: {
                        ...state.account,
                        liked_songs: updatedLikedSongs,
                    },
                };
            } else {
                // Song doesn't exist, add it to liked_songs
                return {
                    ...state,
                    account: {
                        ...state.account,
                        liked_songs: [...state.account.liked_songs, likedSongObject],
                    },
                };
            }

        case TOGGLE_ALBUM_LIKE_STATUS:
            let likedAlbumId = action.payload // is this correct?
            // const { likedAlbumId } = action.payload;
            const albumAlreadyLiked = state.account.liked_album_ids.find(e => e === likedAlbumId)

            if (albumAlreadyLiked) {
                // album already liked, remove it from liked_album_ids
                const updatedLikedAlbumIds = [...state.account.liked_album_ids].filter(e => e !== likedAlbumId)

                return {
                    ...state,
                    account: {
                        ...state.account,
                        liked_album_ids: updatedLikedAlbumIds,
                    },
                };
            } else {
                // album doesn't exist, add it to liked_album_ids

                return {
                    ...state,
                    account: {
                        ...state.account,
                        liked_album_ids: [...state.account.liked_album_ids, likedAlbumId],
                    },
                };
            }

        case TOGGLE_COLLECTION_LIKE_STATUS:
            let likedCollectionId = action.payload // is this correct?
            // const { likedCollectionId } = action.payload;
            const collectionAlreadyLiked = state.account.liked_collection_ids.find(e => e === likedCollectionId)

            if (collectionAlreadyLiked) {
                // collection already liked, remove it from liked_collection_ids
                const updatedLikedCollectionIds = [...state.account.liked_collection_ids].filter(e => e !== likedCollectionId)

                return {
                    ...state,
                    account: {
                        ...state.account,
                        liked_collection_ids: updatedLikedCollectionIds,
                    },
                };
            } else {
                // collection doesn't exist, add it to liked_album_ids

                return {
                    ...state,
                    account: {
                        ...state.account,
                        liked_collection_ids: [...state.account.liked_collection_ids, likedCollectionId],
                    },
                };
            }

        case TOGGLE_GAME_LIKE_STATUS:
            // action.payload = the game object
            let likedGameId = action.payload // is this correct?
            const gameAlreadyLiked = state.account.liked_game_ids.find(e => e === likedGameId)

            if (gameAlreadyLiked) {
                // game already liked, remove it from liked_game_ids
                const updatedLikedGameIds = [...state.account.liked_game_ids].filter(e => e !== likedGameId)

                return {
                    ...state,
                    account: {
                        ...state.account,
                        liked_game_ids: updatedLikedGameIds,
                    },
                };
            } else {
                // album doesn't exist, add it to liked_album_ids

                return {
                    ...state,
                    account: {
                        ...state.account,
                        liked_game_ids: [...state.account.liked_game_ids, action.payload],
                    },
                };
            }

        case "UPDATE_LISTENING_HISTORY":
            const newSong = action.payload;

            // Ensure the listening_history array exists
            if (!state.account.listening_history) {
                state.account.listening_history = [];
            }

            const existingSongIdx = state.account.listening_history.findIndex(
                (historySong) => historySong.uuid === newSong.uuid
            );

            // If there's an existing song with the same uuid, remove it
            if (existingSongIdx !== -1) {
                state.account.listening_history.splice(existingSongIdx, 1);
            }

            // Add the new song to the beginning of the listening_history array
            state.account.listening_history.unshift(newSong);

            // Limit the array to a maximum of 50 songs
            if (state.account.listening_history.length > 50) {
                state.account.listening_history.pop(); // Remove the last song if there are more than 50
            }

            return {
                ...state,
                account: {
                    ...state.account,
                    listening_history: [...state.account.listening_history],
                },
            };

        default:
            return state;
    }
};

export default accountReducer;
