// Reducer
const initialState = {
  videoId: -1, // undefined
  isPlaying: false,
  visibleIframe: true,
};

const videoPlayerReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_VIDEO_ID":
      return { ...state, videoId: action.videoId };
    case "TOGGLE_PLAYBACK":
      return { ...state, isPlaying: !state.isPlaying };
    case "TOGGLE_IFRAME_VISILITY":
      return { ...state, visibleIframe: !state.visibleIframe };
    default:
      return state;
  }
}

export default videoPlayerReducer;
