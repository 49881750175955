// Define an initial state for the 'playing' slice of the Redux store
const initialState = {
    song_title: '',
    game_title: ''
};

// Define the reducer function for 'playing' slice
const playingReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_GAME_TITLE':
            return {
                ...state,
                game_title: action.payload, // Update game_title when needed
            };
        case 'SET_SONG_TITLE':
            return {
                ...state,
                song_title: action.payload, // Update game_title when needed
            };

        default:
            return state;
    }
};

export default playingReducer;
