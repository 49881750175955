
import React, { useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';

function FAQPage() {

    const [questions, setQuestions] = useState([
        { question: "What is VGMHub?", answer: "VGMHub, short for Video Game Music Hub, is a platform dedicated to sharing and listening to video game soundtracks. Using YouTube playlists, it creates a community where users contribute and explore curated gaming music collections." },
        { question: "Is VGMHub free to use?", answer: "Absolutely, VGMHub is completely free. The entirety of our content is driven by user contributions, emphasizing our dedication to fostering a free and collaborative space for video game music enthusiasts." },
        { question: "Does VGMHub host the music directly?", answer: "No, VGMHub does not host the music files. Instead, we utilize YouTube iframes to stream the video game soundtracks. This allows us to showcase a vast collection while adhering to legal and copyright regulations." },
        { question: "What's the point of using VGMHub if it only uses YouTube? Why not just use YouTube directly?", answer: "Excellent question! While YouTube is a fantastic platform, it's not exclusively dedicated to video games and their soundtracks. VGMHub serves as a specialized hub where you can effortlessly search, filter, and enjoy the soundtracks of your favorite games. Here, you have the convenience of creating playlists that seamlessly blend tracks from multiple games. While we act as a bridge to YouTube, VGMHub simplifies and enhances the entire experience, making it a one-stop destination for gaming music enthusiasts." },
        { question: "How can I contribute to VGMHub?", answer: "You can actively contribute to VGMHub by adding albums to games that currently lack any albums in our library. Your contributions play a crucial role in expanding our diverse collection of video game soundtracks." },
        { question: "Should I still add an album if someone else has already added one for the same game?", answer: "Absolutely! If the previously added album has any issues, such as incorrect album name, wrong cover image, or missing songs, feel free to contribute by adding a new album. After adding, ensure you make the necessary improvements for a visually appealing album. This increases the likelihood of your album being appreciated by other users and appearing at a higher position." },
        { question: "Some songs are not playing on mobile. Why is that?", answer: "Certain songs may not play on mobile devices due to restrictions imposed by YouTube. Unfortunately, there's no fix for this issue. However, the majority of songs should work seamlessly on desktop versions." },
        { question: "Why do so many games have no albums at all?", answer: "The reason is that VGMHub is an open-source platform created for and by video game soundtrack lovers like you. Every album you see (or don't see yet!) is a direct contribution from our fantastic community. So, feel free to dive in and be the one to kick off the soundtrack collection for your favorite games! 🎮🎵" },
        { question: "Can I edit or delete my album after I've posted it?", answer: "Certainly! You maintain complete control over your contributions. Simply click on the three dots icon located on your album, and from there, you can easily access the options to edit or delete your album as needed. " },
    ]);

    return (
        <div className='faq-page'>
            <Accordion>
                {questions.map((q, index) => {
                    return (
                        <Accordion.Item eventKey={index}>
                            <Accordion.Header >{q.question}</Accordion.Header>
                            <Accordion.Body>
                                {q.answer}
                            </Accordion.Body>
                        </Accordion.Item>
                    )
                })}

                <Accordion.Item eventKey={"s-1"}>
                    <Accordion.Header >I have a bug report/feature request. Where can I contact you?</Accordion.Header>
                    <Accordion.Body>
                        You can submit your bug reports and feature requests through our Discord server. Join us by clicking <a target="_blank" href='https://discord.gg/bSTbBA5pMc'>here</a>.
                    </Accordion.Body>
                </Accordion.Item>

            </Accordion>
        </div>
    );
}

export default FAQPage;
