import { BrowserRouter, Routes, Route } from 'react-router-dom';
import React from 'react';
import { useSelector } from 'react-redux';
import HomePage from '../pages/HomePage';
import FavoritesPage from '../pages/FavoritesPage';
import AlbumsPage from '../pages/AlbumsPage';
// import ArtistsPage from '../pages/ArtistsPage';
import StatsPage from '../pages/StatsPage';
import FAQPage from '../pages/FAQPage';
import SettingsPage from '../pages/SettingsPage';
import FavoriteTracksPage from '../pages/FavoritePages/FavoriteTracksPage';
import FavoriteAlbumsPage from '../pages/FavoritePages/FavoriteAlbumsPage';
import FavoriteGamesPage from '../pages/FavoritePages/FavoriteGamesPage';
import FavoriteCollectionsPage from '../pages/FavoritePages/FavoriteCollectionsPage';
// import History from '../pages/FavoritePages/History';
import GamePage from '../pages/GamePage';
import CollectionPage from '../pages/CollectionPage';
import PlayingPage from '../pages/PlayingPage';
import AlbumPage from '../pages/AlbumPage';
import CollectionsPage from '../pages/CollectionsPage';
import NotFoundPage from '../pages/NotFoundPage';
import LeftBar from '../components/LeftBar';
// import RightBar from '../components/RightBar';
import Playbar from '../components/Playbar';
import PlaybarSimple from '../components/PlaybarSimple';
import TopBar from '../components/TopBar';
import TwitchAuthCallback from '../pages/TwitchAuthCallback'

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function AppRouter() {

  const visibleIframe = useSelector(state => state.videoPlayer.visibleIframe)
  const videoId = useSelector((state) => state.videoPlayer.videoId);

  return (
    <BrowserRouter>
      <div className='site-wrapper'>
        <LeftBar />
        {/* <RightBar /> */}
        <TopBar />
        <ToastContainer
          position="bottom-center"
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />

        <div className={'main-content' + (videoId !== -1 && visibleIframe ? " main-content--player-enabled" : "")}>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/settings" element={<SettingsPage />} />
            <Route path="/faq" element={<FAQPage />} />
            <Route path="/albums" element={<AlbumsPage />} />
            <Route path="/stats" element={<StatsPage />} />
            {/* <Route path="/artists" element={<ArtistsPage />} /> */}
            <Route path="/favorites" element={<FavoritesPage />} />
            <Route path="/collections" element={<CollectionsPage />} />
            <Route path="/favorites/tracks" element={<FavoriteTracksPage />} />
            <Route path="/favorites/albums" element={<FavoriteAlbumsPage />} />
            <Route path="/favorites/collections" element={<FavoriteCollectionsPage />} />
            <Route path="/favorites/games" element={<FavoriteGamesPage />} />
            {/* <Route path="/history" element={<History />} /> */}
            <Route path="/playing" element={<PlayingPage />} />
            <Route path="/auth/twitch/callback" element={<TwitchAuthCallback />} />
            <Route
              path='/games/:game_slug/:game_id'
              element={<GamePage />}
            />
            <Route
              path='/collection/:collection_id'
              element={<CollectionPage />}
            />
            <Route
              path='/profiles/:profile_id'
              element={<FavoritesPage
                mode={"visitor"}
              />}
            />
            <Route
              path='/collection/gameCollection/:collection_id'
              element={<CollectionPage />}
            />
            <Route
              path='/albums/:album_slug/:albumId'
              element={<AlbumPage />}
            />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </div>
        <PlaybarSimple />
        <Playbar />
      </div>
    </BrowserRouter>
  );
}

export default AppRouter;
