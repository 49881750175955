import React, { useState, useEffect } from 'react';
import useFunctions from '../components/Functions';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import SongsWrapper from '../components/SongsWrapper';
import AlbumBanner from '../components/AlbumBanner';
import { toast } from 'react-toastify';

function AlbumPage() {

    const [album, setAlbum] = useState(null);
    const user = useSelector((state) => state.user);
    const [multiEditBtnsEnabled, setMultiEditBtnsEnabled] = useState(true)
    const { albumId } = useParams();
    const { getAlbumsById, isURL } = useFunctions();
    const [selectedSongIds, setSelectedSongIds] = useState([]);

    const refreshAlbum = () => {
        getAlbumsById([albumId], 1).then(data => {
            if (data.albums.length > 0) {
                setAlbum(data.albums[0])
            };
        })
    }

    const clearSongEditForms = () => {
        setSelectedSongIds([])
    }

    const toggleSongSelect = (songId, replaceAll) => {
        if (Array.isArray(songId)) { // toggle-all button is clicked case.
            let toggleMode = document.querySelector(".container-settings .option--checkbox").getAttribute("toggle-mode")
            if (toggleMode === "enable") {
                setSelectedSongIds(songId) // songId is actually an array of ALL songIds.
                document.querySelector(".container-settings .option--checkbox").setAttribute("toggle-mode", "disable")
            } else {
                setSelectedSongIds([])
                document.querySelector(".container-settings .option--checkbox").setAttribute("toggle-mode", "enable")
            }
            return true
        }
        else {
            if (!selectedSongIds.includes(songId)) {
                setSelectedSongIds(prevIds => [...prevIds, songId]);
            } else {
                setSelectedSongIds(prevIds => prevIds.filter(id => id !== songId));
            }
        }
    }

    const submitEditForm = (songId) => {
        // su an parametre olarak bunlari aliyor. ilerde albumId ve songId alcak sadece.
        // index sadece hangi form selector olarak kullanilcak bunun icin lazim
        // update ederken formda statedeki indexler kullanilcak,parametredeki degil

        //const formContainer = document.querySelector(".song-edit-form-container--" + index)
        let formContainer

        if (songId === -1) formContainer = document.querySelector(".song-edit-form-container--multi")
        else formContainer = document.querySelector(".song-edit-form-container--" + songId)

        let new_song_title = null
        let song_title_dom = formContainer.querySelector(".song-edit-input--name")
        if (song_title_dom) new_song_title = song_title_dom.value

        const remove_string = formContainer.querySelector(".song-edit-input--remove-string").value
        // const remove_string = ""

        let youtube_link = null
        let youtube_link_dom = formContainer.querySelector(".song-edit-input--youtube")
        if (youtube_link_dom) youtube_link = youtube_link_dom.value
        let youtube_id = null

        if (youtube_link) {
            if (isURL(youtube_link) && youtube_link.includes("youtube.com") && youtube_link.includes("v=")) {
                try {
                    const url = new URL(youtube_link);
                    const urlParams = new URLSearchParams(url.search);
                    youtube_id = urlParams.get('v');
                } catch (e) {

                    toast.error('Something is wrong with the youtube URL', {
                        position: "bottom-center",
                        autoClose: 3500,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });

                }
            }
        }

        const updatedAlbumSongData = {
            song_title: new_song_title,
            youtube_id,
            remove_string
        }

        if (remove_string && new_song_title) {
            toast.error('You can\'t use both of these options at same time', {
                position: "bottom-center",
                autoClose: 3500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return false
        }

        fetch(`${process.env.REACT_APP_API_URL}/update-album-songs`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ updatedAlbumSongData, album_id: album._id, albumSongIds: selectedSongIds, sub: user.user.sub })
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                // console.log('Response data:', data);
                toast.success('Successfully edited.', {
                    position: "bottom-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                clearSongEditForms()
                // refreshGameAlbums(chosenGameAlbum._id)
                refreshAlbum()
            })
            .catch(error => {
                console.error('Error:', error);
                toast.error('Error editing.', {
                    position: "bottom-center",
                    autoClose: 3500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            });
    }

    const toggleSongForm = (songId) => {
        if (Array.isArray(songId)) {
            // songId = selectedSongIds
            // let allIndexes = index.sort() not needed.
            if (songId.length === 0) return false
            let form = document.querySelector(".song-edit-form-container--multi")
            if (form && !form.className.includes("song-edit-form-container--multi--active")) {
                form.className += " song-edit-form-container--multi--active"
            } else {
                form.classList.remove("song-edit-form-container--multi--active")
            }

            return false
        }
        else {
            // single song edit form case
            let activeFormIndex = -1
            document.querySelectorAll(".song-edit-form-container--single").forEach((d, i) => {
                if (d.className.includes("song-edit-form-container--" + songId)) {
                    if (!d.className.includes("song-edit-form-container--active")) {
                        d.className += " song-edit-form-container--active"
                        activeFormIndex = i
                    } else {
                        d.classList.remove("song-edit-form-container--active")
                    }
                }
                else {
                    d.classList.remove("song-edit-form-container--active")
                }
            })

            // when one form is active, disable clicking other checkboxes
            const songs_container = document.querySelector(".songs-container")
            if (activeFormIndex > -1) {
                songs_container.querySelectorAll(".option--checkbox").forEach((d, i) => {
                    if (activeFormIndex !== i) {
                        if (!d.className.includes("option-blocked")) d.className += " option-blocked"
                    }
                })
            } else if (activeFormIndex === -1) {
                // no active form. activate all checkboxes again
                songs_container.querySelectorAll(".option--checkbox").forEach((d, i) => {
                    if (d.className.includes("option-blocked")) d.classList.remove("option-blocked")
                })
            }
        }

        // currently editing single song, so disabling multi-option btns
        setMultiEditBtnsEnabled(false)

    }

    useEffect(() => {
        if (user.user.logged !== undefined) {
            getAlbumsById([albumId], 1).then(data => {
                if (data.albums.length > 0) {
                    setAlbum(data.albums[0])
                };
            })
        }
    }, [user.user.logged])

    return (
        <div>
            <div className='holder'>
                {album && <AlbumBanner album={album} />}
            </div>
            {album &&
                <SongsWrapper
                    songs={album.album_songs || []}
                    album={album}
                    selectedSongIds={selectedSongIds}
                    submitEditForm={submitEditForm}
                    toggleSongForm={toggleSongForm}
                    gameName={album.game_name}
                    setSelectedSongIds={setSelectedSongIds}
                    toggleSongSelect={toggleSongSelect}
                    albumType="album"
                    fromGamePage={true}
                    multiEditBtnsEnabled={multiEditBtnsEnabled}
                    setMultiEditBtnsEnabled={setMultiEditBtnsEnabled}
                />
            }
        </div>
    );
}

export default AlbumPage;
