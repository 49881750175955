import React from 'react';
import { useDrag, useDrop } from 'react-dnd';


function AlbumWrapperSimplified(props) {

    const { index, album, moveAlbum } = props;

    const [, drag] = useDrag({
        type: 'ALBUM',
        item: { id: album._id, index },
    });

    const [, drop] = useDrop({
        accept: 'ALBUM',
        hover: (draggedItem) => {
            if (draggedItem.index !== index) {
                moveAlbum(draggedItem.index, index);
                draggedItem.index = index;
            }
        },
    });


    return (

        <div className="album-container-simplified "
            ref={(node) => drag(drop(node))}

            style={{
                cursor: 'move', // Set cursor to 'move' while dragging
            }}

        >

            <svg xmlns="http://www.w3.org/2000/svg" width="30px" height="30px" viewBox="0 0 24 24" fill="none">
                <g id="Interface / Drag_Vertical">
                    <g id="Vector">
                        <path d="M14 18C14 18.5523 14.4477 19 15 19C15.5523 19 16 18.5523 16 18C16 17.4477 15.5523 17 15 17C14.4477 17 14 17.4477 14 18Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M8 18C8 18.5523 8.44772 19 9 19C9.55228 19 10 18.5523 10 18C10 17.4477 9.55228 17 9 17C8.44772 17 8 17.4477 8 18Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M14 12C14 12.5523 14.4477 13 15 13C15.5523 13 16 12.5523 16 12C16 11.4477 15.5523 11 15 11C14.4477 11 14 11.4477 14 12Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M8 12C8 12.5523 8.44772 13 9 13C9.55228 13 10 12.5523 10 12C10 11.4477 9.55228 11 9 11C8.44772 11 8 11.4477 8 12Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M14 6C14 6.55228 14.4477 7 15 7C15.5523 7 16 6.55228 16 6C16 5.44772 15.5523 5 15 5C14.4477 5 14 5.44772 14 6Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M8 6C8 6.55228 8.44772 7 9 7C9.55228 7 10 6.55228 10 6C10 5.44772 9.55228 5 9 5C8.44772 5 8 5.44772 8 6Z" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </g>
                </g>
            </svg>

            <div className="album-title-text">
                {index + 1}. {album.album_name}
            </div>
        </div>
    );
}


export default AlbumWrapperSimplified;