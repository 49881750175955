function NotFoundPage() {
    return (
      <div style={{marginTop: "40px"}}>
        <h1>404 Page Not Found</h1>
        <p>The page you are looking for does not exist.</p>
      </div>
    );
  }
  
  export default NotFoundPage;
  