import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import AlbumWrapper from './AlbumWrapper';
import useFunctions from './Functions';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import AlbumWrapperSimplified from './AlbumWrapperSimplified';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';


function AlbumsWrapper(props) {

    const popupRef = useRef(null);
    const { setBrowseOwned, albumQueryPage, setAlbumQueryPage, setCollectionAlbums, fromCollection, refreshAlbums, albums, setAddAlbumPopupVisible, fromFavorites, totalResultsCount } = props;
    const [activeButton, setActiveButton] = useState('likedAlbums'); // Default to 'myAlbums'
    const [activeAlbumPopup, setActiveAlbumPopup] = useState(-1);
    const [editingAlbumId, setEditingAlbumId] = useState(null);
    const [editAlbumPopupVisible, setEditAlbumPopupVisible] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null); // needed for updating album image
    const imageRef = useRef(null); // needed for updating album image
    const account = useSelector((state) => state.account);
    const user = useSelector((state) => state.user);
    const { mixAlbumResults, getAccountCollections } = useFunctions();
    const [editMode, setEditMode] = useState(null);
    const [editAlbumName, setEditAlbumName] = useState("")
    const [editAlbumDescription, setEditAlbumDescription] = useState("")
    const fileInputRef = useRef(null);
    const [isCollectionPopup, setIsCollectionPopup] = useState(false);
    const [accountCollections, setAccountCollections] = useState([]);
    const [isOrderMode, setIsOrderMode] = useState(false);


    const location = useLocation();
    const isCollectionPage = location.pathname.includes('/collection/');


    const handleButtonClick = (buttonType) => {
        setActiveButton(buttonType);
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Escape') {
            resetEditForm()
        }
    };

    useEffect(() => {
        if (user.user.sub && user.user.email) {
            getAccountCollections().then(collections => {
                setAccountCollections(collections)
            })
        }
    }, [user.user.sub, user.user.email])

    useEffect(() => {

        /* adding esc key listener for closing forms */
        document.addEventListener('keydown', handleKeyPress);
        /* esc listener end */

        const handleOutsideClick = (event) => {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                // Click occurred outside the popup (excluding the form inside it)
                setActiveAlbumPopup(-1)
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);


    const handleAlbumEdit = async (e) => {
        e.preventDefault()

        const editFormData = new FormData();
        let updatedAlbumId = editingAlbumId
        setEditingAlbumId(null) // reset

        editFormData.append("updatedAlbumId", updatedAlbumId);
        editFormData.append("sub", user.user.sub);

        let correctAlbumName = editAlbumName
        if (correctAlbumName.trim() !== "") {
            editFormData.append("album_name", correctAlbumName);
        }

        let correctAlbumDescription = editAlbumDescription
        if (correctAlbumDescription.trim() !== "") {
            editFormData.append("album_description", correctAlbumDescription);
        }

        if (selectedImage) {
            editFormData.append("album_cover_image", selectedImage);
        }

        let responseStatus;
        fetch(`${process.env.REACT_APP_API_URL}/update-album-infos`, {
            method: 'POST',
            // body: JSON.stringify({ updatedAlbumId, updatedData, sub: user.user.sub }),
            body: editFormData,
        })
            .then(response => {
                responseStatus = response.status
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                // console.log('Response data:', data);
                toast.success('Album successfully edited.', {
                    position: "bottom-center",
                    autoClose: 3500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });

                if (responseStatus === 200) {
                    // fetching again - (will act different based on page)
                    refreshAlbums();
                }

            })
            .catch(error => {
                console.error('Error:', error);
                toast.error('Error editing album.', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            });

        resetEditForm()
    }

    const handleImageFile = (file) => {
        setSelectedImage(file); // just for testing, maybe thats enough
        const reader = new FileReader();
        reader.onload = () => {
            // Display the dropped or pasted image
            imageRef.current.src = reader.result;
        };
        reader.readAsDataURL(file);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];

        if (file && file.type.startsWith('image/')) {
            handleImageFile(file);
        }
    };

    const handlePaste = (e) => {
        const items = e.clipboardData.items;

        for (let i = 0; i < items.length; i++) {
            if (items[i].type.indexOf('image') !== -1) {
                const blob = items[i].getAsFile();
                handleImageFile(blob);
                break;
            }
        }
    };

    const handleFileSelect = (e) => {
        const file = e.target.files[0];

        if (file && file.type.startsWith('image/')) {
            handleImageFile(file);
        }
    };

    const handleEditAlbumNameChange = (event) => {
        setEditAlbumName(event.target.value);
    };

    const handleEditAlbumDescriptionChange = (event) => {
        setEditAlbumDescription(event.target.value);
    };

    const resetEditForm = () => {
        setEditAlbumPopupVisible(false)
        setEditingAlbumId(null)
        setEditMode(null)
        setEditAlbumName("")
        setSelectedImage(null)
    }

    const moveAlbum = (dragIndex, hoverIndex) => {
        setCollectionAlbums((prevAlbums) => {
            const newAlbums = [...prevAlbums];
            const draggedAlbum = newAlbums[dragIndex];

            // Swap positions
            newAlbums[dragIndex] = newAlbums[hoverIndex];
            newAlbums[hoverIndex] = draggedAlbum;

            return newAlbums;
        });
    };

    const submitNewOrder = () => {
        const editFormData = new FormData();
        editFormData.append("updatedCollectionId", fromCollection._id);
        editFormData.append("sub", user.user.sub);
        let newIds = albums.map(e => e._id)
        editFormData.append("updated_collection_ids", JSON.stringify(newIds));
        let responseStatus;
        fetch(`${process.env.REACT_APP_API_URL}/update-collection-infos`, {
            method: 'POST',
            // body: JSON.stringify({ updatedAlbumId, updatedData, sub: user.user.sub }),
            body: editFormData,
        })
            .then(response => {
                responseStatus = response.status
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                // console.log('Response data:', data);
                toast.success('Order successfully updated.', {
                    position: "bottom-center",
                    autoClose: 3500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });

                if (responseStatus === 200) {
                    setIsOrderMode(false)
                }

            })
            .catch(error => {
                console.error('Error:', error);
                toast.error('Error editing collection.', {
                    position: "bottom-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            });
    }

    const onPageChange = (newPageNum) => {
        setAlbumQueryPage(newPageNum)
    }

    return (
        <div>
            {!isOrderMode &&
                <div className='albums-wrapper'>

                    {/* {albums.length === 0 &&
                <p className='no-albums' style={{ "textAlign": "left" }}>No albums found.</p>
            } */}

                    {albums.length > 0 &&
                        <h1 className='game-albums'>Albums</h1>
                    }

                    {albums.length > 0 &&
                        <div className='common-form-btns'>
                            <button onClick={() => mixAlbumResults(albums)}>Mix Results</button>
                            {fromCollection && fromCollection.canEditCollection && <button className='mobile-hidden' onClick={() => setIsOrderMode(!isOrderMode)}>Edit Order</button>}
                            {fromFavorites && <button
                                className={activeButton === 'likedAlbums' ? '' : 'not-selected-btn'}
                                onClick={() => {
                                    handleButtonClick('likedAlbums')
                                    setBrowseOwned(false)
                                }}>Liked Albums</button>}
                            {fromFavorites && <button
                                className={activeButton === 'myAlbums' ? '' : 'not-selected-btn'}
                                onClick={() => {
                                    handleButtonClick('myAlbums')
                                    setBrowseOwned(true)
                                }}>My Albums</button>}
                        </div>
                    }

                    <div className='albums-container'>
                        {albums.length > 0 && albums.map((album, index) => {
                            return (
                                <AlbumWrapper
                                    key={index}
                                    // setChosenGameAlbum={setChosenGameAlbum}
                                    album={album}
                                    account={account}
                                    index={index}
                                    activeAlbumPopup={activeAlbumPopup}
                                    popupRef={popupRef}
                                    setEditAlbumPopupVisible={setEditAlbumPopupVisible}
                                    setEditingAlbumId={setEditingAlbumId}
                                    setActiveAlbumPopup={setActiveAlbumPopup}
                                    fromFavorites={fromFavorites}
                                    refreshAlbums={refreshAlbums}
                                    isCollectionPopup={isCollectionPopup}
                                    setIsCollectionPopup={setIsCollectionPopup}
                                    accountCollections={accountCollections}
                                    fromCollection={fromCollection}
                                />
                            )
                        })}
                        {setAddAlbumPopupVisible && account && account.account && account.account.username &&
                            <div className='album-container album-container--empty' onClick={() => setAddAlbumPopupVisible(true)}>
                                <div className='album-cover-container' >
                                    <div>+</div>
                                </div>
                                <div className='album-infos-container'>
                                    <div className='album-title' > </div>
                                </div>
                            </div>
                        }
                    </div>


                    {albums.length > 0 && totalResultsCount > 1 &&
                        <div className='pagination-container'>
                            <button className='pagination-btn' onClick={() => onPageChange(albumQueryPage - 1)} disabled={albumQueryPage === 1}>
                                Previous
                            </button>
                            <button className='pagination-btn' onClick={() => onPageChange(albumQueryPage + 1)} disabled={albumQueryPage >= totalResultsCount}>
                                Next
                            </button>
                        </div>
                    }


                    {editAlbumPopupVisible &&
                        <form className='edit-album-form edit-component-form popup-form' onSubmit={handleAlbumEdit}>

                            {!editMode &&
                                <div className='edit-choose-btns'>
                                    <button onClick={() => setEditMode("albumName")}>Edit album name</button>
                                    <button onClick={() => setEditMode("albumCover")}>Edit album cover</button>
                                    <button onClick={() => setEditMode("albumDescription")}>Edit album description</button>
                                </div>
                            }

                            <div className={`form-group ${editMode !== "albumName" ? 'hidden' : ''}`}>
                                <label htmlFor="correctAlbumName">Album name: </label>
                                <input onChange={handleEditAlbumNameChange} value={editAlbumName} type="text" autoComplete="off" className="form-control" id="correctAlbumName" placeholder="Correct album name (optional)" />
                            </div>

                            <div className={`form-group ${editMode !== "albumCover" ? 'hidden' : ''}`}>
                                <label htmlFor="correctAlbumCoverImage" style={{ display: "block" }}>Album Cover Image:
                                </label>

                                {!selectedImage &&
                                    <div className='drag-image-wrapper'>
                                        <div
                                            className='drag-info-container'
                                            onDrop={handleDrop}
                                            onPaste={handlePaste}
                                            onDragOver={handleDragOver}
                                            style={{ border: '2px dashed #ccc' }}
                                        >
                                            <div>Drag and drop an image OR paste from clipboard OR manually select from your computer</div>
                                        </div>
                                        <div className='manual-file-select-container'>
                                            <input
                                                type="file"
                                                accept="image/*"
                                                onChange={handleFileSelect}
                                                ref={fileInputRef}
                                            />
                                        </div>
                                    </div>
                                }

                                {selectedImage &&
                                    <img
                                        ref={imageRef}
                                        alt="Dropped or Pasted"
                                        style={{ maxWidth: '100%', maxHeight: '190px', margin: '1px auto' }}
                                    />
                                }

                            </div>

                            <div className={`form-group ${editMode !== "albumDescription" ? 'hidden' : ''}`}>
                                <label htmlFor="correctAlbumDescription">Album description: </label>
                                <textarea
                                    value={editAlbumDescription}
                                    onChange={handleEditAlbumDescriptionChange}
                                    rows={4} // Set the number of visible rows
                                    cols={50} // Set the number of visible columns
                                    placeholder="Enter the album description..."
                                    className='form-control'
                                    id="correctAlbumDescription"
                                />

                            </div>

                            <div className='common-form-btns'>

                                {editMode &&
                                    <button type="button" className='previous-step' onClick={() => {
                                        setEditMode(null)
                                    }}>Previous</button>
                                }

                                {editMode === "albumCover" && selectedImage &&
                                    <button type="button" onClick={() => {
                                        setSelectedImage(null)
                                    }}>Remove Image</button>
                                }

                                {editMode &&
                                    <button type="submit" className={`${(editAlbumName || selectedImage || editAlbumDescription) ? '' : 'option-blocked'}`}>Submit</button>
                                }

                                <button onClick={() => {
                                    resetEditForm()
                                }} >Cancel</button>
                            </div>
                        </form>
                    }

                </div>
            }

            {isOrderMode && isCollectionPage &&
                <DndProvider backend={HTML5Backend}>

                    <div className="common-form-btns" style={{ position: "relative", marginTop: "40px" }}>
                        {<button onClick={() => setIsOrderMode(!isOrderMode)}>Cancel</button>}
                        {<button onClick={() => submitNewOrder()}>Submit New Order</button>}
                    </div>

                    <div className='albums-wrapper-simplified' >

                        {albums.map((album, index) => (
                            <AlbumWrapperSimplified
                                album={album}
                                index={index}
                                popupRef={popupRef}
                                accountCollections={accountCollections}
                                isCollectionPage={isCollectionPage}
                                fromCollection={fromCollection}
                                moveAlbum={moveAlbum}
                                key={album._id}
                            />
                        ))}

                    </div>
                </DndProvider>
            }
        </div>
    );
}


export default AlbumsWrapper;