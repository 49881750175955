import { useEffect, useRef, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setVideoId } from '../actions/videoPlayer';
import { toggleSongLikeStatus, toggleAlbumLikeStatus, toggleCollectionLikeStatus, toggleGameLikeStatus, updateListeningHistory } from '../actions/account';
import { setIsPaused, setVolume } from '../actions/playback';
import { useVideoRef } from '../context/videoContext';
import { playNextSong, playPreviousSong } from '../actions/playlist';
import { toast } from 'react-toastify';


const useFunctions = () => {

    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);
    const playerRef = useRef(null);
    const videoRef = useVideoRef();
    const playingSongObject = useSelector(state => state.playback.playingSongObject)
    const shuffleEnabled = useSelector(state => state.playback.shuffleEnabled)
    const [latestVolume, setLatestVolume] = useState(0);

    const currentSongIndex = useSelector((state) => state.playlist.currentSongIndex);
    const playlist = useSelector((state) => state.playlist);


    /* this causes playsong to called twice , not sure if still */
    const useSongEffect = () => {
        useEffect(() => {
            if (currentSongIndex !== -1 && playlist.playlistData) {
                const playMode = playlist.playlistData.playMode;

                if (playMode === "playAlbum" || playMode === "singlePlay") return // these already has their own playSong handler
                playSong({
                    vidObject: playlist.playlistData.album_songs[currentSongIndex],
                    gameName: playlist.playlistData.game_name,
                    albumData: playlist.playlistData,
                    songIndex: currentSongIndex,
                    game_id: playlist.playlistData.game_id // not sure
                });
            }
        }, [currentSongIndex, playlist.playlistData]);
    };


    const shuffle = (array) => {
        // Create a copy of the original array to avoid modifying the original array
        const shuffledArray = [...array];

        // Fisher-Yates shuffle algorithm
        for (let i = shuffledArray.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
        }

        return shuffledArray;
    }

    const truncateString = (inputString, maxLength) => {
        if (!inputString) return ""
        if (inputString.length <= maxLength) {
            return inputString;
        } else {
            return inputString.slice(0, maxLength - 3) + '...';
        }
    }

    const addToHistory = async (song) => {
        return false
        delete song.album_songs;
        // song should have all the props.
        const userEmail = user.user.email
        fetch(`${process.env.REACT_APP_API_URL}/add-to-history`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ song, userEmail, sub: user.user.sub })
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                // console.log('Response data:', data);
                dispatch(updateListeningHistory(song))
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }

    const cacheLastPlayed = (object) => {
        const { cachedPlayedGameTitle, cachedPlayedSongTitle, cachedPlayedYoutubeId, cachedPlayedGameId } = object
        if (cachedPlayedGameTitle && cachedPlayedSongTitle && cachedPlayedYoutubeId) {
            localStorage.setItem("cachedPlayedGameTitle", cachedPlayedGameTitle)
            localStorage.setItem("cachedPlayedSongTitle", cachedPlayedSongTitle)
            localStorage.setItem("cachedPlayedYoutubeId", cachedPlayedYoutubeId)
            localStorage.setItem("cachedPlayedGameId", cachedPlayedGameId)
        }
    }

    const playSong = (object) => {

        let cachedPlayedGameTitle
        let cachedPlayedSongTitle
        let cachedPlayedYoutubeId
        let cachedPlayedGameId

        // console.log('Play song is called!', object);

        // songlist will only used if its not coming from an official album. ie. liked, user playlists
        let { vidObject, gameName, albumData, songIndex, songList, togglePause, playMode } = object

        cachedPlayedSongTitle = vidObject.song_title
        cachedPlayedYoutubeId = vidObject.youtube_id
        if (albumData) {
            cachedPlayedGameId = albumData.game_id
        }
        // if played from game mix, this will be empty, prop should come from song itself.
        else {
            cachedPlayedGameId = vidObject.game_id // not sure
        }

        if (togglePause) {
            // if you see react error, thats because video file is faulty. handle it later.
            const player = videoRef.current;
            if (player) {
                if (player.getPlayerState() === 1) {
                    player.pauseVideo();
                    dispatch(setIsPaused(true));
                } else {
                    player.playVideo();
                    dispatch(setIsPaused(false));
                }
            }
            return true
        } else {
            dispatch(setIsPaused(false));
        }

        dispatch(setVideoId(undefined));
        setTimeout(() => {
            dispatch(setVideoId(vidObject.youtube_id)); // starts the song play only.
        }, 200)

        dispatch({ type: 'SET_SONG_TITLE', payload: vidObject.song_title });

        /* this causes the change when clicked playAlbum, so playSong is called twice */

        if (albumData) {
            albumData.playMode = playMode
            dispatch({ type: 'SET_PLAYING_SONG_OBJECT', payload: { ...vidObject, ...albumData } });
            dispatch({ type: 'SET_PLAYLIST', playlistSongs: albumData, currentSongIndex: songIndex }); // this line
            // addToHistory({ ...vidObject, ...albumData })
        } else {
            dispatch({ type: 'SET_PLAYING_SONG_OBJECT', payload: vidObject });
            // addToHistory(vidObject)

            // queue/playlist stuff (when played from liked/mix) albumData wont exist by default. re-creating.
            let albumData = {
                album_name: "CUSTOM ALBUM NAME",
                album_songs: songList, // here, I AT LEAST need this. songList = user mix, liked songs etc.
                playMode
            }
            dispatch({ type: 'SET_PLAYLIST', playlistSongs: albumData, currentSongIndex: songIndex, playMode });
        }

        if (gameName) { // played from game page albums
            dispatch({ type: 'SET_GAME_TITLE', payload: gameName });
            cachedPlayedGameTitle = gameName
        } else { // played from favorites or some custom playlist.
            dispatch({ type: 'SET_GAME_TITLE', payload: vidObject.game_name });
            cachedPlayedGameTitle = vidObject.game_name
        }

        // fake - reset playback progress instantly
        document.querySelectorAll("#progress-bar").forEach(d => d.style.width = 0)
        document.querySelectorAll("#current-duration").forEach(d => d.textContent = "0:00")
        document.querySelectorAll("#total-duration").forEach(d => d.textContent = " ")

        cacheLastPlayed({
            cachedPlayedGameTitle,
            cachedPlayedSongTitle,
            cachedPlayedYoutubeId,
            cachedPlayedGameId
        })
    }

    const mixGameResults = async (game_ids) => {
        const data = await getGameSongs(game_ids)
        const gameSongs = data.gameSongs;
        if (gameSongs.length === 0) {
            toast.info('No music found for current results.', {
                position: "bottom-center",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return (false)
        }

        let albumData = {
            // album_name: "Results Mix", 
            album_songs: shuffleEnabled ? shuffle(gameSongs).slice(0, 40) : gameSongs.slice(0, 40)
        }

        dispatch({ type: 'SET_PLAYLIST', playlistSongs: albumData, currentSongIndex: 0 });
        return true

    }

    const mixAlbumResults = async (albums) => {
        let allSongs = []

        // Iterate over all albums
        albums.forEach(album => {
            album.album_songs.forEach(song => {
                const songWithAdditionalProps = {
                    ...song,
                    game_name: album.game_name,
                    game_id: album.game_id,
                    release_date: album.release_date,
                    album_name: album.album_name
                };
                allSongs.push(songWithAdditionalProps);
            });
        });

        if (allSongs.length === 0) {
            toast.info('No music found for current album results.', {
                position: "bottom-center",
                autoClose: 2500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return (false)
        }

        let albumData = {
            // album_name: "Results Mix", 
            album_songs: shuffleEnabled ? shuffle(allSongs).slice(0, 40) : allSongs.slice(0, 40)
        }

        dispatch({ type: 'SET_PLAYLIST', playlistSongs: albumData, currentSongIndex: 0 });
        return true
    }

    const getGameSongs = async (game_ids) => {

        return new Promise((resolve, reject) => {
            fetch(`${process.env.REACT_APP_API_URL}/get-game-songs`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ game_ids })
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then(data => {
                    resolve({ gameSongs: data.gameSongs });
                })
                .catch(error => {
                    console.error('Error:', error);
                    toast.error('Error getting game songs.', {
                        position: "bottom-center",
                        autoClose: 3500,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                });
        })
    }

    const getAlbumsSongs = async (album_ids) => {

        return new Promise((resolve, reject) => {
            fetch(`${process.env.REACT_APP_API_URL}/get-albums-songs`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ album_ids })
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then(data => {
                    resolve({ albumsSongs: data.albumsSongs });
                })
                .catch(error => {
                    console.error('Error:', error);
                    toast.error('Error getting albums songs.', {
                        position: "bottom-center",
                        autoClose: 3500,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                    });
                });
        })
    }

    const playGameMusic = async (game_id) => {
        const data = await getGameSongs([game_id]) // function supports multi only
        const gameSongs = data.gameSongs; // array, but only 2 props thats it.
        if (gameSongs.length === 0) {
            toast.info('No music found for this game.', {
                position: "bottom-center",
                autoClose: 3500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
            return (false)
        }
        let albumData = {
            // album_name: "Game Mix", 
            album_songs: shuffleEnabled ? shuffle(gameSongs) : gameSongs // here, I AT LEAST need this. songList = user mix, liked songs etc.
        }

        dispatch({ type: 'SET_PLAYLIST', playlistSongs: albumData, currentSongIndex: 0 });
        return true
    }

    const playCollection = async (collection) => {
        if (collection.collection_type === "game") {
            const data = await getGameSongs(collection.collection_game_ids) // function supports multi only
            const collectionSongs = data.gameSongs; // array, but only 2 props thats it.
            if (collectionSongs.length === 0) {
                toast.info('No music found for this collection.', {
                    position: "bottom-center",
                    autoClose: 3500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                return (false)
            }
            let albumData = {
                // album_name: "Game Mix", 
                album_songs: shuffleEnabled ? shuffle(collectionSongs) : collectionSongs // here, I AT LEAST need this. songList = user mix, liked songs etc.
            }

            dispatch({ type: 'SET_PLAYLIST', playlistSongs: albumData, currentSongIndex: 0 });
            return true
        } else if (collection.collection_type === "album") {
            const data = await getAlbumsSongs(collection.collection_album_ids) // function supports multi only
            const collectionSongs = data.albumsSongs; // array, but only 2 props thats it. (?)
            if (collectionSongs.length === 0) {
                toast.info('No music found for this collection.', {
                    position: "bottom-center",
                    autoClose: 3500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
                return (false)
            }
            let albumData = {
                // album_name: "Game Mix", 
                album_songs: shuffleEnabled ? shuffle(collectionSongs) : collectionSongs // here, I AT LEAST need this. songList = user mix, liked songs etc.
            }

            dispatch({ type: 'SET_PLAYLIST', playlistSongs: albumData, currentSongIndex: 0 });
            return true
        }
    }

    const playAlbum = (albumData) => {
        // console.log("albumData:", albumData);
        // dispatch({ type: 'SET_PLAYLIST', playlistSongs: albumData, currentSongIndex: 0 });
        // console.log("Album play started: ", albumData)
        if (!shuffleEnabled) {
            playSong({ vidObject: albumData.album_songs[0], game_id: albumData.game_id, gameName: albumData.game_name, albumData, songIndex: 0, playMode: "playAlbum" })
        } else {
            albumData.album_songs = shuffle(albumData.album_songs)
            playSong({ vidObject: albumData.album_songs[0], game_id: albumData.game_id, gameName: albumData.game_name, albumData, songIndex: 0, playMode: "playAlbum" })
        }

    }

    const likeAlbum = (albumId) => {
        const userEmail = user.user.email
        fetch(`${process.env.REACT_APP_API_URL}/user-toggle-album-like`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ albumId, userEmail, sub: user.user.sub })
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                // console.log('Response data:', data);
                dispatch(toggleAlbumLikeStatus(albumId));
            })
            .catch(error => {
                console.error('Error:', error);
                toast.error('Error liking/disliking album.', {
                    position: "bottom-center",
                    autoClose: 3500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            });
    }

    const likeCollection = (collectionId) => {
        const userEmail = user.user.email
        fetch(`${process.env.REACT_APP_API_URL}/user-toggle-collection-like`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ collectionId, userEmail, sub: user.user.sub })
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                // console.log('Response data:', data);
                dispatch(toggleCollectionLikeStatus(collectionId));
            })
            .catch(error => {
                console.error('Error:', error);
                toast.error('Error liking/disliking collection.', {
                    position: "bottom-center",
                    autoClose: 3500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            });
    }

    const likeGame = (gameId) => {
        const userEmail = user.user.email
        fetch(`${process.env.REACT_APP_API_URL}/user-toggle-game-like`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ userEmail, gameId, sub: user.user.sub })
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                // console.log('Response data:', data);
                dispatch(toggleGameLikeStatus(gameId));
            })
            .catch(error => {
                console.error('Error:', error);
                toast.error('Error liking/disliking game.', {
                    position: "bottom-center",
                    autoClose: 3500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            });
    }

    const getAlbumsById = async (albumIds, albumQueryPage) => {
        return new Promise((resolve, reject) => {

            fetch(`${process.env.REACT_APP_API_URL}/get-albums-by-id`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ albumIds, page: albumQueryPage, sub: user.user.sub })
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then(data => {
                    // console.log('Response data:', albums);
                    resolve(data) // returns totalResults and albums
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        })
    }

    const getGamesById = async (gameIds, gameQueryPage) => {
        return new Promise((resolve, reject) => {

            fetch(`${process.env.REACT_APP_API_URL}/get-games-by-id`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ gameIds, page: gameQueryPage, sub: user.user.sub })
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then(data => {
                    // console.log('Response data:', albums);
                    resolve(data) // includes totalResultsCount and games
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        })
    }

    const getCollectionsById = async (collectionIds, page) => {
        return new Promise((resolve, reject) => {

            fetch(`${process.env.REACT_APP_API_URL}/get-collections-by-id`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ collectionIds, page, sub: user.user.sub })
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then(data => {
                    // console.log('Response data:', collections);
                    console.log(data);
                    resolve(data) // includes collections and totalResultsCount
                })
                .catch(error => {
                    console.error('Error:', error);
                });
        })
    }

    const likeSong = (vidObject, chosenGameAlbum) => {

        let likedSongObject

        // when liked from main component
        if (vidObject) {
            if (chosenGameAlbum) {
                likedSongObject = {
                    song_title: vidObject.song_title,
                    youtube_id: vidObject.youtube_id,
                    uuid: vidObject.uuid,
                    fromAlbum: vidObject.fromAlbum,
                    fromVersionId: vidObject.fromVersionId,
                    album_name: chosenGameAlbum.album_name,
                    game_id: chosenGameAlbum.game_id,
                    game_name: chosenGameAlbum.game_name,
                    release_date: chosenGameAlbum.release_date,
                }
            } else {
                likedSongObject = {
                    song_title: vidObject.song_title,
                    youtube_id: vidObject.youtube_id,
                    uuid: vidObject.uuid,
                    fromAlbum: vidObject.fromAlbum,
                    fromVersionId: vidObject.fromVersionId,
                    album_name: vidObject.album_name,
                    game_id: vidObject.game_id,
                    game_name: vidObject.game_name,
                    release_date: vidObject.release_date,

                }
            }
        } else {
            // liked from Playbar component
            likedSongObject = (({ song_title, youtube_id, uuid, fromAlbum, fromVersionId, album_name, game_id, game_name, release_date }) => ({ song_title, youtube_id, uuid, fromAlbum, fromVersionId, album_name, game_id, game_name, release_date }))(playingSongObject);
        }

        const userEmail = user.user.email
        fetch(`${process.env.REACT_APP_API_URL}/user-toggle-song-like`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ song: likedSongObject, userEmail, sub: user.user.sub })
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                // console.log('Response data:', data);
                // update instantly account_liked_songs
                dispatch(toggleSongLikeStatus(likedSongObject));
            })
            .catch(error => {
                console.error('Error:', error);
                toast.error('Error liking/disliking song.', {
                    position: "bottom-center",
                    autoClose: 3500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            });
    }

    const toggleMute = (event) => {
        let id = event.target.id
        if (id === "mute-btn-wrapper") {
            let volume = 50 // default volume, just in case video is not available
            if (videoRef.current && !Number.isInteger(videoRef.current)) {
                volume = videoRef.current.getVolume();
            }
            if (volume === 0) {
                dispatch(setVolume(latestVolume));
                if (videoRef.current && !Number.isInteger(videoRef.current)) {
                    videoRef.current.setVolume(latestVolume.toString());
                }
            } else {
                setLatestVolume(volume);
                dispatch(setVolume(0));
                if (videoRef.current && !Number.isInteger(videoRef.current)) {
                    videoRef.current.setVolume("0");
                }
            }
        }
    }

    const togglePlayback = () => {

        // page loaded and clicked play case - load song from cache
        if (!playingSongObject || !playingSongObject.youtube_id) {
            let cachedPlayedGameTitle = localStorage.getItem("cachedPlayedGameTitle")
            let cachedPlayedSongTitle = localStorage.getItem("cachedPlayedSongTitle")
            let cachedPlayedYoutubeId = localStorage.getItem("cachedPlayedYoutubeId")
            let cachedPlayedGameId = localStorage.getItem("cachedPlayedGameId")

            let vidObject = {
                song_title: cachedPlayedSongTitle,
                game_name: cachedPlayedGameTitle,
                youtube_id: cachedPlayedYoutubeId,
                game_id: cachedPlayedGameId
            }
            if (cachedPlayedGameTitle && cachedPlayedSongTitle && cachedPlayedYoutubeId) {
                dispatch(setVideoId(cachedPlayedYoutubeId)); // starts the song play only.
                dispatch({ type: 'SET_PLAYING_SONG_OBJECT', payload: vidObject });
                dispatch(setIsPaused(false));
                return false // playingObject is loaded from cache.
            }
        }

        const player = videoRef.current;
        if (player && typeof (player) !== "number") {
            if (player.getPlayerState() === 1) { // video is playing case
                // If video is currently playing, pause it
                player.pauseVideo();
                dispatch(setIsPaused(true));

            } else {
                // If video is paused or ended, play it
                player.playVideo();
                dispatch(setIsPaused(false));
            }
        }
    };


    let throttleTimeoutNext;
    const handleNextClick = useCallback(() => {
        const delay = 2500; // Throttle time in milliseconds
        if (!throttleTimeoutNext) {
            dispatch(playNextSong());
            throttleTimeoutNext = setTimeout(() => {
                throttleTimeoutNext = null;
            }, delay);
        }
    }, [dispatch]);


    let throttleTimeoutPrevious;
    const handlePreviousClick = useCallback(() => {
        const delay = 2500; // Throttle time in milliseconds
        if (!throttleTimeoutPrevious) {
            dispatch(playPreviousSong());
            throttleTimeoutPrevious = setTimeout(() => {
                throttleTimeoutPrevious = null;
            }, delay);
        }
    }, [dispatch]);


    const handleVolumeChange = (event) => {
        const newVolume = event.target.value;
        dispatch(setVolume(newVolume));
        if (videoRef.current && !Number.isInteger(videoRef.current)) {
            videoRef.current.setVolume(newVolume);
        }
    };


    const removeFromCollection = async (albumId, collectionId, collectionType) => {
        let answer = window.confirm("Remove from collection?")
        if (!answer) return false
        fetch(`${process.env.REACT_APP_API_URL}/remove-from-collection`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ albumId, collectionId, collectionType, sub: user.user.sub, email: user.user.email })
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                // console.log('Response data:', data);
                let str = collectionType === "game" ? "Game" : "Album"
                toast.success(str + ' is removed from collection.', {
                    position: "bottom-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }

    const addToCollection = async (albumId, collectionId, collectionType) => {

        fetch(`${process.env.REACT_APP_API_URL}/add-to-collection`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ albumId, collectionId, collectionType, sub: user.user.sub, email: user.user.email })
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                // console.log('Response data:', data);
                let str = collectionType === "game" ? "Game" : "Album"
                toast.success(str + ' is added to collection.', {
                    position: "bottom-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            })
            .catch(error => {
                console.error('Error:', error);
            });

    }

    const getAccountCollections = async () => {
        return new Promise((resolve, reject) => {
            fetch(`${process.env.REACT_APP_API_URL}/get-account-collections`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ email: user.user.email, sub: user.user.sub })
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then(data => {
                    // console.log('returned collections: ', data.collections);
                    resolve(data.collections)
                })

        })

    }

    const renderTextWithLineBreaks = (descriptionString) => (
        descriptionString.split("\r\n").map((line, index) => (
            <div className='line' key={index}>
                {line}
                <br />
            </div>
        ))
    );

    function isURL(str) {
        try {
            new URL(str);
            return true;
        } catch (error) {
            return false;
        }
    }

    const debounce = (func, delay) => {
        let timeoutId;

        return (...args) => {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
                func(...args);
            }, delay);
        };
    };

    const getShortDate = (unixTimestamp) => {
        const now = new Date();
        const uploadDate = new Date(unixTimestamp); // Keep Unix timestamp in milliseconds

        const timeDifferenceInSeconds = Math.floor((now - uploadDate) / 1000);

        if (timeDifferenceInSeconds < 60) {
            return 'Just now'
        } else if (timeDifferenceInSeconds < 3600) {
            const minutes = Math.floor(timeDifferenceInSeconds / 60);
            return `${minutes} minute${minutes !== 1 ? 's' : ''} ago`;
        } else if (timeDifferenceInSeconds < 86400) {
            const hours = Math.floor(timeDifferenceInSeconds / 3600);
            return `${hours} hour${hours !== 1 ? 's' : ''} ago`;
        } else if (timeDifferenceInSeconds < 2592000) {
            const days = Math.floor(timeDifferenceInSeconds / 86400);
            return `${days} day${days !== 1 ? 's' : ''} ago`;
        } else if (timeDifferenceInSeconds < 31536000) {
            const months = Math.floor(timeDifferenceInSeconds / 2592000);
            return `${months} month${months !== 1 ? 's' : ''} ago`;
        } else {
            const years = Math.floor(timeDifferenceInSeconds / 31536000);
            return `${years} year${years !== 1 ? 's' : ''} ago`;
        }
    };

    return {
        getShortDate, debounce, getGamesById, playCollection, isURL, getCollectionsById, likeCollection, handleVolumeChange, handlePreviousClick, handleNextClick, togglePlayback, toggleMute,
        playerRef, addToHistory, mixGameResults, playGameMusic, truncateString, playSong, likeAlbum,
        likeGame, getAlbumsById, likeSong, playAlbum, playlist, useSongEffect, shuffle, mixAlbumResults,
        removeFromCollection, addToCollection, getAccountCollections, renderTextWithLineBreaks
    };
};

export default useFunctions;
