// VideoContext.js
import React, { createContext, useContext, useRef } from 'react';

const VideoContext = createContext();

export function VideoProvider({ children }) {
  const videoRef = useRef(null);
  const myInterval = useRef(null);
  return (
    <VideoContext.Provider value={{ videoRef, myInterval }}>
      {children}
    </VideoContext.Provider>
  );
}

export function useVideoRef() {
  return useContext(VideoContext);
}
