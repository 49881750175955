import React from 'react';
import { useNavigate } from 'react-router-dom';

const TwitchAuthCallback = () => {
  const navigate = useNavigate();

  React.useEffect(() => {
    const getAccessToken = async () => {
      // Get the authorization code from the query parameters
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get('code');

      // Exchange the authorization code for an access token
      const clientId = process.env.TWITCH_CLIENT_ID;
      const clientSecret = process.env.TWITCH_CLIENT_SECRET;
      const redirectUri = process.env.TWITCH_REDIRECT_URI;

      const response = await fetch('https://id.twitch.tv/oauth2/token', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: `client_id=${clientId}&client_secret=${clientSecret}&code=${code}&grant_type=authorization_code&redirect_uri=${redirectUri}`,
      });

      const data = await response.json();

      if (response.ok) {
        const accessToken = data.access_token;
        localStorage.setItem("twitch-test", accessToken)
        // TODO: Store the access token or use it for further API requests

        // Redirect the user to the desired page
        // navigate('/dashboard');
      } else {
        // Handle the error
        console.error(data);
      }
    };

    getAccessToken();
  }, [navigate]);

  return <div>Authenticating...</div>;
};

export default TwitchAuthCallback;