import { createStore, combineReducers } from 'redux';
import playingReducer from '../reducers/playingReducer';
import videoPlayerReducer from '../reducers/videoPlayerReducer';
import playlistReducer from '../reducers/playlistReducer';
import gameResultsReducer from '../reducers/gameResultsReducer';
import userReducer from '../reducers/userReducer';
import accountReducer from '../reducers/accountReducer';
import playbackReducer from '../reducers/playbackReducer';
import filtersReducer from '../reducers/filtersReducer';

const rootReducer = combineReducers({
  playing: playingReducer,
  videoPlayer: videoPlayerReducer,
  playlist: playlistReducer,
  gameResults: gameResultsReducer,
  user: userReducer, // google
  account: accountReducer, // website
  playback: playbackReducer,
  filters: filtersReducer,
  // Add other reducers if needed
});

const store = createStore(rootReducer);

export default store;
