import React, { useState, useEffect } from 'react';
import CollectionBanner from '../components/CollectionBanner';
import GamesWrapper from '../components/GamesWrapper';
import AlbumsWrapper from '../components/AlbumsWrapper';
import { useSelector } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useNavigate, useLocation, useParams } from 'react-router-dom';


function CollectionPage() {

    const { collection_id } = useParams();
    const [collection, setCollection] = useState(null);
    const [collectionAlbums, setCollectionAlbums] = useState([]);
    const [collectionGames, setCollectionGames] = useState([]);
    const location = useLocation();
    const isGameCollection = location.pathname.includes('/gameCollection/');
    const user = useSelector((state) => state.user);
    const [gameQueryPage, setGameQueryPage] = useState(1);
    const [albumQueryPage, setAlbumQueryPage] = useState(1);
    const [totalResultsCount, setTotalResultsCount] = useState(0);

    const fetchCollectionAlbums = async () => {

        fetch(`${process.env.REACT_APP_API_URL}/get-collection-albums`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ collection_id, page: albumQueryPage, sub: user.user.sub })
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                // console.log('Response data:', data);
                setCollectionAlbums(data.albums)
                setCollection(data.collection)
                setTotalResultsCount(data.totalResultsCount)
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }

    const fetchCollectionGames = async () => {

        // local query --> get ALL games for this collection.
        fetch(`${process.env.REACT_APP_API_URL}/get-collection-games`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ collection_id, page: gameQueryPage, sub: user.user.sub || "" })
        })
            .then(response => response.json())
            .then(data => {
                setCollectionGames(data.games)
                setCollection(data.collection)
                setTotalResultsCount(data.totalResultsCount)
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }

    /* local end */
    useEffect(() => {
        if (user.user.logged !== undefined) {
            if (isGameCollection) fetchCollectionGames()
            else fetchCollectionAlbums()
        }
    }, [user.user.logged, gameQueryPage, albumQueryPage])


    return (
        <div>

            <div className='collection-content-container'>
                <div className='mid-column'>

                    <div className='holder'>
                        <CollectionBanner
                            collection={collection}
                        />
                    </div>

                    {collectionGames.length > 0 &&
                        <GamesWrapper
                            games={collectionGames}
                            fromCollection={collection}
                            setCollectionGames={setCollectionGames} // needed for drag and drop
                            gameQueryPage={gameQueryPage}
                            setGameQueryPage={setGameQueryPage}
                            totalResultsCount={totalResultsCount}
                        />
                    }

                    {collectionAlbums.length > 0 &&
                        <AlbumsWrapper
                            albums={collectionAlbums}
                            fromCollection={collection}
                            setCollectionAlbums={setCollectionAlbums}
                            albumQueryPage={albumQueryPage}
                            setAlbumQueryPage={setAlbumQueryPage}
                            totalResultsCount={totalResultsCount}
                        // setChosenGameAlbum={setChosenGameAlbum}
                        />
                    }

                </div>
            </div>

        </div >
    );
}

export default CollectionPage;
