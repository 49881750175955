import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import SongsWrapper from './SongsWrapper';
import { toggleRepeat, toggleShuffle } from '../actions/playback';
import useFunctions from './Functions';
import { NavLink } from 'react-router-dom';

function Playbar() {

    const dispatch = useDispatch();
    const playingSongObject = useSelector(state => state.playback.playingSongObject)
    const duration = useSelector(state => state.playback.duration)
    const currentTimestamp = useSelector(state => state.playback.currentTimestamp)
    const repeatSong = useSelector(state => state.playback.repeatSong)
    const shuffleEnabled = useSelector(state => state.playback.shuffleEnabled)
    const visibleIframe = useSelector(state => state.videoPlayer.visibleIframe)

    const volume = useSelector(state => state.playback.volume)
    const [queueClass, setQueueClass] = useState("");
    const playback = useSelector((state) => state.playback);
    const playlist = useSelector((state) => state.playlist);
    const { likeSong, toggleMute, togglePlayback, handlePreviousClick, handleNextClick, handleVolumeChange, useSongEffect } = useFunctions();
    const account = useSelector((state) => state.account);


    useSongEffect(); // needed or you will copy whole code to 3 files

    // this should run only once, after page load - loading from cache
    useEffect(() => {
        let cachedPlayedGameTitle = localStorage.getItem("cachedPlayedGameTitle")
        let cachedPlayedSongTitle = localStorage.getItem("cachedPlayedSongTitle")
        let vidObject = {
            song_title: cachedPlayedSongTitle,
            game_name: cachedPlayedGameTitle,
        }
        if (cachedPlayedGameTitle && cachedPlayedSongTitle) {
            dispatch({ type: 'SET_PLAYING_SONG_OBJECT', payload: vidObject });
        }
    }, [])

    const toggleVideoVisibility = () => {
        dispatch({ type: 'TOGGLE_IFRAME_VISILITY' });
    }

    const toggleQueue = () => {
        if (queueClass === "") setQueueClass(" queue-container--active")
        else setQueueClass("")
    }


    useEffect(() => {
        const inputElement = document.getElementById("myinput");
        const value = (inputElement.value - inputElement.min) / (inputElement.max - inputElement.min) * 100;
        inputElement.style.background = `linear-gradient(to right, #EF5466 0%, #EF5466 ${value}%, #ddd ${value}%, #ddd 100%)`;
    }, [volume]);

    return (

        <div className='playbar-container playbar-container--desk'>

            {playlist && playingSongObject &&
                <div className={"queue-container " + queueClass}>
                    <div className="queue-left-content">
                        <div className="queue-left-img-container">
                            <img src={process.env.REACT_APP_ALBUM_COVERS_FOLDER + "/" + playingSongObject.fromAlbum + "-" + playingSongObject.fromVersionId + "_400x.jpg"} alt="album cover"></img>
                        </div>
                        <div className="queue-playing-title">{playingSongObject.song_title}</div>
                        <div className="queue-playing-game">{playingSongObject.game_name}</div>
                    </div>
                    {playlist && playlist.playlistData && playlist.playlistData.album_songs &&
                        <SongsWrapper
                            songs={playlist.playlistData.album_songs}
                            album={playlist.playlistData} // bildigin album objecsi ama hep olmayabilir. liked ise.
                            playlist={playlist}
                            isQueue={true}
                            toggleQueue={toggleQueue}
                        />
                    }
                </div>
            }

            <div className='play-controls'>
                <div className='play-control previous' onClick={handlePreviousClick}>

                    <svg viewBox="0 0 16 16" focusable="false" className="chakra-icon css-1yk3h4a" data-testid="StepBackwardIcon"><path d="M15 0v16L2 8.802V16H1V0h1v7.198L15 0z"></path></svg>

                </div>
                <div className='play-control play' onClick={togglePlayback}>

                    {playback.isPaused &&
                        <svg viewBox="0 0 16 16" focusable="false" className="chakra-icon css-c1x3e4" data-testid="PlayIcon"><path d="m3 1 12 7-12 7V1z"></path></svg>
                    }
                    {!playback.isPaused &&
                        <svg viewBox="0 0 16 16" focusable="false" className="chakra-icon css-c1x3e4" data-testid="PauseIcon"><path d="M6 0H2v16h4V0zm8 0h-4v16h4V0z"></path></svg>
                    }

                </div>
                <div className='play-control next' onClick={handleNextClick}>

                    <svg viewBox="0 0 16 16" focusable="false" className="chakra-icon css-1yk3h4a" data-testid="StepForwardIcon"><path d="M1 1v14l11-6.217V15h1V1h-1v6.217L1 1z"></path></svg>

                </div>
            </div>
            <div className='playback-info'>
                <div className='first-row'>
                    {playingSongObject &&
                        <div className='playing-container'>
                            <div className='playing-text'>
                                <div className='playing-game' title={playingSongObject.game_name}>
                                    <NavLink to={"/games/" + (playingSongObject.game_slug ? playingSongObject.game_slug : "all") + "/" + (playingSongObject.game_id ? playingSongObject.game_id : localStorage.getItem("cachedPlayedGameId"))}>
                                        {playingSongObject.game_name}
                                    </NavLink>
                                </div>
                                <span className='splitter'>·</span>
                                <div className='playing-title' title={playingSongObject.song_title}>
                                    {playingSongObject.song_title}
                                </div>
                            </div>
                            <div className='song-option-buttons'>
                                
                                {account && account.account && account.account.liked_songs && playingSongObject &&
                                    <div className="song-option-btn" onClick={() => likeSong()}>
                                        {account.account.liked_songs.find(e => e.youtube_id === playingSongObject.youtube_id) &&
                                            <svg id="svg-liked" viewBox="0 0 16 16" focusable="false" className="chakra-icon css-1qsky21" data-testid="HeartFillIcon" aria-hidden="true"><path d="M8 3.266C2.837-2.68-2.564 4.578 1.328 8.516 5.22 12.451 8 15 8 15s2.78-2.548 6.672-6.485C18.564 4.501 13.162-2.679 8 3.265z"></path></svg>
                                        }
                                        {!account.account.liked_songs.find(e => e.youtube_id === playingSongObject.youtube_id) &&
                                            <svg viewBox="0 0 16 16" focusable="false" className="chakra-icon css-1yk3h4a" data-testid="HeartIcon" aria-hidden="true"><path d="m8 4.79-.755-.869c-1.17-1.348-2.252-1.832-3.093-1.9-.836-.067-1.59.263-2.164.858C.802 4.108.528 6.283 2.04 7.812a245.96 245.96 0 0 0 4.775 4.7c.482.46.882.837 1.186 1.122.304-.285.704-.663 1.186-1.123a238.026 238.026 0 0 0 4.771-4.695 3.545 3.545 0 0 0 .057-4.963c-.572-.589-1.324-.915-2.161-.843-.843.072-1.926.562-3.098 1.911L8 4.791zm6.672 3.725C10.78 12.452 8 15 8 15s-2.78-2.548-6.672-6.485c-3.717-3.76 1.043-10.549 5.976-5.972.232.215.464.455.696.723.232-.267.464-.508.696-.723C13.63-2.04 18.39 4.68 14.672 8.515z"></path><path d="m8 4.79-.755-.869c-1.17-1.348-2.252-1.832-3.093-1.9-.836-.067-1.59.263-2.164.858C.802 4.108.528 6.283 2.04 7.812a245.96 245.96 0 0 0 4.775 4.7c.482.46.882.837 1.186 1.122.304-.285.704-.663 1.186-1.123a238.026 238.026 0 0 0 4.771-4.695 3.545 3.545 0 0 0 .057-4.963c-.572-.589-1.324-.915-2.161-.843-.843.072-1.926.562-3.098 1.911L8 4.791zm6.672 3.725C10.78 12.452 8 15 8 15s-2.78-2.548-6.672-6.485c-3.717-3.76 1.043-10.549 5.976-5.972.232.215.464.455.696.723.232-.267.464-.508.696-.723C13.63-2.04 18.39 4.68 14.672 8.515z"></path></svg>
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                    }
                </div>
                <div className='second-row'>
                    <div className="current-duration duration-info" id="current-duration">
                        {currentTimestamp}
                    </div>
                    <div id="progress-container">
                        <div id="progress-bar"></div>
                    </div>
                    <div className="total-duration duration-info" id="total-duration">
                        {duration}
                    </div>
                </div>
            </div>
            <div className='playback-options'>
                <div className="playback-options-buttons">
                    <div className="playback-option-btn playback-option-btn--view-video" onClick={toggleVideoVisibility}>
                        <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="800px" height="800px" viewBox="0 -3 20 20" version="1.1">
                            <defs>
                            </defs>
                            <g id="Page-1" stroke="none" strokeWidth="1" fillRule="evenodd">
                                <g transform="translate(-300.000000, -7442.000000)" id={visibleIframe ? "view-youtube" : "hide-youtube"}>
                                    <g id="icons" transform="translate(56.000000, 160.000000)">
                                        <path d="M251.988432,7291.58588 L251.988432,7285.97425 C253.980638,7286.91168 255.523602,7287.8172 257.348463,7288.79353 C255.843351,7289.62824 253.980638,7290.56468 251.988432,7291.58588 M263.090998,7283.18289 C262.747343,7282.73013 262.161634,7282.37809 261.538073,7282.26141 C259.705243,7281.91336 248.270974,7281.91237 246.439141,7282.26141 C245.939097,7282.35515 245.493839,7282.58153 245.111335,7282.93357 C243.49964,7284.42947 244.004664,7292.45151 244.393145,7293.75096 C244.556505,7294.31342 244.767679,7294.71931 245.033639,7294.98558 C245.376298,7295.33761 245.845463,7295.57995 246.384355,7295.68865 C247.893451,7296.0008 255.668037,7296.17532 261.506198,7295.73552 C262.044094,7295.64178 262.520231,7295.39147 262.895762,7295.02447 C264.385932,7293.53455 264.28433,7285.06174 263.090998,7283.18289" id="youtube-[#168]">
                                        </path>
                                    </g>
                                </g>
                            </g>
                        </svg>
                    </div>
                    <div className="playback-option-btn" onClick={() => dispatch(toggleRepeat())}>
                        {repeatSong &&
                            <svg id="svg-enabled" viewBox="0 0 16 16" focusable="false" className="chakra-icon css-133bxm3" data-testid="RepeatIcon">
                                <path d="M9 5H4a3 3 0 0 0 0 6h1.2v1H4a4 4 0 0 1 0-8h1V2l4 3zm3 6a3 3 0 0 0 0-6h-1.2V4H12a4 4 0 0 1 0 8h-1v2l-4-3h5"></path>
                            </svg>
                        }
                        {!repeatSong &&
                            <svg viewBox="0 0 16 16" focusable="false" className="chakra-icon css-133bxm3" data-testid="RepeatIcon"><path d="M9 5H4a3 3 0 0 0 0 6h1.2v1H4a4 4 0 0 1 0-8h1V2l4 3zm3 6a3 3 0 0 0 0-6h-1.2V4H12a4 4 0 0 1 0 8h-1v2l-4-3h5z"></path></svg>
                        }

                    </div>
                    <div className="playback-option-btn" onClick={() => dispatch(toggleShuffle())}>
                        {!shuffleEnabled &&
                            <svg viewBox="0 0 16 16" focusable="false" className="chakra-icon css-133bxm3" data-testid="ShuffleIcon"><path d="m16 5-4-3v2h-2v1h6zm-12.553.007c.377-.015 1.169-.044 1.935.231.434.156.83.403 1.12.787.285.38.498.938.498 1.774 0 1.009.248 1.805.673 2.422.423.614.996 1.013 1.59 1.272.976.426 2.054.492 2.737.502V14l4-3h-2v-.003h-1.647c-.627 0-1.74-.005-2.69-.42-.465-.203-.873-.497-1.167-.924C8.204 9.23 8 8.64 8 7.8c0-1.014-.262-1.793-.7-2.375-.435-.579-1.013-.923-1.58-1.127-.982-.353-2.02-.304-2.34-.29-.04.003-.07.004-.086.004H0v1h3.294l.153-.004zM5 12H0v-1h5v1z"></path></svg>
                        }
                        {shuffleEnabled &&
                            <svg id="svg-enabled" viewBox="0 0 16 16" focusable="false" className="chakra-icon css-133bxm3" data-testid="ShuffleIcon">
                                <path d="m16 5-4-3v2h-2v1h6zm-12.553.007c.377-.015 1.169-.044 1.935.231.434.156.83.403 1.12.787.285.38.498.938.498 1.774 0 1.009.248 1.805.673 2.422.423.614.996 1.013 1.59 1.272.976.426 2.054.492 2.737.502V14l4-3h-2v-.003h-1.647c-.627 0-1.74-.005-2.69-.42-.465-.203-.873-.497-1.167-.924C8.204 9.23 8 8.64 8 7.8c0-1.014-.262-1.793-.7-2.375-.435-.579-1.013-.923-1.58-1.127-.982-.353-2.02-.304-2.34-.29-.04.003-.07.004-.086.004H0v1h3.294l.153-.004zM5 12H0v-1h5v1z"></path>
                            </svg>
                        }
                    </div>
                    <div className="playback-option-btn playback-option-btn--sound" onClick={(e) => toggleMute(e)} >
                        <div className="popover-wrapper" id="mute-btn-wrapper" >
                            <div className="popover">
                                <input
                                    id="myinput"
                                    type="range"
                                    min="0"
                                    max="100"
                                    step="1"
                                    value={volume}
                                    onChange={handleVolumeChange}
                                />
                            </div>
                        </div>
                        <svg id="volume-svg" viewBox="0 0 16 16" focusable="false" className="chakra-icon css-1yk3h4a" data-testid="VolumeIcon"><path d="M5.894 9.8H2V6.2h3.894L9 3.301V12.7L5.894 9.8zM1 10.8h4.5L10 15V1L5.5 5.2H1v5.6zm14-3.145a5.117 5.117 0 0 1-1.504 3.63l-.687-.728A4.118 4.118 0 0 0 14 7.655a4.116 4.116 0 0 0-1.013-2.71l.708-.708A5.112 5.112 0 0 1 15 7.655zm-2.094.108c0 .784-.33 1.49-.857 1.99l-.687-.727a1.734 1.734 0 0 0 .036-2.491l.707-.707a2.73 2.73 0 0 1 .801 1.935z"></path></svg>
                    </div>
                </div>
                <div className="playback-options--queue" onClick={toggleQueue}>
                    <div className="queue-content-container">
                        <div className="queue-album-img"
                            style={{ backgroundImage: playingSongObject && "url(" + process.env.REACT_APP_ALBUM_COVERS_FOLDER + "/" + playingSongObject.fromAlbum + "-" + playingSongObject.fromVersionId + "_64x.jpg)" }}
                        ></div>
                        <div className="queue-title">Queue</div>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default Playbar;
