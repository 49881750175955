
import React from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

function SettingsPage() {

  // localStorage.removeItem('credential');

  const account = useSelector((state) => state.account);
  const user = useSelector((state) => state.user);

  const submitChanges = () => {
    let newUsername
    if (document.querySelector('#newUsername')) {
      newUsername = document.querySelector('#newUsername').value;
    }
    let newProfilePicture = document.querySelector('#newProfilePicture').value;
    let newAccountData = {}

    if (newUsername && newUsername.trim() !== "") {
      newAccountData.username = newUsername;
    }

    if (newProfilePicture !== "") {
      newAccountData.profile_picture = newProfilePicture;
    }

    if (Object.keys(newAccountData).length === 0) return false;

    fetch(`${process.env.REACT_APP_API_URL}/update-account-data`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ email: account.account.email, newAccountData, sub: user.user.sub })
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        // console.log('Response data:', data);
        toast.success('Account successfully updated.', {
          position: "bottom-center",
          autoClose: 2700,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

        setTimeout(() => {
          window.location.href = window.location.href
        }, 2800)

      })
      .catch(error => {
        // console.error('Error:', error);
        toast.error('Error updating account info.', {
          position: "bottom-center",
          autoClose: 3500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  }

  return (
    <div className='settings-container'>
      {user.user.logged === false && account && account.account && !account.account.username &&
        <div style={{ "margin": "80px 50px", "textAlign": "left" }}>Please login to see this page </div>
      }
      {account && account.account && account.account.username &&

        <div className='settings-content-container'>

          {account?.account?.canChangeUsername &&
            <div className='username-change'>
              <input id='newUsername' maxlength="20" type="text" placeholder={"Username"}></input>
              <span>Warning: You can change your username only once every 6 months.</span>
            </div>
          }
          <input id='newProfilePicture' type="text" placeholder="Profile picture URL"></input>
          <div className='button-container'>
            <button onClick={submitChanges}>SUBMIT CHANGES</button>
          </div>
        </div>
      }
    </div>
  );
}

export default SettingsPage;
