
import React, { useState, useEffect, useRef } from 'react';
import FavoritesBanner from '../../components/FavoritesBanner';
import { useSelector } from 'react-redux';
import useFunctions from '../../components/Functions';
import AlbumsWrapper from '../../components/AlbumsWrapper';


function FavoriteAlbumsPage() {

  const { getAlbumsById } = useFunctions();
  const [albums, setAlbums] = useState([]);
  const account = useSelector((state) => state.account);
  const [albumQueryPage, setAlbumQueryPage] = useState(1);
  const [browseOwned, setBrowseOwned] = useState(false); // false: browsing liked
  const [totalResultsCount, setTotalResultsCount] = useState(0);

  // filters and renders accounts albums
  const filterMyAlbums = () => {
    getAlbumsById(account.account.contributions.addedAlbumIds, albumQueryPage).then(data => {
      setAlbums(data.albums.reverse())
      setTotalResultsCount(data.totalResultsCount)
    })
  }

  // filters and renders accounts liked albums
  const filterLikedAlbums = () => {

    getAlbumsById(account.account.liked_album_ids, albumQueryPage).then(data => {
      if (data.albums.length > 0) {
        setAlbums(data.albums.reverse())
        setTotalResultsCount(data.totalResultsCount)
      }
    })

  }

  const queryMyAlbums = () => {
    browseOwned ? filterMyAlbums() : filterLikedAlbums();
  }

  const delay = 130; // Adjust the delay as needed
  const timeoutIdRef = useRef(null);
  const debounce = (func) => {
    clearTimeout(timeoutIdRef.current);
    timeoutIdRef.current = setTimeout(() => {
      func();
    }, delay);
  };

  const queryMyAlbumsDebounced = () => {
    debounce(queryMyAlbums);
  };


  useEffect(() => {
    if (!account.account.liked_album_ids || !account.account.contributions.addedAlbumIds) return;

    queryMyAlbumsDebounced();

    return () => {
      clearTimeout(timeoutIdRef.current);
    };

  }, [account.account, albumQueryPage]);


  useEffect(() => {
    if (!account.account.liked_album_ids || !account.account.contributions.addedAlbumIds) return;
    setAlbumQueryPage(1)
    queryMyAlbumsDebounced();

    return () => {
      clearTimeout(timeoutIdRef.current);
    };
  }, [browseOwned]);


  return (
    <div className='favorites-content-container'>
      <FavoritesBanner />

      {albums &&
        <AlbumsWrapper
          albums={albums}
          // setChosenGameAlbum={setChosenGameAlbum}
          fromFavorites={true}
          filterMyAlbums={filterMyAlbums}
          filterLikedAlbums={filterLikedAlbums}
          albumQueryPage={albumQueryPage}
          setAlbumQueryPage={setAlbumQueryPage}
          totalResultsCount={totalResultsCount}
          setBrowseOwned={setBrowseOwned}
          queryMyAlbums={queryMyAlbums}
        />
      }

    </div>
  );
}

export default FavoriteAlbumsPage;
